import React, {useState} from 'react'
import SettleMents from './SettleMents';



const tabStyle = {
    width: '10%',
    background: 'rgb(219, 233, 255)',
    cursor: 'pointer',
    color: '#000',
    padding: '8px 12px',
    boxShadow: 'inset -3px -3px 10px -7px #000',
    marginLeft:'auto',
  
};
const activeTabStyle = {
    width: '10%',
    cursor: 'pointer',
    padding: '8px 12px',
    background: '#006c67',
    color: '#fff',
    marginLeft:'auto',
  
};

const h5Style = {
    textAlign: 'center',
    fontSize: '17px',
    margin: '5px 0px',
    
}

export default function Main({admin}) {

    const [tab, setTab] = useState('razorPay');
    
        const handlepagechange = (type) => {
            setTab(type)
        }
  return (
    <>
        <div className="" style={{ marginTop: '15px',position:'relative' }}>
                <div>
                <div className='topTabs' >
                    <div className="tabs" style={tab === "razorPay" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('razorPay')}>
                        <h5 style={h5Style}>Razorpay</h5>
                    </div>
                </div>
               
              
                </div>

                <div style={{ marginTop: window.innerWidth > 1800 ? '75px' : '30px' }}>

                    {tab === 'razorPay' ?
                        <SettleMents admin={admin} />
                        : ""}
                   
                </div>
            </div>
    </>
  )
}
