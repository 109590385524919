import React from "react";
import { FORM_STATUS, STRING, TYPE } from "../../../../../utils/Constant";
import { aadharExistCheck, fetchBankDetail, panAuthenticationApi, zipAndBranchDetail } from "../../AdminActionCreator.component";
import { BankFormValidation } from "../../../../../utils/validation";
import { APIS } from "../../../../../utils/api-factory";
import ConfirmPopup from '../../../../presentationals/Popup/newconfirmpopup'
import PopUp from "../../../../presentationals/Popup/Popup.component";
import DuplicatePanpOpup from '../../../../presentationals/Popup/duplicatePan.component'
import Loader from '../../../../presentationals/Loader/dots'
class BankEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameAsOnPan: '',
            userDetail: {
                BankNameState: '',
                BankUserNameState: '',
                AccountNoState: '',
                IFSCCodeState: '',
                BankBranchState: '',
                IfSalriedAccountState: '',
                PanNoState: '',
                aadharNoState: ''

            },
            bankValidationMsg: {},
            popupState: false,
            popupStatus: '',
            isPenNo: false,
            confirmationText: '',
            confirmPopupState: false,
            confirmPopupStatePanCard: false,
            confirmationTextPancard: "",
            macthedPandata: '',
            loaderStateforPan:false





        }
    }
    cancelLoanConfirm(type) {
        const { admin, userdata } = this.props

        if (type == 'yes') {
            let bankValidation = BankFormValidation(this.state.userDetail)
            if (bankValidation.status) {
                this.setState({
                    bankValidationMsg: bankValidation
                })
                fetchBankDetail(
                    this.state.userDetail,
                    userdata.userId,
                    admin.emailId,
                    callBack => {
                        if (callBack === 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Bank Details Changed Successfully',
                                confirmPopupState: false
                            })
                            this.removePopup()
                        }
                    }
                )
            } else {
                this.setState({
                    bankValidationMsg: bankValidation
                })
            }
        } else if (type == 'no') {
            this.setState({ confirmPopupState: false })
        }

    }
    componentDidMount() {
        const { userdata } = this.props
        console.log(userdata, 'userdatauserdata')
        let userDetail = Object.assign({}, this.state.userDetail)
        fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + userdata.userId)
            .then(res => res.json())
            .then(json => {
                if (
                    json.nameAsBankAccount != null &&
                    json.nameAsBankAccount != ''
                ) {
                    userDetail.BankUserNameState = json.nameAsBankAccount
                }
                if (
                    json.accountNumber != null &&
                    json.accountNumber != 0
                ) {
                    userDetail.AccountNoState = json.accountNumber
                }
                if (
                    json.ifscCode != null &&
                    json.ifscCode != ''
                ) {
                    userDetail.IFSCCodeState = json.ifscCode
                }
                if (
                    json.branch != null &&
                    json.branch != ''
                ) {
                    userDetail.BankBranchState = json.branch
                }
                if (
                    json.bankName != null &&
                    json.bankName != ''
                ) {
                    userDetail.BankNameState = json.bankName
                }
                if (
                    json.ifSalariedAccount != null &&
                    json.ifSalariedAccount != ''
                ) {
                    userDetail.IfSalriedAccountState = json.ifSalariedAccount
                }
                this.setState({ userDetail: userDetail })

            })
        if (
            userdata.nameAsOnPan != null &&
            userdata.nameAsOnPan != ''
        ) {
            userDetail.nameAsOnPan = userdata.nameAsOnPan
        }
        userDetail.PanNoState = userdata.panNumber
        userDetail.aadharNoState = userdata.aadharNumber
        userDetail.userId = userdata.userId
        userDetail.loanId = userdata.loanId
        userDetail.formStatus = FORM_STATUS.UPDATE,

            this.setState({ userDetail: userDetail, nameAsOnPan: userdata.nameAsOnPan })
    }
    onChangeHandler(e, type) {
        const { userdata } = this.props
        console.log(userdata)
        let userDetail = Object.assign({}, this.state.userDetail)

        if (type == TYPE.BANK_USER_NAME) {
            userDetail.BankUserNameState = e.target.value
        } else if (type == TYPE.ACCOUNT_NO) {
            userDetail.AccountNoState = e.target.value
        } else if (type == TYPE.BANK_NAME) {
            userDetail.BankNameState = e.target.value
        } else if (type == TYPE.BANK_BRANCH) {
            userDetail.BankBranchState = e.target.value
        } else if (type == TYPE.IFSC_CODE) {
            userDetail.IFSCCodeState = e.target.value
            let ifscCode = e.target.value
            if (ifscCode.length == 11) {
                zipAndBranchDetail(e.target.value, 'branch', res => {
                    if (res.status == 'success') {
                        ; (userDetail.BankBranchState = res.branchCode),
                            (userDetail.BankNameState = res.branchName)
                        document.getElementById('userBankName').value = res.branchName
                        document.getElementById('userBankBranch').value = res.branchCode
                    }
                })
            } else {
                ; (userDetail.BankBranchState = ''), (userDetail.BankNameState = '')
                document.getElementById('userBankName').value = ''
                document.getElementById('userBankBranch').value = ''
            }
        } else if (type == TYPE.IF_SALARIED_ACCOUNT) {
            userDetail.IfSalriedAccountState = e.target.value
        }
        else if (type == TYPE.AADHAAR_NO) {
            userDetail.aadharNoState = e.target.value
        }
        else if (type == TYPE.PAN_NO) {
            userDetail.PanNoState = e.target.value
            let penNo = e.target.value

            // if (penNo.length >= 10 && admin.emailId === 'SuperUser') {
            //   userDetail.PanNoState = e.target.value
            //   this.setState({ isPenNo: false })
            // }



            if (penNo.length >= 10) {
                this.setState({ loaderStateforPan: true })
                fetch(APIS.CHECK_PEN_NUMBER + penNo + '&userId=' + userdata.userId)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 'success') {
                            this.setState({ isPenNo: false, loaderStateforPan: false }
                                // , () => this.saveVerify()
                                //removepanauth
                            )
                        } else if (res.status == 'failure') {

                            this.setState({
                                macthedPandata: res,
                                loaderStateforPan: false,
                                confirmPopupStatePanCard: true,
                                confirmationTextPancard: 'This PAN already exists.Do you want update anyway?'
                            })
                            // this.setState({
                            //   isPenNo: true,
                            //   popupState: true,
                            //   popupStatus: 'Pan No Already Exist',
                            //   loaderStateforPan: false
                            // }, () => this.removePopup())
                        }
                    })
            }
        }
        this.setState({ userDetail: userDetail })

    }
    saveVerify() {
        const { userdata } = this.props
        if (this.state.userDetail.PanNoState != '' && this.state.userDetail.PanNoState != null) {
            let userDetail = Object.assign({}, this.state.userDetail)
            this.setState({ loaderStateforPan: true })
            panAuthenticationApi(userdata.userId, this.state.userDetail.PanNoState, callBack => {
                if (callBack !== null && callBack.result.name !== null && callBack.result.name !== '' && callBack.result.name !== undefined) {
                    userDetail.nameAsOnPan = callBack.result.name
                    this.setState({ userDetail, loaderStateforPan: false, nameAsOnPan: callBack.result.name })

                } else {
                    userDetail.nameAsOnPan = ''

                    this.setState({
                        nameAsOnPan: '',
                        userDetail,
                        popupState: true,
                        loaderStateforPan: false,
                        popupStatus: "Please Enter Correct PAN Number!"
                    }, () => this.removePopup())
                }

            })
            // fetch(APIS.PAN_VERIFY_CUSTOMER + userDetails.userId + "&pan=" + this.state.userDetail.PanNoState + "&nameOnPan=" + this.state.userDetail.nameAsOnPan)
            //   .then(res => res.text())
            //   .then(json => {
            //     if (json == 'success') {
            //       this.setState({
            //         panVerifiedstate: true,
            //       })

            //     }
            //   })

        } else {
            this.setState({
                popupState: true,
                popupStatus: "Enter PAN Number"
            }, () => this.removePopup())
        }
    }
    onSubmitHandler() {
        const { userdata, admin } = this.props
        if (this.state.isPenNo === false) {
            // if (this.state.userDetail.nameAsOnPan !== '' && this.state.userDetail.nameAsOnPan != null) {

                let bankValidation = BankFormValidation(this.state.userDetail)


                aadharExistCheck(
                    userdata.userId,
                    this.state.userDetail.aadharNoState,
                    callBack => {
                        if (callBack.aadharExistence == false) {
                            if (bankValidation.status) {
                                this.setState({
                                    bankValidationMsg: bankValidation
                                })
                                fetchBankDetail(
                                    this.state.userDetail,
                                    userdata.userId,
                                    admin.emailId,
                                    callBack => {
                                        this.setState({
                                            popupState: true,
                                            popupStatus: 'Bank Details Changed Successfully'
                                        }, () => this.props.getbankData())
                                        this.removePopup()
                                    }
                                )
                            } else {
                                this.setState({
                                    bankValidationMsg: bankValidation
                                })
                            }
                        } else if (callBack.aadharExistence == true) {
                            this.setState({
                                confirmPopupState: true,
                                confirmationText: 'This Aadhar already exists.Do you want update anyway?'
                            })

                        }

                    }
                )
            // } else if (this.state.userDetail.nameAsOnPan == '' || this.state.userDetail.nameAsOnPan == null) {
            //     this.setState({ popupState: true, popupStatus: 'Enter Name On pan First' }, () => this.removePopup())
            // }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Pan No. Already Exist'
            }, () => this.removePopup())

        }
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }

    closePopup() {
        this.setState({ popupState: false, popupStatePan: false })
    }
    setpancardanyway(type) {
        let userDetail = Object.assign({}, this.state.userDetail)

        if (type === 'yes') {
            userDetail.PanNoState = this.state.userDetail.PanNoState
            this.setState({ confirmPopupStatePanCard: false, confirmationText: '', userDetail })
        }
        if (type === 'no') {
            userDetail.PanNoState = ''
            this.setState({ confirmPopupStatePanCard: false, confirmationText: '', userDetail })
        }

    }
    render() {
        return (
            <>
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    <div className="edit-box ">

                        <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                        <h4><b>Edit Bank Details</b></h4>
                        <div className="row form-group">
                            <div className="col-sm-4 col-xs-12">
                                <h4>Name as in Bank Account</h4>
                                <input type="text" className="form-control" value={this.state.userDetail.BankUserNameState} onChange={(e) => this.onChangeHandler(e, TYPE.BANK_USER_NAME)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "BankUserName" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <h4>IFSC Code</h4>
                                <input type="text" className="form-control" value={this.state.userDetail.IFSCCodeState} onChange={(e) => this.onChangeHandler(e, TYPE.IFSC_CODE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "IFSCCode" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <h4>Account Number</h4>
                                <input type="text" className="form-control" value={this.state.userDetail.AccountNoState} onChange={(e) => this.onChangeHandler(e, TYPE.ACCOUNT_NO)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "AccountNo" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-4 col-xs-12">
                                <h4>Bank Name</h4>
                                <input type="text" id="userBankName" className="form-control" value={this.state.userDetail.BankNameState} onChange={(e) => this.onChangeHandler(e, TYPE.BANK_NAME)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <h4>Branch</h4>
                                <input type="text" id="userBankBranch" className="form-control" value={this.state.userDetail.BankBranchState} onChange={(e) => this.onChangeHandler(e, TYPE.BANK_BRANCH)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <h4>If Salried Account?</h4>
                                <select required className="form-control" value={this.state.userDetail.IfSalriedAccountState} onChange={(e) => this.onChangeHandler(e, TYPE.IF_SALARIED_ACCOUNT)} >
                                    <option value=""></option>
                                    <option value={STRING.YES}>{STRING.YES}</option>
                                    <option value={STRING.NO}>{STRING.NO}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "IfSalriedAccount" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-4 col-xs-12">
                                <h4>Pan Number</h4>
                                <div className="" style={{ display: 'flex' }}> <input type="text" id="panNumber" style={{ height: '43px' ,borderTopRightRadius:'0px' ,borderBottomRightRadius:'0px'}} className="form-control" value={this.state.userDetail.PanNoState} onChange={(e) => this.onChangeHandler(e, TYPE.PAN_NO)} /><button className="btn btn-primary" style={{borderTopLeftRadius:'0px' ,borderBottomLeftRadius:'0px'}} onClick={() => this.saveVerify()}>Check Pan</button></div>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "PanNo" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <h4>Name as on PAN</h4>
                                <input type="text" className="form-control" value={this.state.nameAsOnPan} readOnly />

                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <h4>Aadhaar Number</h4>
                                <input type="number" id="aadhaarNumber" className="form-control" value={this.state.userDetail.aadharNoState} onChange={(e) => this.onChangeHandler(e, TYPE.AADHAAR_NO)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.bankValidationMsg.field == "aadharNo" ? this.state.bankValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        {this.state.loaderStateforPan?<Loader/>:""}
                        {this.state.confirmPopupState === false && this.state.nameAsOnPan !== '' ?
                            <div className="row ">
                                <div className="col-xs-12">
                                    <button className="btn btn-primary" id="" onClick={(e) => this.onSubmitHandler(e, FORM_STATUS.BANK)}>
                                        Save Bank Details
                                    </button>
                                </div>
                            </div>
                            : ""}
                        {this.state.confirmPopupState ?
                            <div >
                                <ConfirmPopup confirmationText={this.state.confirmationText} cancelLoanConfirm={this.cancelLoanConfirm.bind(this)} />
                            </div>
                            : ""}
                        {this.state.confirmPopupStatePanCard ?
                            <DuplicatePanpOpup macthedPandata={this.state.macthedPandata} confirmationText={this.state.confirmationTextPancard} cancelLoanConfirm={this.setpancardanyway.bind(this)} />
                            : ""}
                            
                    </div>
                </div>
            </>
        )
    }
}
export default BankEdit