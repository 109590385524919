import React, { useEffect, useState } from "react";
import { getTxnDtaApi } from "../../targetactioncreator";
import AddExpense from '../expenseaddnew';
import Loader from '../../../../../presentationals/Loader/Loader.component'
import NodataImage from '../../../../../../images/nodata.png';

const Transaction = ({ selectedData, admin }) => {
    const [txnData, setTxnData] = useState([]);
    const [viewType, setviewType] = useState('list');
    const [editData, seteditData] = useState({});
    const [orderState, setOrderState] = useState({
        expenseDate: 'asc',
        departmentName: 'asc',
        categoryName: 'asc',
        subCategoryName: 'asc',
        vendorName: 'asc',
        amount: 'asc',
    });

    const [selectedTxn, setSelectedTxn] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        console.log(selectedData.expenseCategoryId);
        setLoader(true)
        getTxnDtaApi(selectedData.expenseCategoryId, orderState.expenseDate, 'expenseDate', (callback) => {
            console.log(callback);
            setTxnData(callback.data.transactions);
            setLoader(false)
        });
    }, [selectedData.expenseCategoryId]);

    const handleRowClick = (transaction) => {
        setSelectedTxn(transaction);
    };

    const closePopup = () => {
        setSelectedTxn(null);
    };

    const sortingBy = (column) => {
        const currentOrder = orderState[column];
        const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';

        setOrderState((prevOrderState) => ({
            ...prevOrderState,
            [column]: newOrder,
        }));
        setLoader(true)
        getTxnDtaApi(selectedData.expenseCategoryId, newOrder, column, (callback) => {
            console.log(callback);
            setTxnData(callback.data.transactions);
            setLoader(false)
        });
    };
    const getdata = (type) => {
        setviewType(type)
        setLoader(true)
        getTxnDtaApi(selectedData.expenseCategoryId, orderState.expenseDate, 'expenseDate', (callback) => {
            console.log(callback);
            setLoader(false)
            setTxnData(callback.data.transactions);
        });

    }

    const editHandler=(data)=>{
        setviewType("edit")
        seteditData(data)
    }

    return (
        <div className="container-fluid expense-popup" style={{ maxHeight: '550px', overflow: 'auto' }}>
            {loader ?
                <Loader />
                : ""}
            {viewType === 'add' || viewType === "edit" ? <button style={{ marginTop: '20px' }} className='btn btn-primary' onClick={() => setviewType('list')}><i className='fa fa-add'></i>Back to Transactions</button> : ""}
            {viewType === 'add' || viewType === "edit" ?
                <AddExpense setViewType={getdata} admin={admin} editData={editData} />
                : ""}

            {txnData.length > 0 && viewType === 'list' && loader === false ? (
                <div className="expense-table" style={{ overflow: 'auto' }}>
                    <button className='btn btn-primary' style={{ marginTop: '20px',marginBottom:'20px' }} onClick={() => setviewType('add')}><i className='fa fa-add'></i>Add Expense</button>
                    <div style={{overflowY:'auto'}}>
                    <table>
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th onClick={() => sortingBy('expenseDate')}>
                                    Added On &nbsp;
                                    {orderState.expenseDate === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th onClick={() => sortingBy('departmentName')}>
                                    Department&nbsp;
                                    {orderState.departmentName === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th onClick={() => sortingBy('categoryName')}>
                                    Category&nbsp;
                                    {orderState.categoryName === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th onClick={() => sortingBy('subCategoryName')}>
                                    SubCategory&nbsp;
                                    {orderState.subCategoryName === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                {/* <th>Expense Ledger</th> */}
                                <th onClick={() => sortingBy('vendorName')}>
                                    Vendor Name&nbsp;
                                    {orderState.vendorName === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th onClick={() => sortingBy('amount')}>
                                    Amount&nbsp;
                                    {orderState.amount === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th>GST Amount</th>
                                <th onClick={() => sortingBy('paymentStatus')}>
                                Payment Status&nbsp;
                                    {orderState.paymentStatus === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                </th>
                                <th>Spending Type</th>
                                <th>Verified By</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        {console.log(orderState)}
                        <tbody>
                            {txnData.map((transaction) => (
                                <tr key={transaction.id}>
                                    <td style={{textAlign:'center'}} onClick={() => handleRowClick(transaction)}><b>i</b></td>
                                    <td>{transaction.expenseDate}</td>
                                    <td>{transaction.departmentName}</td>
                                    <td>{transaction.categoryName}</td>
                                    <td>{transaction.subCategoryName}</td>
                                    {/* <td>{transaction.expenseLedger}</td> */}
                                    <td>{transaction.vendorName}</td>
                                    <td>{transaction.expenseAmount}</td>
                                    <td>{transaction.gstAmount}</td>
                                    <td>{transaction.paymentStatus}</td>
                                    <td>{transaction.spendingType}</td>
                                    <td>{transaction.varifiedBy}</td>
                                    <td> <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(transaction)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </div>
            ) : (
                <p style={{ marginTop: '100px' }} className="text-center">
                <img src={NodataImage} style={{ width: '30%' }} />
            </p>
            )}

            {selectedTxn && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Transaction Details</h2>
                        <div className="row">
                            <div className="col-sm-3 col-xs-12"><strong>Added On</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.addedOn}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Added By</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.addedBy ? selectedTxn.addedBy : 'N/A'}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Category Name</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.categoryName}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Comment</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.comment || 'No comment'}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Department Name</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.departmentName}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Expense Amount</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.expenseAmount}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Expense Date</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.expenseDate}<div style={{ marginTop: '10px' }}></div></div>
                            {/* <div className="col-sm-3 col-xs-12"><strong>Expense Ledger</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.expenseLedger}<div style={{ marginTop: '10px' }}></div></div> */}
                            <div className="col-sm-3 col-xs-12"><strong>GST Amount</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.gstAmount}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>GST Inclusive</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.gstinclusive ? 'Yes' : 'No'}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Invoice Number</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.invoiceNumber}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Payment Mode</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.paymentMode}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Paid</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.paid ? "Yes" : "No"}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Spending Type</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.spendingType}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>SubCategory Name</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.subCategoryName}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Transaction Number</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.transactionNumber}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Updated By</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.updatedBy || 'N/A'}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Verified By</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.varifiedBy}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Vendor Name</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.vendorName}<div style={{ marginTop: '10px' }}></div></div>
                            <div className="col-sm-3 col-xs-12"><strong>Verified</strong> <div style={{ marginTop: '5px' }}></div>{selectedTxn.verified ? 'Yes' : 'No'}<div style={{ marginTop: '10px' }}></div></div>
                        </div>
                        <button onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Transaction;
