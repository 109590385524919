import React from 'react'
import Nodata from '../../../../images/nodata.png'
import { Pagination } from '@mui/material'

const UserRawMsg = ({
  tableMaxPagination,
  tableMinPagination,
  tablePagination,
  rawMsgData,
  getFilterValue,
  FilteredData,
  getPageValue,
  pageIndexToShow,
  filterHandler,
  loaderState,
  handlePageChange,
  currentPage,
  smsJsonData,
  jsondata
}) => (
  <div className='container-fluid'>
    {/* <h3 className='heading-analysis'>Raw Messages</h3> */}
    <div className='container-fluid' style={{ background: '#b9d8f9', padding: '10px', marginTop: '10px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} >

      <div className='row'>
        {/* <div className='col-xs-12 col-sm-2'>
      </div> */}
      <div className='col-xs-12 col-sm-4'>
        {/* <label style={{colo}} className='font-12px'>MESSAGES FILTER</label> */}
        <select className='form-control text-capitalize' onChange={(e) => filterHandler(e)}>
          <option value=''>Select</option>
          <option value='exhaust'>exhaust</option>
          <option value='legal'>legal</option>
          <option value='clear'>clear</option>
          <option value='pay dues'>pay dues</option>
          <option value='overdue'>overdue</option>
          <option value='legal action'>legal action</option>
          <option value='immediately'>immediately</option>
          <option value='reminder'>reminder</option>
          {/* <option value='DRP'>DRP</option> */}
          <option value='Arham'>Arham</option>
          <option value='Credfin'>Credfin</option>
          <option value='loan'>loan</option>
        </select>
      </div>
      <div className='col-xs-12 col-sm-4'>
        {/* <label style={{colo}} className='font-12px'>Enter Keywords</label> */}

          <input placeholder='Type keywords...' onChange={(e) => filterHandler(e)} className='form-control' /></div>
        <div className='col-xs-12 col-sm-2'>
        </div>
      </div>
    </div>
    <div>
      {rawMsgData != null && rawMsgData != '' ? (
        <div className='credit-table-finbit' >
          <table className='text-center  ' style={{ marginTop: '0px' }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Raw Message</th>
              </tr>
            </thead>
            <tbody>

              {rawMsgData != null && rawMsgData != '' && rawMsgData.length > 0
                ? rawMsgData
                  .slice(tableMinPagination, tableMaxPagination)
                  .map((data, i) => {
                    // console.log(data)
                    const messageType = data.message;

                    const isEmiIncluded = jsondata?.Raw && jsondata.Raw.some(item => item.message === messageType);

                    return (
                      <tr key={i}
                        style={{
                          background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                        }}
                        onClick={() => smsJsonData('Raw', data)}>
                        {/* { data.message.includes('OTP') == false && data.message.includes('Verification Code' || 'verification code') == false && data.message.includes('verification code') == false? */}
                        <td>{data.date}</td>
                        {/* :""} */}
                        {/* {console.log(data.message.includes('Verification Code' || 'verification code'))}
                           { data.message.includes('OTP') == false && data.message.includes('Verification Code' || 'verification code') == false && data.message.includes('verification code') == false? */}
                        <td>{data.message}</td>
                        {/* :""} */}

                      </tr>
                    )
                  })
                : ''}
            </tbody>
          </table>
        </div>
      ) : loaderState != true ?
        <h3 className='text-center blue-text'>
          <img src={Nodata} width='300px' />
        </h3>
        : ""}
      {rawMsgData != null && rawMsgData != '' ?
        <div style={{ marginTop: '20px', marginBottom: '20px', float: 'right' }}>
          <Pagination
            count={Math.ceil(rawMsgData.length / 10)}  // Set the number of pages
            page={currentPage}  // Set the current active page
            onChange={(event, value) => handlePageChange(event, value)}  // Handle page change
            color='primary'
          />
        </div>
        // <div className='margin-bottom-6perc'>
        //   <div className='row'>
        //     <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => tablePagination(rawMsgData, 'back')}></button></div>
        //     <div className="col-sm-2 col-xs-12  phoc text-center" >
        //       {tableMinPagination +
        //         1 +
        //         ' - ' +
        //         tableMaxPagination +
        //         ' of ' +
        //         rawMsgData.length}
        //     </div>
        //     <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " o style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => tablePagination(rawMsgData, 'forward')}></button></div>
        //   </div>
        // </div>
        : ""}
    </div>
  </div>
)

export default UserRawMsg
