import React, { useState } from "react";
import MainExpenses from "./main";
import MainCategory from "./category/main";
import MainSubCategory from "./subcategory/main";
import MainDescription from "./description/main";
import MainTarget from "./Targets/main";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const TabsExpense = ({pageViewHandler}) => {
    const [viewState, setViewState] = useState('businessPlan')

    const BackBtnStyle2 = {
        color: '#fff', 
        fontWeight: '400', 
        display: 'flex', 
        background:'#006c67',
        cursor:'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }
    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
            <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div>
                <div className="" onClick={() => setViewState('businessPlan')} style={{ borderBottom: viewState === 'businessPlan' ? '2px solid #006c67' : '', background: viewState === 'businessPlan' ? '#006c67' : '#DBE9FF', padding: viewState === 'businessPlan' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessPlan' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Business Plan

                </div>
                <div className="" onClick={() => setViewState('businessReturn')} style={{ borderBottom: viewState === 'businessReturn' ? '2px solid #006c67' : '', background: viewState === 'businessReturn' ? '#006c67' : '#DBE9FF', padding: viewState === 'businessReturn' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessReturn' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Return

                </div>
                <div className="" onClick={() => setViewState('businessTargetcategory')} style={{ borderBottom: viewState === 'businessTargetcategory' ? '2px solid #006c67' : '', background: viewState === 'businessTargetcategory' ? '#006c67' : '#DBE9FF', padding: viewState === 'businessTargetcategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessTargetcategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target Category

                </div>
                <div className="" onClick={() => setViewState('businessTargetSubcategory')} style={{ borderBottom: viewState === 'businessTargetSubcategory' ? '2px solid #006c67' : '', background: viewState === 'businessTargetSubcategory' ? '#006c67' : '#DBE9FF', padding: viewState === 'businessTargetSubcategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businessTargetSubcategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target Subcategory

                </div>
                <div className="" onClick={() => setViewState('businesstarget')} style={{ borderBottom: viewState === 'businesstarget' ? '2px solid #006c67' : '', background: viewState === 'businesstarget' ? '#006c67' : '#DBE9FF', padding: viewState === 'businesstarget' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'businesstarget' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                Business Target

                </div>

            </div>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 " style={{ marginTop: window.innerWidth > 1800 ? '0%' : '5%'}}>
                        <button style={{ color: '#223d64', fontWeight: '400', display: 'flex', border: '1px solid #267dff', borderRadius: '6px', padding: '8px 10px' }} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back</button>
                    </div>
                </div>
            </div> */}
            <div className="" style={{ marginTop: window.innerWidth > 1800 ? '0%' : '3%'}}>
            {viewState === 'businessPlan' ?
                <MainExpenses />
                : ""}
            {viewState === 'businessReturn' ?
                <MainCategory />
                : ""}
            {viewState === 'businessTargetcategory' ?
                <MainSubCategory />
                : ""}
            {viewState === 'businessTargetSubcategory' ?
                <MainDescription />
                : ""}
            {viewState === 'businesstarget' ?
                <MainTarget />
                : ""}
                </div>
        </>
    )
}
export default TabsExpense