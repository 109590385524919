import React, { useState,useEffect } from 'react'
import Loader from '../../../../../presentationals/Loader/Loader.component'
import { getExpenseProvisionDetailsBycategoryId } from '../../targetactioncreator';
import NodataImage from '../../../../../../images/nodata.png';

export default function Provisionsection({ selectedData, admin }) {
    const [loader, setLoader] = useState(false);
    const [data,setData] =useState([])
 const [orderState, setOrderState] = useState({
    provisionDate: 'asc',
    expenseDescription: 'asc',
    departmentName: 'asc',
    provisionAmount: 'asc',
    provisionType: 'asc',
    });

    useEffect(()=>{
        setLoader(true)
        getExpenseProvisionDetailsBycategoryId(selectedData.expenseCategoryId,orderState.provisionDate,"provisionDate",(callback)=>{
            console.log(callback);
            setLoader(false)
            if(callback && callback?.status == 200){
                setData(callback?.data);
            }else{
                setData([]);
            }
        })
    },[selectedData.expenseCategoryId])


    const sortingBy = (column) => {
            const currentOrder = orderState[column];
            const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
    
            setOrderState((prevOrderState) => ({
                ...prevOrderState,
                [column]: newOrder,
            }));
            setLoader(true)
            getExpenseProvisionDetailsBycategoryId(selectedData.expenseCategoryId, newOrder, column, (callback) => {
                console.log(callback);
                setLoader(false)
                if(callback && callback?.status == 200){
                    setData(callback?.data);
                }else{
                    setData([]);
                }
            });
        };

    return (
        <div className="container-fluid expense-popup" style={{ maxHeight: '550px', overflow: 'auto' }}>
            {loader ?
                <Loader />
                : ""}

            {data && data.length > 0 && loader === false ? (
                <div className="expense-table" style={{ overflow: 'auto' }}>
                    <div style={{ overflowY: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{textAlign:'center'}} onClick={() => sortingBy('provisionDate')}>
                                        Provision Date
                                        {orderState.provisionDate === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                        </th>
                                    <th style={{textAlign:'center'}} onClick={() => sortingBy('expenseDescription')}>
                                        Expense Description
                                        {orderState.expenseDescription === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                        </th>
                                    <th style={{textAlign:'center'}} onClick={() => sortingBy('departmentName')}>
                                        Department Name
                                        {orderState.departmentName === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                        </th>
                                    <th style={{textAlign:'center'}} onClick={() => sortingBy('provisionAmount')}>
                                        Provision Amount
                                        {orderState.provisionAmount === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                        </th>
                                    <th style={{textAlign:'center'}} onClick={() => sortingBy('provisionType')}>
                                        Provision Type
                                        {orderState.provisionType === 'asc' ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                                        </th>
                                    <th style={{textAlign:'center'}}>Comment</th>
                                    {/* <th>Edit</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td style={{textAlign:'center'}}>{transaction?.provisionDate ?? "-" }</td>
                                        <td style={{textAlign:'center'}}>{transaction?.expenseDescription ?? "-" }</td>
                                        <td style={{textAlign:'center'}}>{transaction?.departmentName ?? "-" }</td>
                                        <td style={{textAlign:'center'}}>{transaction?.provisionAmount ?? "-" }</td>
                                        <td style={{textAlign:'center'}}>{transaction?.provisionType ?? "-" }</td>
                                        <td style={{textAlign:'center'}}>{transaction?.comment ?? "-" }</td>
                                        {/* <td> <i
                                            className="fa fa-pencil-square-o fa-lg"
                                            aria-hidden="true"
                                            onClick={() => editHandler(transaction)}
                                            style={{ cursor: 'pointer' }}
                                            aria-label="Edit Expense"
                                            title="Edit Expense"
                                        ></i></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <p style={{ marginTop: '100px' }} className="text-center">
                <img src={NodataImage} style={{ width: '30%' }} />
            </p>
            )}
        </div>
    )
}
