import React, { useState, useEffect } from 'react'
import SamplecsvFile from '../../../maketingimages/singular.csv'
import { SaveSingularLogsCSV, getLatestEventTimestampAPI } from '../../actioncreator'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import PopUp from '../../../../../presentationals/Popup/Popup.component';

export default function Singular() {
  const [timeStampData, settimeStampData] = useState({})
  const [errorPopup, setErrorPopup] = useState(false)
  const [csvFile, setCsvFile] = useState(null);
  const [nameOfFile, setNameOfFile] = useState('');
  const [csvData, setCsvData] = useState('');
  const [csvSaveData, setSaveData] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [popupStatus, setPopupStatus] = useState('');

  useEffect(() => {
    getLatestEventTimestampAPI(callback => {
      console.log(callback)
      if (callback && callback?.message == 'success') {
        settimeStampData(callback?.data)
      }
    })
  }, []);

  const changeHandler = (e) => {
    setCsvFile(e.target.files[0]);
    setNameOfFile(e.target.files[0].name);
  };

  const submitHandler = () => {
    setLoaderState(true);
    SaveSingularLogsCSV(csvFile, (callback) => {
      console.log(csvFile);
      console.log(callback);
      if (callback && callback?.message == 'success' && callback?.data == null) {
        setCsvData(callback?.data);
        setLoaderState(false);
        setPopupState(true)
        setPopupStatus('CSV uploaded Successfully')
      } else if (callback && callback?.message == 'success') {
        setCsvData(callback?.data);
        setLoaderState(false);
        setPopupState(true)
        setPopupStatus('Errors in CSV File Uploaded')
      } else if (callback && callback?.message == 'failure') {
        setPopupState(true)
        setPopupStatus(callback?.data || '')
      } else {
        setPopupState(true)
        setPopupStatus('Error Uploading CSV File')
      }
      setLoaderState(false);
    });
  };


  const closePopup = () => {
    setPopupState(false)
  }
  const removePopup = () => {
    setTimeout(() => {
      setPopupState(false)
    }, 5000);
  }
  return (
    <>
      {loaderState && <Loader />}
      {popupState ? <PopUp popupStatus={popupStatus} closePopup={closePopup} /> : ""}
      <div className='container-fluid' style={{marginTop:'12%'}}>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <h3>
              <b>Singular</b>
            </h3>
          </div>
          <div className=" col-lg-4 col-sm-6 col-xs-12" style={{ float: 'inline-end',marginTop:'10px' }}>
                            <div className='count-div' style={{display:'flex'}}>
                                <div className="btn-count-new text-center" style={{marginRight:'10px' }}><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700'}}>LATEST EVENT TIMESTAMP <br></br><br></br> </div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{timeStampData?.latestEventTimestamp  || '-'}</span></div>
                                <div className="btn-count-new text-center"><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700' }}>LATEST ADDED ON <br></br><br></br> </div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{timeStampData?.latestAddedOn  || '-'}</span></div>
                            </div>
                        </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <div className='upload-csv text-center' style={{ marginTop: '53px' }}>
              <label className='' htmlFor='formFileLg'>
                <i className='bi bi-cloud-arrow-up-fill me-3'></i>
                <span className='align-middle'>
                  {nameOfFile ? (
                    nameOfFile
                  ) : (
                    <>
                      <i className='fa fa-cloud-upload' style={{ fontSize: '20px', marginTop: '10px' }}></i>
                      &nbsp;&nbsp; Click here to Upload CSV File
                    </>
                  )}
                </span>
              </label>
              <input
                className='form-control form-control-lg'
                id='formFileLg'
                accept='.csv'
                type='file'
                onChange={(e) => {
                  changeHandler(e);
                }}
              />
            </div>
          </div>
          <div className='' style={{ display: 'flex' }}>
            <div >
              {nameOfFile && (
                <div className='text-center' style={{ marginTop: '50px', }}>
                  <button className='btn btn-primary' style={{ background: '#006c67', padding: '15px',color:'#fff' }} onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              )}
            </div>
            &nbsp;&nbsp;
            <div >

              <a href={SamplecsvFile}>
                <button className='btn btn-primary' style={{ background: '#006c67', marginBottom: '20px', marginTop: '50px', padding: '15px',color:'#fff' }}>
                  <i className='fa fa-cloud-download'></i>&nbsp;&nbsp;Download CSV Sample File{' '}
                </button>
              </a>
            </div>
            <div>
              {
                csvData && csvData?.notSaved?.length > 0 ?
                  <div className='text-center' style={{ marginTop: '50px', marginLeft: '8px' }}>
                    <button className='btn btn-primary' style={{ background: '#006c67', padding: '15px',color:'#fff' }} onClick={() => setErrorPopup(true)}>
                      Errors in CSV
                    </button>
                  </div>
                  : ''}
            </div>
          </div>
        </div>
        <div className='row'>
          {errorPopup ? (
            <div>
              <div className="background-blur" onClick={(e) => setErrorPopup(false)}></div>
              <div className="popup-main small-popup container-fluid dbl-border" style={{ maxWidth: '1000px', width: '100%' }}>
                <div className="row from-group">
                  <div className="col-xs-12">
                    <h4 className="text-center">Errors In Uploaded Csv</h4>
                  </div>
                </div>
                <div style={{ overflowX: 'auto', maxHeight: '500px' }}>
                  <div className="row from-group text-center" style={{padding:'15px'}} >
                    <table className='phocket-table'>
                      <thead style={{position:'sticky',top:'0',zIndex:'1',width:'100%'}}>
                        <tr>
                          <th className='text-center'>S.No.</th>
                          <th className='text-center'>Line No.</th>
                          <th className='text-center'>Error</th>
                        </tr>
                      </thead>
                      <tbody >
                        {csvData?.notSaved?.map((csvKey,index) => {
                          let [before, after] = csvKey.split(/:(.+)/)
                          return (
                            <tr key={index} >
                              <td>{index + 1}</td>
                              <td>{before}</td>
                              <td>{after}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : ''}

        </div>
      </div>
    </>
  )
}
