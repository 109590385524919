import React, { useState, useEffect } from 'react'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine, LabelList } from 'recharts';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';
import { getAllExpenseSubcategoryBycategory,getcategoryWiseTargetListGraphdata } from '../../targetactioncreator';
import { Call } from '@mui/icons-material';
import moment from 'moment';

export default function listNew({ fromDate, toDate, ActiveMonthFilter, categorySelected, applyFilter, applyFilterState,SubcategorySelected }) {
    const [popoverData, setPopOverdata] = useState({ show: false, data: '' })
    const [loader, setLoader] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [graphAPIData, setgraphAPIData] = useState([])

    useEffect(() => {
        DataAPI(categorySelected, fromDate, toDate)
        getcategoryWiseTargetListGraphdataAPI(categorySelected, fromDate, toDate,SubcategorySelected)
        // if (categorySelected !== "" && categorySelected !== null && categorySelected !== undefined && applyFilter == true) {
        // }
    }, [categorySelected,SubcategorySelected, applyFilter])


    const DataAPI = (id, sDate, eDate) => {
        const formattedFromDate = moment(sDate).format('DD-MM-YYYY');
        const formattedToDate = moment(eDate).format('DD-MM-YYYY');
        setLoader(true)
        getAllExpenseSubcategoryBycategory(id, formattedFromDate, formattedToDate, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.status == 200 && callback?.message == "success") {
                setCategoryData(callback?.data)
                setgraphAPIData([
                    {
                        name: "Target Amount",
                        amount: callback?.data?.categoryDetail?.categoryTarget || 0,
                    },
                    {
                        name: "Current Amount",
                        amount: callback?.data?.categoryDetail?.categoryExpense || 0,
                    },
                    {
                        name: "Deviation Amount",
                        amount: callback?.data?.categoryDetail?.deviation || 0,
                    },
                ])
            } else {
                setCategoryData([])
            }
            applyFilterState()
        })
        applyFilterState()
    }

    const getcategoryWiseTargetListGraphdataAPI =(categoryId,startDate,endDate,subcategoryId)=>{
        getcategoryWiseTargetListGraphdata(categoryId,startDate,endDate,subcategoryId,(callback)=>{
            console.log("call",callback)
        })
    }



    const handlePopOver = (text) => {
        setPopOverdata({ show: true, data: text })
    }


    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 10;
        const setValues=(d)=>{
            const str =  graphAPIData.filter((ele)=>ele.name === d)
            let amount = (Number(str[0].amount))?.toLocaleString("en-US")
            return amount
        }
        return (
          <g>
            <text x={x + width / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
            {value?.length > 0 ? setValues(value) : ""}
            </text>
          </g>
        );
      };


    return (
        <>
            <div className="targetListNewCSS">
                {
                    loader ?
                        <Loader />
                        : ''}
                {/* {
                    categorySelected && categorySelected !== "" && categorySelected !== null && categorySelected != undefined ? */}
                        <div className="paymentInsightsMain">
                            <div className="paymentOverviewSection outerBoxWithPadding" style={{ padding: '15px', marginTop: '40px' }}>
                                <div className="PaymentBoxes">
                                    <div className="tabs">
                                        <div className="subtabs" style={{ backgroundColor: '#ffe2e6', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                <div className="circlesIcon" style={{ backgroundColor: '#fa5a7d', textAlign: 'center' }}><i class="fa fa-bullseye " aria-hidden="true"></i></div>
                                            </div>
                                            <h4>{'₹ ' + (categoryData?.categoryDetail?.categoryTarget || 0)?.toLocaleString("en-US")}</h4>

                                            <h5>Target Amount</h5>
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="subtabs" style={{ backgroundColor: '#fff4de', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                <div className="circlesIcon" style={{ backgroundColor: '#ff947a' }}><i class="fa fa-money" aria-hidden="true"></i></div>

                                            </div>
                                            <h4>{'₹ ' + (categoryData?.categoryDetail?.categoryExpense || 0)?.toLocaleString("en-US")}</h4>

                                            <h5>Expense Amount</h5>
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="subtabs" style={{ backgroundColor: '#fff1c1', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                <div className="circlesIcon" style={{ backgroundColor: '#dbb328' }}><i class="fa fa-money" aria-hidden="true"></i></div>

                                            </div>
                                            <h4>{ (categoryData?.categoryDetail?.categoryExpensePercentage || 0)?.toLocaleString("en-US") + ' %' }</h4>

                                            <h5>Expense % AUM</h5>
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="subtabs" style={{ backgroundColor: '#dcfce7', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                <div className="circlesIcon" style={{ backgroundColor: '#3dd856' }}><i class="fa fa-signal" aria-hidden="true"></i></div>
                                            </div>
                                            <h4>{'₹ ' + (categoryData?.categoryDetail?.deviation || 0)?.toLocaleString("en-US")}</h4>

                                            <h5>Deviation Amount</h5>
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="subtabs" style={{ backgroundColor: '#f4e8ff', height: window.innerWidth < 1200 ? '170px' : '156px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'basline' }}>
                                                <div className="circlesIcon" style={{ backgroundColor: '#bf83ff' }}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                            </div>

                                            <h4>{(categoryData?.categoryDetail?.deviationPercentage || 0)?.toLocaleString("en-US") + ' %' }</h4>

                                            <h5>Deviation % AUM</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="graphSections outerBoxWithPadding" style={{ padding: '15px', marginTop: '50px' }}>
                                <div className="graphData" style={{ height: '340px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={graphAPIData}
                                            barSize={30}
                                            margin={{
                                                top: 25,
                                                right: 10,
                                                left: 30,
                                                bottom: 2,
                                            }}
                                        >
                                            <defs>
                                                <linearGradient id="barGradient1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="0%" stopColor="#223D64" stopOpacity={1} />
                                                    <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient id="barGradient2" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="0%" stopColor="#6d97ae" stopOpacity={1} />
                                                    <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient id="barGradient3" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                    <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <CartesianGrid stroke="none" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            {/* <Tooltip content={<CustomTooltip />} /> */}
                                            <ReferenceLine y={0} stroke="#9f9f9f" />
                                            <Bar dataKey="amount" fill="url(#barGradient2)" radius={[3, 3, 0, 0]} >
                                                <LabelList dataKey="name" content={renderCustomizedLabel} />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>


                            <div className="expense-table outerBoxWithPadding" style={{ marginTop: '50px', padding: '15px',overflowX:'auto' }}>
                                {
                                    categoryData && categoryData?.subCategoryList && categoryData?.subCategoryList?.length > 0 ?
                                        <table style={{ marginTop: '0px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'center' }}>Sub-Category Name</th>
                                                    <th style={{ textAlign: 'center' }}>Sub-Category Type</th>
                                                    <th style={{ textAlign: 'center' }}>Target Amount</th>
                                                    <th style={{ textAlign: 'center' }}>Target %</th>
                                                    <th style={{ textAlign: 'center' }}>Current Amount</th>
                                                    <th style={{ textAlign: 'center' }}>Current % </th>
                                                    <th style={{ textAlign: 'center' }}>Deviation Amount</th>
                                                    <th style={{ textAlign: 'center' }}>Deviation %</th>
                                                    {/* <th style={{ textAlign: 'center', minWidth: '200px' }}>Comment</th> */}
                                                    <th style={{ textAlign: 'center', minWidth: '200px' }}>No. Of Expense</th>
                                                    <th style={{ textAlign: 'center', minWidth: '200px' }}>No. Of Provisions</th>
                                                    <th style={{ textAlign: 'center', minWidth: '200px' }}>No. Of Target</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categoryData?.subCategoryList?.map((da, index) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'center' }}>{da?.subCategoryName || ""}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.subCategoryType || ""}</td>
                                                        <td style={{ textAlign: 'center' }}>{(da?.subCategoryTarget || 0)?.toLocaleString("en-US") || ""}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.targetPercentageAum || 0} %</td>
                                                        <td style={{ textAlign: 'center' }}>{(da?.subCategoryExpense || 0)?.toLocaleString("en-US") || ""}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.currentPercentage || 0} %</td>
                                                        <td style={{ textAlign: 'center' }}>{(da?.deviation || 0)?.toLocaleString("en-US") || ""}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.deviationPercentage || 0} %</td>
                                                        {/* <td style={{textAlign:'center'}}>{(da?.comment?.length > 30 ? `${(da?.comment).substring(0,30)} ` : da?.comment) || ""}</td> */}
                                                        {/* <td style={{ textAlign: 'center' }}>
                                                            {
                                                                da?.comment?.length > 30 ?
                                                                    <>
                                                                        {(da?.comment).substring(0, 30)}<button type="button" class="btn btn-secondary" style={{ padding: '0px' }} onClick={() => handlePopOver(da?.comment)}>...</button>
                                                                    </>
                                                                    : (da?.comment)}
                                                        </td> */}
                                                        <td style={{ textAlign: 'center' }}>{da?.noOfExpense || 0}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.noOfProvision || 0}</td>
                                                        <td style={{ textAlign: 'center' }}>{da?.noOfTarget || 0}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                        (
                                            <p style={{ marginTop: '100px' }} className="text-center">
                                                <img src={NodataImage} style={{ width: '30%' }} />
                                            </p>
                                        )}
                            </div>

                            {
                                popoverData?.show == true && popoverData?.data?.length > 0 ?
                                    <>
                                        <div className="background-blur " onClick={() => setPopOverdata({ show: false, data: '' })}></div>
                                        <div
                                            className="popup-main small-popup container-fluid dbl-border"
                                            style={{ marginTop: '40px', width: '90%', maxWidth: '60%' }}
                                        >
                                            <div className="popup-content">
                                                <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }}>Comment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }}>{popoverData?.data}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    : ''}
                        </div>
                        {/* : ''} */}
            </div>
        </>
    )
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const barValue = payload[0]?.payload;
        return (
            <div
                style={{
                    position: "relative",
                    background: "white",
                    border: "1px solid #fff",
                    boxShadow: '3px 3px 10px -2px rgb(206, 203, 203)',
                    borderRadius: "5px",
                    padding: "10px",
                    fontSize: "14px",
                    textAlign: "center",
                }}
            >
                <div style={{ marginBottom: "5px" }}>
                    <strong>{label}</strong>
                </div>
                <div>{`${barValue?.name}: ${(Number(barValue?.amount))?.toLocaleString("en-US")}`}</div>
            </div>
        );
    }
    return null;
};