import React, { useState } from "react";
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Account from "./AccountReconciliaitonPage";
import PaymentUi from '../Payments/AllPaymentsNewTab'
import PaymentInsigts from '../Payments/PaymentInsights/PaymentInsightsMain'
import OverDueEmi from "../../Admin/ApplicationLeads/overdueLeads.container";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { ROLE } from "../../../../utils/Constant";
import { Insights, Payment, UnarchiveRounded, VoiceOverOffRounded } from "@mui/icons-material";
import PaymentsMainTab from "./PaymentsMainTab";
import SettleMents from "../Payments/Settlements/Main";
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import TargetList from "./Targetssalary/mainTargets";

const MainEmp = ({ admin, allStatus, newDataBackground, pageViewHandler }) => {
    const [viewType, setviewType] = useState('allpayments')
    const [editData, seteditData] = useState('')
    const [headerHide,setHeaderHide] =useState(false)
    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }

    const HeadingHide = (value)=>{
        setHeaderHide(value)
    }

    return (
        <>
        {
            !headerHide ?
            <div className="container-fluid details-new fixed-top"style={{zIndex:'3'}} >
                <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
                    <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                        <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                    </div>

                    <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                        <div className="btn-group-new">
                        <button className={(viewType === 'allpayments') ? "active" : ""} onClick={() => setviewType('allpayments')}><Payment style={{ fontSize: '27px' }} /><br />All Payments</button>
                            <button className={(viewType === 'payments') ? "active" : ""} onClick={() => setviewType('payments')}><Payment style={{ fontSize: '27px' }} /><br />Payments</button>
                            {
                                            admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                            admin.rolelist.indexOf(ROLE.Payment_Insight) >= 0 ?
                            <button className={(viewType === 'insights') ? "active" : ""} onClick={() => setviewType('insights')}><Insights style={{ fontSize: '27px' }} /><br />Payment Insights</button>
                            :'':''}
                            <button className={(viewType === 'reconcilation') ? "active" : ""} onClick={() => setviewType('reconcilation')}><WalletIcon style={{ fontSize: '27px' }} /><br />Account Reconciliation</button>
                            <button className={(viewType === 'overdue') ? "active" : ""} onClick={() => setviewType('overdue')}><ReceiptIcon style={{ fontSize: '27px' }} /><br />Overdue Emi's</button>
                            <button className={(viewType === 'settlement') ? "active" : ""} onClick={() => setviewType('settlement')}><DoneAllIcon style={{ fontSize: '27px' }} /><br />Settlements</button>

                        </div>

                    </div>
                </div>
            </div>
            :""}
            <div style={{ marginTop:viewType==='reconcilation'?'1.5%': viewType==='payments'? "16px" :viewType === 'allpayments' || viewType === 'settlement' ? "0px" :'60px' }}>
                {viewType === 'reconcilation' ?
                    <Account admin={admin}
                        allStatus={allStatus}
                        newDataBackground={newDataBackground} showButton={false} editHandler={editHandler} />
                    : ""}
                {viewType === 'allpayments' ?
                    <PaymentUi showButton={false} admin={admin} editData={editData} />
                    : ""}
                
                    {viewType === 'payments' ?
                    <PaymentsMainTab showButton={false} admin={admin} editData={editData} />
                    : ""}
                       {viewType === 'insights' ?
                    <PaymentInsigts showButton={false} admin={admin} editData={editData} />
                    : ""}
                        {viewType === 'overdue' ?
                    <OverDueEmi showButton={false} admin={admin} editData={editData} HeadingHide={HeadingHide}/>
                    : ""}
                        {viewType === 'settlement' ?
                    <SettleMents showButton={false} admin={admin}/>
                    : ""}

            </div>

        </>
    )
}
export default MainEmp