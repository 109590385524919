import React, { useState } from "react";
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import { CrisisAlert, Dashboard, List } from "@mui/icons-material";
import AllNbfc from "./allnbfc";
import ListNbfc from "./nbfclisting";


const NbfcTabs = ({ pageViewHandler,admin }) => {
    const [page, setPage] = useState('dashboard')
    const pageHandler = (data) => {
        setPage(data)


    }
    return (
        <div className="container-fluid">
            <div className="details-new fixed-top">
                <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }} >
                    <div className="col-sm-4 " style={{ marginTop: '15px' }}>
                        <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                    </div>
                    <div className="col-sm-8 col-xs-12" style={{ display: 'flex' }}>
                        <div className="btn-group-new" style={{ marginLeft: 'auto', marginRight: '20px' }}>
                            <button className={page === 'dashboard' ? 'active' : ''} onClick={(e) => pageHandler('dashboard')}><Dashboard /><br />Dashboard</button>
                            <button className={page === 'liveReports' ? 'active' : ''} onClick={(e) => pageHandler('liveReports')}><List /><br />Listing</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '30px' }}></div>
            {page === 'dashboard' ?
                <AllNbfc admin={admin} />
                : ""}
                  {page === 'liveReports' ?
                <ListNbfc admin={admin}  />
                : ""}
        </div>
    )
}
export default NbfcTabs