import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    onChangeUserNav, onUserContentChange, resetUserState,
    getLoanApprovalLead, saveFollowUpStatus, saveFollowUpStatusForGiftCard, getLoanDetailByUserId, rfdApi, repeatUserCheckUpdated
} from '../AdminActionCreator.component';
import localStorageUtils from '../../../../utils/localStorageUtil';
import UserDetailNav from '../../../presentationals/Admin/CustomerPages/detailtabfordisburse.component'
import UserMainView from './UserDetail.container'
import UserStatus from '../../../presentationals/Admin/CustomerPages/UserStatus.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { ROLE, STRING } from '../../../../utils/Constant'
import UserDetailPopupUi from './UserDetailPopup.container'
import Loader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment';
import ButtonPopup from '../../../presentationals/Popup/buttonPopup';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
import { APIS } from '../../../../utils/api-factory';
import ConfirmationPopup from '../../../presentationals/Popup/markcompletePopup'
import IncompletePopup from '../../../presentationals/Popup/incompletecommentpopup'
import Action from './NewActionHandle';

class CRMUserContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            CreditScorePopupState: false,
            creditScoringPopup: false,
            searchByEndDate: '',
            tabType: '',
            dataChange: this.props.dataChange,
            newAllDetail: '',
            loaderState: false,
            ButtonPopupState: false,
            confirmationPopupIncomplete: false,
            confirmationText: '',
            confirmationPopupState: false,
            completeType: '',
            invalidMsg: '',
            incompleteType: '',
            confirmationPopupinCompleteState: false,
            teaGardenId: '',
            repeatUserData: ''
        }

    }
    completeHandler(e) {
        this.setState({ completeType: e.target.value })
    }
    incompleteHandler(e) {
        this.setState({ incompleteType: e.target.value })
    }

    completeFunction(buttonType) {
        const { allDetail, admin, userDetails } = this.props
        if (buttonType == 'yes') {
            if (this.state.completeType !== '') {
                this.setState({ invalidMsg: '' })
                if (userDetails.typeOfProduct !== 'Gift Card') {
                    fetch(
                        APIS.MARK_APPLICATION_COMPLETE +
                        allDetail.userId +
                        '&loanId=' +
                        allDetail.loanId +
                        '&giftCardId=' + ''
                        + '&completeType=' + this.state.completeType
                        + '&empId=' + admin.emailId
                    )
                        .then(res => res.text())
                        .then(res => {
                            if (res == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Marked Successfully',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            }
                        })
                } else {
                    fetch(
                        APIS.MARK_APPLICATION_COMPLETE +
                        allDetail.userId +
                        '&loanId=' +
                        '' + '&giftCardId=' + userDetails.giftCardId
                        + '&completeType=' + this.state.completeType
                        + '&empId=' + admin.emailId
                    )
                        .then(res => res.text())
                        .then(res => {
                            if (res == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Marked Successfully',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            }
                        })
                }
            } else {
                this.setState({
                    invalidMsg: 'Please Select Complete Type First'
                })
                this.removePopup()
            }
        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    componentWillUnmount() {
        this.props.resetUserState();
    }
    buttonsForFunction(type) {
        if (type === 'complete') {
            this.setState({
                confirmationPopupState: true, confirmationText: 'Are you sure to Mark this application complete ?'
            })

        }
        if (type === 'incomplete') {
            this.setState({ confirmationPopupinCompleteState: true, confirmationText: 'Are you sure to Mark this application Incomplete ?' })
        }
    }



    getLoanLeads() {
        this.props.getLoanApprovalLead(STRING.SELECT_ALL, 1, this.props.admin.emailId, '', this.props.admin.nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', '',
            (callBack) => {

            })
    }
    buttonPopupHandler(type) {
        if (type === 'open') {
            this.setState({ ButtonPopupState: true })

        }
    }
    incompleteFunction(buttonType) {
        const { admin, allDetail, userDetails } = this.props

        if (buttonType == 'yes') {
            fetch(APIS.MARK_APPLICATION_INCOMPLETE + allDetail.userId + '&loanId=' + allDetail.loanId + '&giftCardId=' + userDetails.giftCardId + '&incompleteType=' + this.state.incompleteType + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Marked Successfully',
                            confirmationPopupinCompleteState: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                            confirmationPopupinCompleteState: false
                        })
                        this.removePopup();
                    }
                })

        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupinCompleteState: false
            })
        }
    }
    RfdFunction(data) {
        const { admin, userDetails } = this.props
        rfdApi(userDetails.loanId, data, admin.emailId, userDetails.userId, userDetails.giftCardId, callBack => {
            if (callBack === 'success') {
                this.setState({
                    popupState: true,
                    popupStatus: 'saved successfully'
                })
                this.removePopup()
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please Try Again later!'
                })
                this.removePopup()
            }
        })
    }

    closeButtonPopup() {
        this.setState({ ButtonPopupState: false })
    }
    getTeaGardenData() {
        const { userDetails } = this.props;
        this.setState({ loaderState: true })
        fetch(APIS.GET_USERS_DETAILS_BY_USER_ID + userDetails.userId)
            .then(res => res.json())
            .then(json => {
                if (Object.entries(json).length !== 0 && json.UserToEmpId.teaGardenId !== null && json.UserToEmpId.teaGardenId !== undefined) {
                    this.setState({ teaGardenId: json.UserToEmpId.teaGardenId, loaderState: false })
                } else {

                    this.setState({ loaderState: false })
                }
            })
    }


    render() {
        const { visiblePanel: visibleContentOf, markCompleteBtn } = this.props;  // state props
        const { onChangeUserNav, selectedNavUserOptions, allDetail, userDetail, admin, allStatus, userDetails } = this.props;
        const userNavOptions = [
            "BASIC",
            "FINANCE",
            "ANALYSIS",
            "LOGS",
            "OPS",
            "CONTACTS APP",
            "ANALYSIS NEW",
            "API TRACKER"


            // "CIBIL",
            // "FINANCE",
            // "DOCS",
            // "ACTION",
            // "MESSAGE",
            // "TRANS",
            // "FEEDBACK",
            // "STATUS ACTIVITY",
            // "AGREEMENT",
            // "EDIT PROFILE",
            // "FIELD BOY",
            // "EMI",
            // "EMI History",
            // "CONTACTS APP"
        ];
        return (
            <div className="container-fluid details-new"  onClick={this.state.ButtonPopupState ? () => this.closeButtonPopup() : ""}>
                <Action admin={admin} allStatus={allStatus} allDetail={allDetail} userDetail={userDetails} />
                {/* <div className='row'>
                    <div className='details-new-tab-div'>
                        <div className='row'>

                        </div>
                    </div>
                </div> */}
                {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 || admin.rolelist.indexOf(ROLE.Mark_Complete) >= 0 || admin.rolelist.indexOf(ROLE.Mark_InComplete) >= 0 || admin.rolelist.indexOf(ROLE.Mark_PFD) >= 0 ?
                    <div style={{ float: 'right' }}>
                        {this.state.ButtonPopupState === false ?
                            <div class="page-wrapper" onClick={() => this.buttonPopupHandler('open')}>
                                <div class="" >
                                    <div class="">
                                        <div class="icon" >
                                            <i class="fa fa-cog" style={{ color: 'white' }} aria-hidden="true"></i>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            : ""}

                    </div>
                    : ""} */}
                {/* <div className="row ">
                    <div className="col-sm-7 col-xs-12 form-group">
                        <h3 className="text-center blue-text">
                            <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.detailBackHandler(this.state.dataChange)} />
                            <strong className="teal-text text-uppercase">  {allDetail.firstName}  {userDetails != undefined && userDetails != null && userDetails != '' ? userDetails.applicationId : ""}</strong> </h3>
                    </div>
                    <div className="col-sm-2 col-xs-12 form-group no-padding">
                        {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                            <button className="profile-save-btn center-block" onClick={this.CreditScorePopupState.bind(this)}><span className="fa fa-window-restore" /></button>
                            : null}
                    </div>
                    <div className="col-sm-3 col-xs-12 form-group no-padding">
                        {this.state.popupState == true ?
                            <Popup
                                closePopup={this.closePopup.bind(this)}
                                popupStatus={this.state.popupStatus}
                            />
                            : ""}
                        {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.STATUS_CHANGE_DROPDOWN) >= 0 ?
                            <UserStatus
                                admin={admin}
                                statusHandler={this.statusHandler.bind(this)}
                                allDetail={allDetail}
                                allStatus={allStatus}
                                userDetails={userDetails != undefined ? userDetails : this.props.leadManagementData}
                            />
                            : null}
                    </div>

                </div> */}

                <div className="row ">
                    <div className="col-xs-12 no-padding">
                        <UserDetailNav
                    repeatUserData={this.state.repeatUserData}

                            dataChange={this.state.dataChange}
                            detailBackHandler={this.detailBackHandler.bind(this)}
                            teaGardenId={this.state.teaGardenId}
                            allDetail={allDetail}
                            selectedIndex={selectedNavUserOptions}
                            UserDetailTabsHandler={this.UserDetailTabsHandler.bind(this)}
                            navItemsArray={userNavOptions}
                            admin={admin}
                            actionButtonState={this.props.actionButtonState}
                            userDetail={userDetails}
                        />
                    </div>
                </div>
                <UserMainView
                    UserDetailTabsHandler={this.UserDetailTabsHandler.bind(this)}
                    repeatUserData={this.state.repeatUserData}
                    allStatus={allStatus}
                    userDetailHandler={this.props.userDetailHandler}
                    getData={this.props.getData.bind(this)}
                    dataChange={this.state.dataChange}
                    allDetail={allDetail}
                    userDetail={userDetails}
                    userDetails={userDetails != undefined ? userDetails : this.props.leadManagementData}
                    admin={admin}
                    getCreditPopupState={this.getCreditPopupState.bind(this)}
                    creditScoringPopup={this.state.creditScoringPopup}
                    markCompleteBtn={markCompleteBtn} />


            </div>
        )
    }
    componentDidMount() {

        const { allDetail, userDetails } = this.props
        this.getTeaGardenData()
        repeatUserCheckUpdated(allDetail.userId,allDetail.loanId, callBack => {
            console.log(callBack)
            this.setState({ repeatUserData: callBack })
        })

    }
    CreditScorePopupState() {
        this.setState({ CreditScorePopupState: true })
    }

    getCreditPopupState(state) {
        this.setState({ CreditScorePopupState: state })
    }

    detailBackHandler() {
        this.props.backHandler(this.state.dataChange)
        localStorageUtils.saveInLocalStorage('salaryMailMsg', "novalueSalary")
        localStorageUtils.saveInLocalStorage('docMailMsg', "novalueDoc")
        localStorageUtils.saveInLocalStorage('viewMail', "salary")
        this.props.setDetailsPage(false)
    }

    UserDetailTabsHandler(e, index) {
        this.props.onUserContentChange(index);
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    statusHandler(e) {
        if (e.target.value != '') {
            const { userDetails, admin } = this.props;


            let statusValue = e.target.value
            if (statusValue === '113' && admin.department !== 'Recovery Team') {
                // if (statusValue === '113' && (admin.department === 'Legal' || admin.department === 'Customer Service' || admin.emailId === 'SuperUser' || admin.emailId === 'vishnu.pandey@credfin.money')) {
                this.setState({ loaderState: true })

                if (userDetails.typeOfProduct == 'Loan') {
                    saveFollowUpStatus(userDetails.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                this.getLoanLeads()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                    loaderState: false
                                })
                                this.removePopup();
                            }
                        })
                } else if (userDetails.typeOfProduct == 'Gift Card') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatusForGiftCard(userDetails.giftCardId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                this.getLoanLeads()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                    loaderState: false
                                })
                                this.removePopup();
                            }
                        })
                }
            } else {
                this.setState({
                    popupState: true,
                    loaderState: false,
                    popupStatus: 'You can not set this status from here',
                })
                this.removePopup();
            }

            if (statusValue == STRING.CHEQUE_BOUNCE) {
                if (userDetails.loanPaybackDate.split(' ')[0] <= moment(new Date()).format('DD-MM-YYYY')) {
                    this.setState({ loaderState: true })

                    saveFollowUpStatus(userDetails.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Follow-up status save Successfully',
                                    loaderState: false
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                this.getLoanLeads()
                            } else {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                } else {
                    this.setState({
                        popupState: true,
                        loaderState: false,
                        popupStatus: 'Please wait for payback date',
                    })
                    this.removePopup();
                }
            }
            if (statusValue != STRING.APPROVED_STATUS && statusValue != STRING.PAID && statusValue != STRING.DISBURSED && statusValue != STRING.RECEIVED && statusValue != STRING.CANCEL_BY_USER && statusValue != STRING.REJECT_STATUS && statusValue != STRING.CHEQUE_BOUNCE && statusValue != STRING.PERMANENT_REJECT && statusValue !== '113') {
                this.setState({ loaderState: true })

                if (userDetails.typeOfProduct == 'Loan') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatus(userDetails.loanId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Follow-up status save Successfully',
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                this.getLoanLeads()
                            } else {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                } else if (userDetails.typeOfProduct == 'Gift Card') {
                    this.setState({ loaderState: true })

                    saveFollowUpStatusForGiftCard(userDetails.giftCardId, statusValue, admin,
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    popupState: true,
                                    loaderState: false,
                                    popupStatus: 'Follow-up status save Successfully',
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                this.getLoanLeads()
                            } else {
                                this.setState({
                                    loaderState: false,
                                    popupState: true,
                                    popupStatus: 'Please try again later!',
                                })
                                this.removePopup();
                            }
                        })
                }
            } else {
                this.setState({
                    popupState: true,
                    loaderState: false,
                    popupStatus: 'You can not set this status from here',
                })
                this.removePopup();
            }

        }
    }


}
const mapStateToProps = ({ CRMMenu, allStatus }) => {
    return {
        visiblePanel: CRMMenu.visibleUserSideNav,
        selectedNavUserOptions: CRMMenu.UserOptions.selectedNavProp,
        allStatus: allStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        onChangeUserNav,
        onUserContentChange,
        resetUserState,
        getLoanApprovalLead
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMUserContainer);