import React from 'react'
import CibilSection from './scoringview'
import DBRsection from '../scoring/dbrview'
import { getallDBRdataApi } from '../../AdminActionCreator.component'
import Nodata from '../../../../../images/nodata.png'
import ObligationCalculator from './ObligationCalculator'
import ApproveAmount from './ApproveAmountData'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from "@emotion/styled";
import InfoIcon from '@mui/icons-material/Info';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '2rem', // Adjust the font size as needed
    },
}));
class ScoringSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flagData: ''
        }
    }
    getData() {

    }
    componentDidMount() {
        this.getFlagData()
    }
    getFlagData() {
        const { allDetail } = this.props

        getallDBRdataApi(allDetail.userId, callback => {
            console.log("callbackcallback", callback)
            if (callback !== '' && callback !== null) {
                this.setState({ flagData: callback })

            }
        })


    }

    render() {
        const { allDetail, getCreditPopupState, creditScoringPopup, admin, setcategoriesData, handleShowObligationPopup,obligationAPiAgain, setObligationAPiAgain} = this.props
        return (
            <>
                {/* {this.state.flagData !== '' && this.state.flagData !== null && this.state.flagData !== undefined ? */}
                <div className=" ">
                    <div className='credit-body' >
                        <div className='' style={{ padding: '0px 10px' }}>
                            <div className='internal-score-1'>
                                <div style={{ display: 'flex', width: '100%', padding: '10px', background: '#D7FBEA', marginLeft: 'auto', marginRight: 'auto', borderRadius: '6px', borderBottom: '1px solid #00C851' }}>
                                    <div className='' style={{ width: '35%' }}>Our Decision
                                        <br /><span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>
                                            <div style={{ fontSize: '18px', color: (this.state.flagData.decision == 'For Quality Check' || this.state.flagData.decision == 'For Quality Check') ? '#9d8420' : this.state.flagData.decision == 'Rejected' ? '#fee5e3' : this.state.flagData.decision == 'Approved' ? '#4CC78C' : "", }}><b>{this.state.flagData.decision}</b></div>
                                        </span>
                                    </div>
                                    {this.state.flagData.decision === 'Approved' && this.state.flagData.approvedAmount
                                        ?
                                        <div className='' style={{ textAlign: 'end', width: '65%', fontSize: '18px', textAlign: 'end' }}>
                                            {this.state.flagData.approvedAmount}
                                            <br />
                                            {this.state.flagData.message ? <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px' }}>{this.state.flagData.message}</span> : ""}
                                        </div>
                                        : ""}
                                    {
                                        this.state.flagData.message === "Please Submit Cibil Scoring and Score Card For Decision" ?
                                        <div style={{ textAlign: 'end', width: '65%' }}>
                                            <CustomTooltip title={this.state.flagData.message || 'NA'} >
                                                <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                            </CustomTooltip>
                                        </div>
                                            : ''}
                                </div>
                                <div className='' style={{ background: '#fff', display: 'flex', width: '100%', marginTop: '10px' }}>
                                    <div className='' style={{ width: '50%', border: '1px solid #DBE9FF', borderBottom: '1px solid #DBE9FF', padding: '15px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                                        <div >
                                            <div>Internal Score</div>
                                            <div style={{ fontSize: '18px' }}><b>{this.state.flagData.scoring}</b></div>
                                            <div>
                                                {/* <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - 20/09/2023</span> */}
                                            </div>

                                        </div>


                                    </div>
                                    <div className='' style={{ width: '50%', border: '1px solid #DBE9FF', borderLeft: 'none', padding: '15px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>

                                        <div>
                                            <div >DBR %</div>
                                            <div style={{ fontSize: '18px' }}><b>{this.state.flagData.dbrpercentage}%</b></div>
                                            <div>
                                                {/* <span style={{ color: 'rgba(0, 0, 0, 0.45', fontSize: '11px', }}>Verified on - 20/09/2023</span> */}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <ApproveAmount allDetail={allDetail} />
                    {/* </div>
                        </div> */}

                    {/* <div className='credit-second-box-small' > */}

                    <CibilSection

                        getData={this.getData.bind(this)}
                        allDetail={allDetail}
                        getCreditPopupState={getCreditPopupState}
                        creditScoringPopup={creditScoringPopup}
                        admin={admin}
                        flagData={this.state.flagData}
                    />


                    <DBRsection setcategoriesData={setcategoriesData} allDetail={allDetail} getFlagData={this.getFlagData.bind(this)} />
                    <ObligationCalculator handleShowObligationPopup={handleShowObligationPopup} allDetail={allDetail} obligationAPiAgain={obligationAPiAgain} setObligationAPiAgain={setObligationAPiAgain}/>

                </div>
                {/* : <div className='col-sm-12 col-xs-12 text-center'><img src={Nodata} style={{ width: '80%', marginTop: '40px' }} /></div>
                }         */}
            </>
        )
    }
}
export default ScoringSection