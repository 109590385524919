import React, { useEffect, useState } from "react";
import "./../compo.scss";
import List from "../../maketingimages/list.png";
import communication from "../../maketingimages/communication.png";
import Filter from "../../maketingimages/filter.png";
import { Pagination } from "@mui/material";
import SentToPopup from "./modals/sentToPopup";
import CommunicationModal from "./modals/communicationModal";
import { getAutomationLogsCountNewApi, getAutomationLogsNewApi } from "../actioncreator";
import { formatDate, monthStartDate, reverseFormat, todayDate } from "../utils/utils";

const CommunicationLogs = () => {
  const [payload, setPayload] = useState({
    fromDate: monthStartDate(),
    toDate: todayDate(),
  });
  const [counts, setCounts] = useState(0);

  const [pageNo, setPage] = useState(1);
  const [noOfEntry, setnoOfEntry] = useState(10);
  const [sentTopopupVisible, setsentTopopupVisible] = useState(false);
  const [communicationPopupVisible, setCommunicationPopupVisible] = useState(false);
  const [objectData, setObjectData] = useState('');

  const openPopup = (whichPopup) => {
    if (whichPopup === "sentTo") {
      setsentTopopupVisible(true);
    } else {
      setCommunicationPopupVisible(true);
    }
  };

  const closePopup = () => {
    setsentTopopupVisible(false);
    setCommunicationPopupVisible(false);
  };
  const getData = () => {
    getAutomationLogsNewApi(payload, pageNo, noOfEntry, callback => {
      setObjectData(callback)
      getCount()
    })
  }
  const handleChange = (e) => {
    console.log(e.target.type);
    console.log(formatDate(e.target.value));
    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });

  };
  useEffect(() => {
    getData()
  }, [])
  const getCount = () => {
    getAutomationLogsCountNewApi(payload, pageNo, noOfEntry, callback => {
      setCounts(callback.count)
    })
  }
  const changeHandler = (e, value) => {
    console.log(value)
    getAutomationLogsNewApi(payload, value, noOfEntry, callback => {
      setObjectData(callback)
      getAutomationLogsCountNewApi(payload, value, noOfEntry, callback => {

        setCounts(callback.count)
      })
    })
  }

  return (
    <>
      <div className="marketing-main-css" style={{ marginTop: "2rem" }}>
        <div className="row">
          {/* <div className="col-sm-2 col-xs-12">
            <img src={Filter} width={20} height={20} alt="" />
            <span className="ms-3 filterHeaders">Filter</span>
            <select
              name=""
              id=""
              // value={payload.filter}
              className="form-select"
              style={{ width: '100%', padding: '7px' }}
            >
              <option value="daily">Daily</option>
            </select>
          </div> */}
          {/* <div style={{ position: 'absolute', marginLeft: '16.5%', marginTop: '30px' }}>::</div> */}
          <div className="col-sm-2 col-xs-12">
            From
            <input
              type="date"
              id="startDate"
              placeholder="DD/MM/YY"
              name="fromDate"
              value={reverseFormat(payload.fromDate)}
              onChange={handleChange}
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />

          </div>
          {/* <div style={{ position: 'absolute', marginLeft: '33%', marginTop: '30px' }}>-</div> */}

          <div className="col-sm-2 col-xs-12">
            <div className="filterHeaders" style={{ marginTop: '4px' }}>to</div>
            <input
              type="date"
              id="endDate"
              name="toDate"
              max={new Date()}
              value={reverseFormat(payload.toDate)}
              onChange={handleChange}
              placeholder="DD/MM/YY"
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <button
              className="btn btn-primary mt-4 "
              style={{
                color: "#006c67",
                borderColor: "#006c67",
                backgroundColor: "white",
                marginTop: '27px',
                padding: '5px',
                borderRadius: '5px'
              }}
              onClick={() => getData()}
            >
              Apply Filter
            </button>
          </div>

        </div>
        <hr />

        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-inline-flex" style={{ display: 'inline-flex' }}>
            <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />{" "}&nbsp;&nbsp;
            <h4 className="ms-3 leadBreakDown_Header">Communication Logs</h4>
          </div>
        </div>
        <div className='credit-table'>
          <table>
            <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000' }}>
              <tr className="thead-class">
                <th className="">Date</th>
                <th className="">Communicated Via</th>
                <th className="">Sub Categories</th>
                <th className="">Sent To</th>
                <th className="">Communication Mode</th>
                <th className="">Sent By</th>
              </tr>
            </thead>
            <tbody>
              {objectData && objectData.map((data, i) => {
                return (
                  <tr style={{ borderBottom: "none" }}>
                    <td scope="col">
                      <span id="comment">
                        <b>{data.date}</b>
                      </span>
                    </td>
                    <td scope="col">
                      <span id="comment">
                        <b>{data.communicationThrough}</b>
                      </span>
                    </td>
                    <td scope="col">
                      <span id="comment">
                        <b>{data.status} </b>
                      </span>
                    </td>
                    <td
                      scope="col"
                      onClick={() => {
                        openPopup("sentTo");
                      }}
                      className="hoverCusrsor"
                    >
                      <span id="comment" style={{ color: "#267DFF" }}>
                        <b>{''}</b>
                      </span>
                    </td>
                    <td
                      scope="col"
                      className="hoverCusrsor"
                      onClick={() => {
                        openPopup("communication");
                      }}
                    >
                      <span id="comment" style={{ color: "#267DFF" }}>
                        <b>{data.communicationType}</b>
                      </span>
                    </td>
                    <td scope="col">
                      <span id="comment">
                        <b>{data.empId}</b>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {counts > 1
          ? <div className="row">

            <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'end' }} >
              <b > Page :</b>
            </div>
            <div className="col-sm-1">
              <select className="form-control">
                <option>1</option>
              </select>
            </div>
            <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'start' }} >
              <b > of &nbsp; 0</b>
            </div>
            <div className="col-sm-5"></div>

            <div className="col-sm-4"> <div style={{ float: 'right', marginTop: '10px', marginBottom: '10px', marginRight: '40px' }}>
              <Pagination
                count={Math.ceil(counts / noOfEntry)}
                // count={10}
                color="primary"
                shape="circle"
                onChange={changeHandler}
              />
            </div></div>
          </div>
          : ""}

      </div>


      {sentTopopupVisible ? (
        <SentToPopup onClose={closePopup} />
      ) : communicationPopupVisible ? (
        <CommunicationModal onClose={closePopup} />
      ) : (
        ""
      )}
    </>
  );
}

export default CommunicationLogs;
