import { APIS } from "../../../../utils/api-factory"

export const overNbfcCountsApi = (
  callBack
) => {
  fetch(APIS.ALL_NBFC_COUNTS_API)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const onboardingStatusApi = (
  callBack
) => {
  fetch(APIS.GET_ONBAORDING_GRAPH_DATA)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getLoanStatusByNbfcIdApi = (nbfcId,
  callBack
) => {
  fetch(APIS.GET_LOAN_STATUS_DATA + nbfcId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getRepaymentStatusApi = (nbfcId,
  callBack
) => {
  fetch(APIS.GET_REPAYMENT_STATUS + nbfcId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getConversionRatioDataApi = (
  callBack
) => {
  fetch(APIS.GET_CONVERSION_RATIO_DATA)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getNbfcPerformanceDataApi = (
  callBack
) => {
  fetch(APIS.GET_NBFC_PERFORMANCE_DATA)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const nbfcStatusApi = (
  callBack
) => {
  fetch(APIS.NBFC_STATS_API)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getNbfcDahsboardDataApi = (
  startDate,endDate,nbfcId,
  callBack
) => {
  fetch(APIS.INDIVIDUAL_DASHBOARD_API + startDate + '&eDate=' + endDate + '&nbfcId=' + nbfcId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getCustomerCriteriaBynbfcId = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_CUSTOMER_CRITERIA_BY_NBFCID + nbfcId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const getProcessingFeeDetailsBynbfcId = (
  nbfcId,pageNo,size,
  callBack
) => {
  fetch(APIS.GET_PROCESSING_FEE_DETAILS_BY_NBFCID + nbfcId + "&pageNo=" + pageNo + "&noOfEntry=" + size)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const getNbfcDocsUrlByNbfcId = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_NBFC_DOCS_URL_BY_NBFCID + nbfcId )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const getProcessingFeeCount = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_PROCESSING_FEE_COUNT + nbfcId )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const getLoanUtilityStatsBynbfcId = (
  nbfcId,pageNo,size,
  callBack
) => {
  fetch(APIS.GET_LOAN_UTILITY_STATS_BY_NBFCID + nbfcId + "&pageNo=" + pageNo + "&noOfEntry=" + size)
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}

export const getLoanUtilityCountBynbfcId = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_PRODUCT_NBFC_COUNT + nbfcId )
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}


export const getEnachConfigBynbfcId = (
  nbfcId,pageNo,size,
  callBack
) => {
  fetch(APIS.GET_ENACH_CONFIG_BY_NBFCID + nbfcId + "&pageNo=" + pageNo + "&noOfEntry=" + size)
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}

export const getEnachConfifCountBynbfcId = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_ENACH_CONFIG_COUNT + nbfcId )
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}

export const getEsignConfigBynbfcId = (
  nbfcId,pageNo,size,
  callBack
) => {
  fetch(APIS.GET_ESIGN_CONFIG_BY_NBFCID + nbfcId + "&pageNo=" + pageNo + "&noOfEntry=" + size)
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}

export const getEsignConfigCountBynbfcId = (
  nbfcId,
  callBack
) => {
  fetch(APIS.GET_ESIGN_CONFIG_COUNT + nbfcId )
  .then(res => res.json())
  .then(json => {
    callBack(json)
  })
}


export const saveNbfcApi = (
  data,
  callBack
) => {
  
  fetch(APIS.SAVE_NBFC, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}


export const SaveUpdateProcessingFee = (data, callBack) => {
  fetch(APIS.SAVE_UPDATE_PROCESSING_FEE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}


export const SaveUpdateNbfcCustomerCriteria = (data, callBack) => {
  fetch(APIS.SAVE_UPDATE_NBFC_CUSTOMER_CRITERIA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}


export const UploadnbfcDocumentByNbfcName = (nbfcName, documentType,nbfcId, data, callBack) => {
  fetch(APIS.UPLOAD_NBFC_DOCUMENTS_BY_NBFC_NAME + nbfcName + "&documentType=" + documentType + "&nbfcId=" + nbfcId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getNbfcRecentActivity = (callBack) => {
  fetch(APIS.GET_NBFC_RECENT_ACTIVITY )
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}