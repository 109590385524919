import React from 'react'
import { TYPE } from '../../../../utils/Constant'
import ReactTable from "react-table";

const CRMRoleAssign = ({ nameFilter,
    selectedFilter, tablePagination, tableMinPagination, tableMaxPagination, RoleAssignHandler, RoleChange, roleDetail, roleDataState,
    userRole, RoleEditHandler, RoleSelectAll, adminRole, RoleDeleteHandler, admin,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, assignApplicationHandler,
    leaveCheckbox, allNbfcList }) => (
    <div className="container-fluid Phocket-crm-role-assign">
        <div className="row">
            <h3 className="text-center blue-text">Role Assign</h3>
        </div>
        <div className="row form-group">
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Employee Email ID</label>
                <input type="email" className="form-control" value={roleDetail.emailIdState} onChange={(e) => RoleChange(e, TYPE.EMP_ID)} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Enter Password</label>
                <input type="text" className="form-control" value={roleDetail.passwordState} onChange={(e) => RoleChange(e, TYPE.EMP_PASS)} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Employee Name</label>
                <input type="text" className="form-control" value={roleDetail.nameState} onChange={(e) => RoleChange(e, TYPE.EMP_NAME)} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Employee Role</label>
                <select className="form-control" value={roleDetail.roleState} onChange={(e) => RoleChange(e, TYPE.EMP_ROLE)}>
                    <optgroup label="" hidden>
                        <option value="">Select Role</option>
                    </optgroup>
                    <optgroup label="Roles">
                        {adminRole != null && adminRole.length > 0 ? adminRole.map((Role, i) => {
                            if (Role != "SuperUser") {
                                return (
                                    <option key={i} value={Role}>{Role}</option>
                                )
                            }
                        }) : ""}
                    </optgroup>
                </select>
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Select Department</label>
                <select className="form-control" value={roleDetail.departmentState} onChange={(e) => RoleChange(e, TYPE.EMP_DEPARTMENT)}>
                    <option value="">Select Department</option>
                    <option value="Technical">Technical</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Finance / Credit">Finance / Credit</option>
                    <option value="Accounts / HRM">Accounts / HRM</option>
                    <option value="Recovery Team">Recovery Team</option>
                    <option value="Customer Service">Customer Service</option>
                    <option value="Legal">Legal</option>

                </select>
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px">Select NBFC</label>
                <select className="form-control" value={roleDetail.nbfcState} onChange={(e) => RoleChange(e, TYPE.NBFC_NAME)}>
                    <optgroup label="NBFC">
                        <option value="">Select NBFC</option>
                        {allNbfcList != null && allNbfcList.length > 0 ?
                            allNbfcList.map((nbfc, i) => {
                                return (
                                    <option key={i} value={nbfc.name}>{nbfc.name}</option>
                                )
                            })
                            : null}
                    </optgroup>
                </select>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-lg-12">
                <input className="phocket-checkbox" type="checkbox" id="roleSelectAll" onChange={(e) => RoleSelectAll(e, userRole)} />
                <label htmlFor="roleSelectAll"><span></span></label>&nbsp;&nbsp;
                <span><strong>Select Permissions</strong></span>
                <div className="col-md-12 roles">
                    {userRole != '' && userRole != null && userRole.length > 0 ?
                        userRole.map((role, i) => {
                            return (
                                <div className="col-sm-3 col-xs-12" key={i}>
                                    <input className="phocket-checkbox" type="checkbox" id={"role" + role.roleId} value={role.roleId} onChange={(e) => RoleChange(e, TYPE.EMP_PERMISSION)} />
                                    <label htmlFor={"role" + role.roleId}><span></span></label>&nbsp;&nbsp;
                                    <span>{role.roleName}</span>
                                </div>
                            )
                        }) : ""}
                </div>
            </div>
        </div>
        <div className="row text-center">
            <div className="col-xs-12">
                <button className="profile-save-btn" id="submitDetail" onClick={e => RoleAssignHandler()}>
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                </button>
            </div>
        </div>
        <div className="row">

            <div className="col-xs-12">
                <div style={{ width: '300px', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', marginBottom: '20px' }}>
                    <input placeholder='Search by Name' onChange={(e) => nameFilter(e)} className='form-control' value={selectedFilter} />
                </div>
                {roleDataState ?
                    <React.Fragment>
                        <table className='phocket-table -striped -highlight phocketAdmin_table'>

                            <thead>
                                <tr>
                                    <td>Edit</td>
                                    <td>Delete</td>
                                    <td>Assign</td>
                                    <td>Emp Name</td>
                                    <td>Email Id</td>
                                    <td>Role</td>
                                    <td>Department</td>
                                    <td>Permissions</td>

                                </tr>
                            </thead>

                            <tbody>
                                {admin.emailId !== 'SuperUser' ? roleDataState && roleDataState.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    if (data.emailId !== 'mohit@micromoney.in') {
                                        return (
                                            <tr>
                                                <td> <button onClick={e => RoleEditHandler(data)}><span className="fa fa-edit" /></button></td>
                                                <td><button onClick={e => RoleDeleteHandler(data)}><span className="fa fa-trash" /></button></td>
                                                <td> <input type="checkbox" className="" checked={data.assign} onChange={e => assignApplicationHandler(e, data)} /></td>
                                                <td>{data.name}</td>
                                                <td>{data.emailId}</td>
                                                <td>{data.role}</td>
                                                <td>{data.department}</td>
                                                <td><div className='text-center' style={{ width: '300px', overflow: 'auto', whiteSpace: 'normal', marginLeft: 'auto', marginRight: 'auto' }}>{JSON.stringify(data.roleNameList).replace('[', "").replace(']', "").replaceAll('"', "").replaceAll(',', " , \n")}</div></td>

                                            </tr>
                                        )
                                    }
                                }) : ""}
                                {admin.emailId === 'SuperUser' ? roleDataState && roleDataState.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        <tr>
                                            <td> <button onClick={e => RoleEditHandler(data)}><span className="fa fa-edit" /></button></td>
                                            <td><button onClick={e => RoleDeleteHandler(data)}><span className="fa fa-trash" /></button></td>
                                            <td> <input type="checkbox" className="" checked={data.assign} onChange={e => assignApplicationHandler(e, data)} /></td>
                                            <td>{data.name}</td>
                                            <td>{data.emailId}</td>
                                            <td>{data.role}</td>
                                            <td>{data.department}</td>
                                            <td><div className='text-center' style={{ width: '300px', overflow: 'auto', whiteSpace: 'normal', marginLeft: 'auto', marginRight: 'auto' }}>{JSON.stringify(data.roleNameList).replace('[', "").replace(']', "").replaceAll('"', "").replaceAll(',', " , \n")}</div></td>

                                        </tr>
                                    )

                                }) : ""}
                            </tbody>
                        </table>
                        <div className="margin-bottom-6perc">
                            <div className=" col-xs-12  tbl-count text-center " ><button type="button" className="pagination-btn " onClick={e => tablePagination(roleDataState, 'back')}>Previous</button>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + roleDataState.length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => tablePagination(roleDataState, 'forward')}>Next</button></div>
                        </div>
                    </React.Fragment>
                    : ""}

            </div>
        </div>

    </div>
)

export default CRMRoleAssign;