import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

const DocumentMail = ({ allDetail, adharTextState, panTextState, bankTextState,
    salarySlipTextState, halfSalaryState, officeIdTextState,CurrentAddressProofState }) => (
    <div>
        <div>Dear {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""}, </div>
        <br />
        <div>Thank you for registering with Credfin! </div>
        <div>We're so happy to have you as a customer. </div>
        <div>We have noticed that your loan application process isn't complete yet and is missing a few documents. </div>
        <br />
        <div>Below are the essential documents required: </div>
        <br />
        {/* {officeIdTextState == true ?
            <div>- Official Id </div>
            : ""} */}
        {adharTextState == true ?
            <div>- Aadhar Card </div>
            : ""}
        {panTextState == true ?
            <div>- PAN Card </div>
            : ""}
        {bankTextState == true ?
            <div>- Last 2 months' bank statement (till date) </div>
            : ""}
        {salarySlipTextState == true ?
            <div>- Last Month Salary Slip </div>
            : ""}
                 {CurrentAddressProofState == true ?
            <div>- Current Address Proof (Utility bill/Rent Agreement/Mobile bill/LPG bill etc) </div>
            : ""}
            <br />
        <b>Note:</b>
        <br />
        <div>You can either share PDF or use Finbit Net Banking auto fetch in our App document upload section for Bank Statement.</div>
        <br />
        <div>If your mobile number is not linked to your Aadhar card, please upload your Aadhar card in PDF/JPG/JPEG format.</div>
        <br />
        <div>If your mobile number is linked to your Aadhar Card and your mobile number is active, please upload details via Digilocker/ XML process.</div>
        <br />
        <div>Documents can be shared by any of the following modes:
                <br /><br />
                1. Uploading them in document section of Credfin Mobile App.
                <br />
                2. Emailing them to finance@Credfin.money
                <br />
                3. Whatsapp to 9311226661
                <br />
                4. <a href={MAIL_STRING.WEB_URL + allDetail.userId} target="_blank">Click here to upload</a>. </div>
        <br />
        {halfSalaryState == true ?
            <div>
                <div>As per our policy the loan amount we provide cannot exceed more than 100% of the applicant's take-home salary. </div>
                <br />
            </div>
            : ""}
        <div>Feel free to contact us, if you have any queries. </div>
        <br />
        <div>Best Regards, </div>
        <div>Team Credfin </div>
        <div>{MAIL_STRING.MOBILE_NO} </div>
    </div>
)

export default DocumentMail