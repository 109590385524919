import React from 'react'
import { PLACEHOLDER, STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';
import TransactionPopup from '../../Popup/transactionpopup';
import NoData from '../../../../images/nodata.png'
const TransactionDetails = ({ addPoup, viewPage, pageHandler, billDeskData, editTransHandler, saveTransHandler, trnsDateState, details,
    openPopup, DeleteTransaction, transDetail, openAddPopup, close, PaymentLinkData, handleExpireBtnchange, convertDate, updatedComment, handleUpdatedcomment, handleSavecomment }) => (
    <div className=" details-new" style={{ border: '1.5px solid #DBE9FF', borderRadius: '5px', padding: '10px', marginTop: '20px', minHeight: '400px' }}>

        {/* <div className='row '>
           <button  onClick={()=>pageHandler('txn')} className={viewPage==='txn'?'btn btn-primary':'btn btn-default'}>Transaction</button>&nbsp;&nbsp;
           <button onClick={()=>pageHandler('billdesk')} className={viewPage==='billdesk'?'btn btn-primary':'btn btn-default'}>Billdesk Transaction</button>

        </div> */}
        {addPoup ?
            <TransactionPopup close={close} editTransHandler={editTransHandler} saveTransHandler={saveTransHandler} trnsDateState={trnsDateState} transDetail={transDetail} />

            : ""}
        <div className='row'>
            <div className='col-sm-6 col-xs-12'>

                <div className="btn-group btn-grp-own" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px' }}>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'txn' ? '#1D3557' : '#F1F7FF', color: viewPage === 'txn' ? '#fff' : '#000' }} onClick={() => pageHandler('txn')}> Razorpay & Others </button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'billdesk' ? '#1D3557' : '#F1F7FF', color: viewPage === 'billdesk' ? '#fff' : '#000' }} onClick={() => pageHandler('billdesk')} > Billdesk </button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'PaymentLink' ? '#1D3557' : '#F1F7FF', color: viewPage === 'PaymentLink' ? '#fff' : '#000' }} onClick={() => pageHandler('PaymentLink')} > Payment Link </button>

                </div>
            </div>
            <div className='col-sm-6 col-xs-12 text-right'>
                <button className='btn btn-primary' style={{ marginTop: '20px', color: '#fff' }} onClick={() => openAddPopup()}><i className='fa fa-plus-circle'></i>&nbsp;&nbsp;Add Transaction</button>
            </div>
        </div>
        {viewPage === 'txn' ?
            details != null && details != '' && details.length > 0 ?
                <div className=" form-group overflow-auto phocket-table-new " style={{ marginBottom: '20px' }}>
                    {/* <h3 className="text-center">Transaction History</h3> */}
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATE</th>
                                <th>MODE</th>
                                <th>AMOUNT</th>
                                <th>COMMENT</th>
                                <th>UPDATE COMMENT</th>
                                <th>UPDATE</th>
                                <th>DELETE</th>
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(details) && details != null && details != '' && details.length > 0 ? details.map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.transactionId}</td>
                                        <td>{detail.prepayDate}</td>
                                        <td>{detail.paymentMode}</td>
                                        <td>{detail.prepayAmount}</td>
                                        <td>{detail.comment}</td>
                                        <td>
                                            <div>
                                                <input style={{ border: '1px solid #000', padding: '8px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }} value={updatedComment[detail.transactionId] ?? ""} onChange={(e) => handleUpdatedcomment(e.target.value, detail.transactionId)} />
                                                <button className="btn-primary" style={{ borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }} onClick={() => handleSavecomment(detail.transactionId)}>Save</button>
                                            </div>
                                        </td>
                                        <td><span className="fa fa-edit" style={{ cursor: 'pointer' }} onClick={(e) => openPopup(e, detail.transactionId)} /></td>
                                        <td><span className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={(e) => DeleteTransaction(detail)} /></td>
                                    </tr>
                                )
                            })
                                : null}
                        </tbody>
                    </table>
                </div>
                : <div className='text-center'><img style={{ width: '30%' }} src={NoData} /></div> : ""}
        {viewPage === 'billdesk' ?
            billDeskData != null && billDeskData != '' && billDeskData.length > 0 ?
                <div className=" form-group overflow-auto phocket-table-new" style={{ marginBottom: '20px' }}>
                    {/* <h3 className="text-center">Billdesk Transaction History</h3> */}
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATE</th>
                                <th>AMOUNT</th>
                                <th>Transaction Error Description</th>
                            </tr>
                        </thead>

                        <tbody>
                            {billDeskData != null && billDeskData != '' && billDeskData.length > 0 ? billDeskData.map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.bdOrderId}</td>
                                        <td>{detail.addedOn}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.transactionErrDesc}</td>

                                    </tr>
                                )
                            })
                                : null}
                        </tbody>
                    </table>
                </div>
                : <div className='text-center'><img style={{ width: '30%' }} src={NoData} /></div> : ""}
        {viewPage === 'PaymentLink' ?
            PaymentLinkData !== null && PaymentLinkData !== '' && PaymentLinkData.length > 0 ?
                <div className=" form-group overflow-auto phocket-table-new" style={{ marginBottom: '20px' }}>
                    {/* <h3 className="text-center">Billdesk Transaction History</h3> */}
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Payment Id</th>
                                <th style={{ textAlign: 'center' }}>Date</th>
                                <th style={{ textAlign: 'center' }}>Payment Type</th>
                                <th style={{ textAlign: 'center' }}>Amount</th>
                                <th style={{ textAlign: 'center' }}>Payment Link</th>
                                <th style={{ textAlign: 'center' }}>Created By</th>
                                <th style={{ textAlign: 'center' }}>Deactivate Payment Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PaymentLinkData !== null && PaymentLinkData !== '' && PaymentLinkData.length > 0 ? PaymentLinkData.map((detail, i) => {
                                return (
                                    <tr key={i} style={{ textAlign: 'center' }}>
                                        <td>{detail?.paymentId}</td>
                                        <td>{detail?.createdAt != null && detail?.createdAt != undefined && detail?.createdAt != '' ? convertDate(detail?.createdAt) : ''}</td>
                                        <td>{detail?.paymentType === 'fullpayment' ? 'Full Payment' : 'Part Payment'}</td>
                                        <td>{detail?.amount}</td>
                                        <td>{detail?.shortUrl}</td>
                                        <td>{detail?.createdBy}</td>
                                        {
                                            detail?.active ?

                                                <td>

                                                    <div style={{ textAlign: 'center' }}>
                                                        <button style={{ width: '50%', padding: '8px 5px', background: '#267DFF', border: 'none', borderRadius: '6px', color: '#fff' }} onClick={() => handleExpireBtnchange()}>Expire</button>
                                                    </div>
                                                </td>
                                                :
                                                <td>Expired</td>}

                                    </tr>
                                )
                            })
                                : null}
                        </tbody>
                    </table>
                </div>
                : <div className='text-center'><img style={{ width: '30%' }} src={NoData} /></div> : ""}
    </div>
)

export default TransactionDetails;