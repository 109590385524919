import React, { useState, useEffect } from 'react';
import reseticonimg from './images/reseticon.png';
import { getUnidetifiedPaymentsApi } from './actionCreator';
import Loader from '../../../presentationals/Loader/DotLoader.component';
import Nodataimage from '../../../../images/nodata.png'
import moment from 'moment'
import Pagination from '@mui/material/Pagination';

const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}
export default function MandateReport() {
    const itemsPerPage =20;
    const [loaderState, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromdate, setfromdate] = useState(InitalDate('fromDate'));
    const [todate, settodate] = useState(InitalDate('toDate'));
    const [tableData, setTableData] = useState([]);
    const [filteredData, setfilteredData] = useState([]);

    const getData = (fromDate, toDate) => {
        const sDate = moment(fromDate).format('DD-MM-YYYY');
        const eDate = moment(toDate).format('DD-MM-YYYY');
        setCurrentPage(1)
        setLoader(true);
        getUnidetifiedPaymentsApi(sDate, eDate, (response) => {
            console.log("respose",response)
            if(response && response?.message == "success" && response?.data?.length > 0){
                setTableData(response?.data); 
                filterFunction(response?.data,1)
            }else{
                setfilteredData([])
                setTableData([]); 
            }
            setLoader(false);
        });
    };

    const filterFunction = (data,currentPageNo)=>{
        const indexOfLastItem = currentPageNo * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = data && data?.length > 0 ? data?.slice(indexOfFirstItem, indexOfLastItem) : [];
        setfilteredData(currentItems)
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;

        if (name === "fromDate") {
            setfromdate(value);
            if (new Date(value) > new Date(todate)) {
                settodate(value); 
            }
        } else {
            settodate(value);
        }
    };

    const handlefilter = (e) => {
        setfiltertype(e.target.value);
        e.target.value === 'duration'
            ? setsubfiltertype('last7days')
            : e.target.value === 'mandatestatus'
            ? setsubfiltertype('all')
            : setsubfiltertype('');
    };

    const handlefiltersecondary = (e) => {
        setsubfiltertype(e.target.value);
    };

    useEffect(() => {
        
            getData(fromdate, todate);
        
    }, []);
    const buttonHandler=(type)=>{
        if(type == "reset"){
            getData(InitalDate('fromDate'), InitalDate('toDate'))
            
        }else{
            getData(fromdate, todate)
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        filterFunction(tableData,value)
    };

    return (
        <>
            {loaderState && <Loader />}
            <div className="MandateReport AccountReconciliaiton-CSS container-fluid full-height">
                <div className="row" style={{ marginTop: '20px', paddingLeft: '15px' }}>
                    <h3 className="mb-0"><b>Unidentified Payments</b></h3>
                </div>

                <div className="row" style={{ marginTop: '20px' }}>
                   
                            <div className="col-sm-2 col-xs-12" >
                                <div className="dates">
                                    <label>From Date</label>
                                    <input
                                    id="customDateInput"
                                        type="date"
                                        name="fromDate"
                                        value={fromdate}
                                        onChange={handleDateChange}
                                        className="form-control"
                                    />
                                </div>
                                </div>
                                <div className="col-sm-2 col-xs-12" >

                                <div className="dates">
                                    <label>To Date</label>
                                    <input
                                    id="customDateInput"
                                        type="date"
                                        name="toDate"
                                        value={todate}
                                        min={fromdate}
                                        onChange={handleDateChange}
                                        className="form-control"
                                    />
                                </div>
               
                    </div>
                    <div className="col-sm-1">
                    <button className="btn btn-primary w-100" style={{marginTop:'21px',padding:'11px',background:'#fff',color:'#006c67'}} onClick={() => {
                          buttonHandler("apply")
                        }}>
                           Apply Filter
                        </button>
                    </div>
                    <div className="col-sm-2">
                        <button className="reset-btn"  onClick={() => {
                          buttonHandler("reset")
                        }}>
                            <img src={reseticonimg} alt="Reset" width="24%" /> Reset Filter
                        </button>
                    </div>
                </div>
{filteredData.length>0?
                <div className="row" style={{ padding: '10px', paddingRight: '15px',marginTop:'20px' }}>
                    <div className="table-responsive phocket-table-new">
                        <table className=""  style={{overflowX:'auto'}}>
                            <thead>
                                <tr>
                                    <th className='text-center'>Application Id</th>
                                    <th className='text-center'>Prepaid Amount</th>
                                    <th className='text-center'>Transaction Id</th>
                                    <th className='text-center'>Transaction Date</th>
                                    <th className='text-center'>Updated By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData && filteredData.length > 0 ? filteredData?.map((data, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{data?.applicationId || ''}</td>
                                        <td className='text-center'>{(Number(data?.prepayAmount))?.toLocaleString('en-IN') || ''}</td>
                                        <td className='text-center'>{data?.transactionId || ''}</td>
                                        <td className='text-center'>{new Date(data.getTransactionDate).toLocaleString() || ''}</td>
                                        <td className='text-center'>{data?.updatedBy || ''}</td>
                                        
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                        <div style={{ float: 'right', marginTop: '15px' }}>
                        <Pagination
                            count={Math.ceil(tableData?.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="success"
                        />
                    </div>
                    </div>
                </div>
                :<div className='text-center'><img src={Nodataimage} style={{width:'30%',marginTop:'4%'}}/></div>}
            </div>
        </>
    );
}
