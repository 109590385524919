import React from "react";
import { ReferenceValidationAdminNew2 } from "../../../../../utils/validation";
import { saveuserRefApi, userReferenceApi } from "../../AdminActionCreator.component";
import PopUp from "../../../../presentationals/Popup/Popup.component";
let datatosend = []

class ReferenceEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referenceDataState: {
                name1: '',
                number1: '',
                relationShip1: '',
                name2: '',
                number2: '',
                relationShip2: '',
                name3: '',
                number3: '',
                relationShip3: '',
                name4: '',
                number4: '',
                relationShip4: '',
                name5: '',
                number5: '',
                relationShip5: ''
            },
            popupState: false,
            popupStatus: '',
            referenceValidationMsg: {}
        }
    }
    referencechange(e, type) {
        let changeData = Object.assign({}, this.state.referenceDataState)
        if (type == 'name1') {
            ///backhere
            changeData.name1 = e.target.value
        }
        if (type == 'number1') {
            if (e.target.value.charAt(0) == "0") {
                changeData.number1 = ''
            } else {
                changeData.number1 = e.target.value
            }
        }
        if (type == 'relationShip1') {
            changeData.relationShip1 = e.target.value
        }
        if (type == 'name2') {
            changeData.name2 = e.target.value
        }
        if (type == 'number2') {
            if (e.target.value.charAt(0) == "0") {
                changeData.number2 = ''
            } else {
                changeData.number2 = e.target.value
            }
        }
        if (type == 'relationShip2') {
            changeData.relationShip2 = e.target.value
        }
        if (type == 'name3') {
            changeData.name3 = e.target.value
        }
        if (type == 'number3') {
            if (e.target.value.charAt(0) == "0") {
                changeData.number3 = ''
            } else {
                changeData.number3 = e.target.value
            }
        }
        if (type == 'relationShip3') {
            changeData.relationShip3 = e.target.value
        }
        if (type == 'name4') {
            changeData.name4 = e.target.value
        }
        if (type == 'number4') {
            if (e.target.value.charAt(0) == "0") {
                changeData.number4 = ''
            } else {
                changeData.number4 = e.target.value
            }
        }
        if (type == 'relationShip4') {
            changeData.relationShip4 = e.target.value
        }
        if (type == 'name5') {
            changeData.name5 = e.target.value
        }
        if (type == 'number5') {
            if (e.target.value.charAt(0) == "0") {
                changeData.number5 = ''
            } else {
                changeData.number5 = e.target.value
            }
        }
        if (type == 'relationShip5') {
            changeData.relationShip5 = e.target.value
        }
        this.setState({ referenceDataState: changeData })

    }
    referenceUpdate(e) {
        const { userdata } = this.props
        let RefValidation = ReferenceValidationAdminNew2(this.state.referenceDataState, userdata)
        console.log(RefValidation,'RefValidation')
        if (RefValidation.status) {
            this.setState({
                referenceValidationMsg: RefValidation
            })
            datatosend = []
            if (this.state.referenceDataState.name2 !== '' && this.state.referenceDataState.number1 !== '' && this.state.referenceDataState.number2 !== '' && this.state.referenceDataState.number2 !== '' && this.state.referenceDataState.number3 !== '' && this.state.referenceDataState.number3 !== '') {
                datatosend.push({ 'name': this.state.referenceDataState.name1, 'number': Number(this.state.referenceDataState.number1), 'relationShip': this.state.referenceDataState.relationShip1 },
                    { 'name': this.state.referenceDataState.name2, 'number': Number(this.state.referenceDataState.number2), 'relationShip': this.state.referenceDataState.relationShip2 },
                    { 'name': this.state.referenceDataState.name3, 'number': Number(this.state.referenceDataState.number3), 'relationShip': this.state.referenceDataState.relationShip3 },  
                   
                )
            }
            if(this.state.referenceDataState.name4 !== '' && this.state.referenceDataState.number4 !== '' && this.state.referenceDataState.relationShip4 !== ''){
                datatosend.push( { 'name': this.state.referenceDataState.name4, 'number': Number(this.state.referenceDataState.number4), 'relationShip': this.state.referenceDataState.relationShip4})
            }
            if(this.state.referenceDataState.name5 !== '' && this.state.referenceDataState.number5 !== '' && this.state.referenceDataState.relationShip5 !== ''){
                datatosend.push({ 'name': this.state.referenceDataState.name5, 'number': Number(this.state.referenceDataState.number5), 'relationShip': this.state.referenceDataState.relationShip5})
            }
            if (datatosend.length > 0) {
                if (datatosend.length >= 3) {
                    // console.log(datatosend)
                    this.setState({ loaderStateforPan: true })
                    saveuserRefApi(datatosend, userdata.userId, callback => {
                        datatosend = []
                        if (callback === 'success') {
                            this.setState({ popupState: true, popupStatus: 'Data Successfully Saved!', successState: true, loaderStateforPan: false }, () => this.removePopup())
                            this.props.getuserReference()
                        } else {
                            this.setState({ popupState: true, popupStatus: 'Please Try Again Later!', successState: false, loaderStateforPan: false }, () => this.removePopup())

                        }
                    })
                } else {
                    // newdata = []
                }
            }
        } else {
            //  this.setState({referenceValidationMsg:RefValidation})
            //  console.log(this.state.referenceValidationMsg)
            this.setState({ popupState: true, popupStatus: RefValidation.msg }, () => this.removePopup())
            datatosend = []
        }

    }

    referenceData() {
        const { userdata } = this.props
        userReferenceApi(userdata.userId, callBack => {
            // console.log(callBack)
            // this.setState({ userReference: callBack })
            let changeData = Object.assign({}, this.state.referenceDataState)
            changeData.name1 = callBack !== '' && callBack[0].name !== undefined && callBack[0].name !== '' ? callBack[0].name : ''
            changeData.number1 = callBack !== '' && callBack[0].number !== undefined && callBack[0].number !== '' ? callBack[0].number : ''
            changeData.relationShip1 = callBack !== '' && callBack[0].relationShip !== undefined && callBack[0].relationShip !== '' ? callBack[0].relationShip : ''
            changeData.name2 = callBack !== '' && callBack[1].name !== undefined && callBack[1].name !== '' ? callBack[1].name : ''
            changeData.number2 = callBack !== '' && callBack[1].number !== undefined && callBack[1].number !== '' ? callBack[1].number : ''
            changeData.relationShip2 = callBack !== '' && callBack[1].relationShip !== undefined && callBack[1].relationShip !== '' ? callBack[1].relationShip : ''
            changeData.name3 = callBack !== '' && callBack[2].name !== undefined && callBack[2].name !== '' ? callBack[2].name : ''
            changeData.number3 = callBack !== '' && callBack[2].number !== undefined && callBack[2].number !== '' ? callBack[2].number : ''
            changeData.relationShip3 = callBack !== '' && callBack[2].relationShip !== undefined && callBack[2].relationShip !== '' ? callBack[2].relationShip : ''
            changeData.name4 = callBack !== '' && callBack[3].name !== undefined && callBack[3].name !== '' ? callBack[3].name : ''
            changeData.number4 = callBack !== '' && callBack[3].number !== undefined && callBack[3].number !== '' ? callBack[3].number : ''
            changeData.relationShip4 = callBack !== '' && callBack[3].relationShip !== undefined && callBack[3].relationShip !== '' ? callBack[3].relationShip : ''
            changeData.name5 = callBack !== '' && callBack[4].name !== undefined && callBack[4].name !== '' ? callBack[4].name : ''
            changeData.number5 = callBack !== '' && callBack[4].number !== undefined && callBack[4].number !== '' ? callBack[4].number : ''
            changeData.relationShip5 = callBack !== '' && callBack[4].relationShip !== undefined && callBack[4].relationShip !== '' ? callBack[4].relationShip : ''
            this.setState({ referenceDataState: changeData })

        })
    }
    componentDidMount() {
        this.referenceData()
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }

    closePopup() {
        this.setState({ popupState: false, popupStatePan: false })
    }
    render() {
        return (
            <>
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    <div className="edit-box ">
                        <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                        <h4><b>Edit Reference Details</b></h4>
                        <React.Fragment>

                            <div className="row">
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Reference 1 Name</label>
                                    <input onChange={(e) => this.referencechange(e, 'name1')} type="text" className="form-control" placeholder='Name' value={this.state.referenceDataState.name1} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Number</label>

                                    <input onChange={(e) => this.referencechange(e, 'number1')} className="form-control" placeholder='Number' type='number' value={this.state.referenceDataState.number1} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Relationship</label>
                                    <select onChange={(e) => this.referencechange(e, 'relationShip1')} className='form-control' value={this.state.referenceDataState.relationShip1}>
                                        <option value=''>Select Value</option>
                                        <option value='Father'>Father</option>
                                        <option value='Friend/Colleague'>Friend/Colleague</option>
                                        <option value='Other Relative'>Other Relative</option>
                                    </select>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Reference 2 Name</label>
                                    <input onChange={(e) => this.referencechange(e, 'name2')} type="text" className="form-control" placeholder='Name' value={this.state.referenceDataState.name2} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Number</label>
                                    <input onChange={(e) => this.referencechange(e, 'number2')} className="form-control" placeholder='Number' type='number' value={this.state.referenceDataState.number2} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Relationship</label>
                                    <select onChange={(e) => this.referencechange(e, 'relationShip2')} className='form-control' value={this.state.referenceDataState.relationShip2}>
                                        <option value=''>Select Value</option>
                                        <option value='Mother'>Mother</option>
                                        <option value='Friend/Colleague'>Friend/Colleague</option>
                                        <option value='Other Relative'>Other Relative</option>
                                    </select>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Reference 3 Name</label>

                                    <input onChange={(e) => this.referencechange(e, 'name3')} type="text" className="form-control" placeholder='Name' value={this.state.referenceDataState.name3} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Number</label>

                                    <input onChange={(e) => this.referencechange(e, 'number3')} className="form-control" placeholder='Number' type='number' value={this.state.referenceDataState.number3} />

                                </div>
                                <div className='col-sm-4 col-xs-12' style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Relationship</label>
                                    <select onChange={(e) => this.referencechange(e, 'relationShip3')} className='form-control' value={this.state.referenceDataState.relationShip3}>
                                        <option value=''>Select Value</option>
                                        <option value='Sibling'>Sibling</option>
                                        {/* <option value='Spouse'>Spouse</option> */}
                                        <option value='Friend/Colleague'>Friend/Colleague</option>
                                        <option value='Other Relative'>Other Relative</option>
                                    </select>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Reference 4 Name</label>

                                    <input onChange={(e) => this.referencechange(e, 'name4')} type="text" className="form-control" placeholder='Name' value={this.state.referenceDataState.name4} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Number</label>

                                    <input onChange={(e) => this.referencechange(e, 'number4')} className="form-control" placeholder='Number' type='number' value={this.state.referenceDataState.number4} />

                                </div>
                                <div className='col-sm-4 col-xs-12' style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Relationship</label>
                                    <select onChange={(e) => this.referencechange(e, 'relationShip4')} className='form-control' value={this.state.referenceDataState.relationShip4}>
                                        <option value=''>Select Value</option>
                                        {/* <option value='Sibling'>Sibling</option> */}
                                        <option value='Spouse'>Spouse</option>
                                        <option value='Friend/Colleague'>Friend/Colleague</option>
                                        <option value='Other Relative'>Other Relative</option>
                                    </select>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Reference 5 Name</label>

                                    <input onChange={(e) => this.referencechange(e, 'name5')} type="text" className="form-control" placeholder='Name' value={this.state.referenceDataState.name5} />

                                </div>
                                <div className="col-sm-4 col-xs-12" style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Number</label>

                                    <input onChange={(e) => this.referencechange(e, 'number5')} className="form-control" placeholder='Number' type='number' value={this.state.referenceDataState.number5} />

                                </div>
                                <div className='col-sm-4 col-xs-12' style={{marginTop:'10px'}}>
                                    <label style={{ color: '#000' }}>Relationship</label>
                                    <select onChange={(e) => this.referencechange(e, 'relationShip5')} className='form-control' value={this.state.referenceDataState.relationShip5}>
                                        <option value=''>Select Value</option>
                                        <option value='Friend/Colleague'>Friend/Colleague</option>
                                        <option value='Other Relative'>Other Relative</option>
                                    </select>

                                </div>
                            </div>
                            <div className="row " style={{marginTop:'20px'}}>
                                <div className="col-xs-12">
                                    <button className="btn btn-primary" onClick={(e) => this.referenceUpdate(e)}>
                                        Save Reference Details
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </>
        )
    }
}
export default ReferenceEdit