import React from 'react'
import { ROLE } from '../../../../utils/Constant'

const userDocs = ({ sendToDigitap, sendToFinb, userDetail, closeautomationDoc, openautomationDocuments, automationDocument, closeAdminDoc, openAdminDocuments, adminDocument, typeofDocument, bucketView, urlName, docViewData, ViewDocument, allDetail, loaderState, openAllDocs, saveDocName, admin, markCompleteBtn, markAsComplete }) => (
    <div className="container-fluid crm-user-docs">
        <div className="row">
            {/* {console.log(allDetail)} */}
            {loaderState != true ?
                <div className="col-sm-6 col-xs-12 text-left">
                    <button className="profile-save-btn" onClick={e => openAllDocs()}>Open All Docs</button>
                </div>
                : ""}

        </div>
        {allDetail != '' && allDetail != null && allDetail.documentList != null ?
            <div className='row' style={{ marginBottom: '15px' }}>

                <div className='col-sm-4 col-xs-12'></div>
                <div className='col-sm-4 col-xs-12'> <select className='form-control' onChange={(e) => bucketView(e)}>
                    <option value=''>Select Documents</option>
                    {allDetail.documentList.photographUrl != null || allDetail.documentList.adharCardUrl != null || allDetail.documentList.panCardUrl != null ?

                        <option value='KYC'>KYC/Office ID/Photo</option>
                        : ""}
                    {allDetail.documentList.bankStatementUrl != null && allDetail.documentList.bankStatementUrl != '' && allDetail.documentList.bankStatementUrl != undefined ?
                        <option value='BANKSTATEMENT'>Bank Statement</option>
                        : ""}
                    {allDetail.documentList.salaryProofUrl != null && allDetail.documentList.salaryProofUrl != '' ?

                        <option value='SALARY'>Salary Slip/Offer Letter/</option>
                        : ""}
                    {allDetail.documentList.currentAddressUrl != null || allDetail.documentList.loanAgreement != null && allDetail.documentList.stampDutyUrl != null && allDetail.documentList.signedLoanAggreement != null ?

                        <option value='NOC'>Misll/CA/NOC</option>
                        : ""}
                </select></div>
                <div className='col-sm-4 col-xs-12'></div>

            </div>
            : allDetail.documentList == null && loaderState != true ? <h3 className='text-center'>No Documents By User</h3> : ""}
        {allDetail != '' && typeofDocument == 'KYC' && allDetail.documentList != '' ?
            <div className='row'>
                {allDetail.documentList.photographUrl != null && allDetail.documentList.photographUrl != '' ?

                    <div className="col-sm-4 col-xs-12" >

                        <div className="profile-details-box">
                            <div className="crm-user-head margin-0">
                                <a target="_blank" className="text-white" href={allDetail.documentList.photographUrl.includes('//credfin.money') ? allDetail.documentList.photographUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.photographUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Photo Id </a>
                            </div>
                            {allDetail.documentList.photographUrl.split("type=")[1] == "img" ?
                                <img className="" width="100%" height="100%" src={allDetail.documentList.photographUrl.includes('//credfin.money') ? allDetail.documentList.photographUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.photographUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                : ""}
                            {allDetail.documentList.photographUrl.split("type=")[1] == "pdf" ?
                                <object className="obj-doc" data={allDetail.documentList.photographUrl.includes('//credfin.money') ? allDetail.documentList.photographUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.photographUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                : ""}
                            {allDetail.documentList.photographUrl.includes("downloaddocxfile") ?
                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.photographUrl.includes('//credfin.money') ? allDetail.documentList.photographUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.photographUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                : ""}
                        </div>

                    </div>
                    : ""}
                {allDetail.documentList.adharCardUrl != null && allDetail.documentList.adharCardUrl != '' ?

                    <div className="col-sm-4 col-xs-12" >
                        <div className="profile-details-box">
                            <div className="crm-user-head margin-0">
                                <a target="_blank" className="text-white" href={allDetail.documentList.adharCardUrl.includes('//credfin.money') ? allDetail.documentList.adharCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.adharCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>AdharCard </a>
                            </div>
                            {allDetail.documentList.adharCardUrl.split("type=")[1] == "img" ?
                                <img className="" width="100%" height="100%" src={allDetail.documentList.adharCardUrl.includes('//credfin.money') ? allDetail.documentList.adharCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.adharCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                : ""}
                            {allDetail.documentList.adharCardUrl.split("type=")[1] == "pdf" ?
                                <object className="obj-doc" data={allDetail.documentList.adharCardUrl.includes('//credfin.money') ? allDetail.documentList.adharCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.adharCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                : ""}
                            {allDetail.documentList.adharCardUrl.includes("downloaddocxfile") ?
                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.adharCardUrl.includes('//credfin.money') ? allDetail.documentList.adharCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.adharCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                : ""}
                        </div>

                    </div>
                    : ""}
                {allDetail.documentList.panCardUrl != null && allDetail.documentList.panCardUrl != '' ?

                    <div className="col-sm-4 col-xs-12" >
                        <div className="profile-details-box">
                            <div className="crm-user-head margin-0">
                                <a target="_blank" className="text-white" href={allDetail.documentList.panCardUrl.includes('//credfin.money') ? allDetail.documentList.panCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.panCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Pancard </a>
                            </div>
                            {allDetail.documentList.panCardUrl.split("type=")[1] == "img" ?
                                <img className="" width="100%" height="100%" src={allDetail.documentList.panCardUrl.includes('//credfin.money') ? allDetail.documentList.panCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.panCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                : ""}
                            {allDetail.documentList.panCardUrl.split("type=")[1] == "pdf" ?
                                <object className="obj-doc" data={allDetail.documentList.panCardUrl.includes('//credfin.money') ? allDetail.documentList.panCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.panCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                : ""}
                            {allDetail.documentList.panCardUrl.includes("downloaddocxfile") ?
                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.panCardUrl.includes('//credfin.money') ? allDetail.documentList.panCardUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.panCardUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                : ""}
                        </div>

                    </div>
                    : ""}

            </div>
            : ""}
        {allDetail != '' && typeofDocument == 'BANKSTATEMENT' && allDetail.documentList != '' ?
            <div className='row'>
                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.CHECK_VIA_FINBIT) >= 0 && admin.emailId !== 'SuperUser' && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?

                    <div className='text-right'>
                        <button className='profile-save-btn' onClick={() => sendToDigitap()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via Digitap</button>
                        {/* <button className='profile-save-btn' onClick={() => sendToFinb()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via finbit</button> */}

                    </div> : ""}
                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.CHECK_VIA_FINBIT) >= 0 && admin.emailId === 'SuperUser' ?

                    <div className='text-right'>
                        <button className='profile-save-btn' onClick={() => sendToDigitap()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via Digitap</button>

                        {/* <button className='profile-save-btn' onClick={() => sendToFinb()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via finbit</button> */}

                    </div> : ""}
                {allDetail.documentList.bankStatementUrl != null && allDetail.documentList.bankStatementUrl != '' && allDetail.documentList.bankStatementUrl != undefined ?

                    <div className="col-sm-12 col-xs-12" >

                        <div className="profile-details-box">
                            <div className="crm-user-head margin-0">
                                <a target="_blank" className="text-white" href={allDetail.documentList.bankStatementUrl.includes('//credfin.money') ? allDetail.documentList.bankStatementUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.bankStatementUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Bank Statement </a>
                            </div>
                            {allDetail.documentList.bankStatementUrl.split("type=")[1] == "img" ?
                                <img className="" width="100%" height="100%" src={allDetail.documentList.bankStatementUrl.includes('//credfin.money') ? allDetail.documentList.bankStatementUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.bankStatementUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                : ""}
                            {allDetail.documentList.bankStatementUrl.split("type=")[1] == "pdf" ?
                                <object className="obj-doc" data={allDetail.documentList.bankStatementUrl.includes('//credfin.money') ? allDetail.documentList.bankStatementUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.bankStatementUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                : ""}
                            {allDetail.documentList.bankStatementUrl.includes("downloaddocxfile") ?
                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.bankStatementUrl.includes('//credfin.money') ? allDetail.documentList.bankStatementUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.bankStatementUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                : ""}
                        </div>
                    </div>
                    : ""}
            </div>
            : ""}
        {
            allDetail != '' && typeofDocument == 'SALARY' && allDetail.documentList != '' ?
                <div className='row'>
                    {allDetail.documentList.salaryProofUrl != null && allDetail.documentList.salaryProofUrl != '' ?

                        <div className="col-sm-12 col-xs-12" >

                            <div className="profile-details-box">
                                <div className="crm-user-head margin-0">
                                    <a target="_blank" className="text-white" href={allDetail.documentList.salaryProofUrl.includes('//credfin.money') ? allDetail.documentList.salaryProofUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.salaryProofUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Salary Proof </a>
                                </div>
                                {allDetail.documentList.salaryProofUrl.split("type=")[1] == "img" ?
                                    <img className="" width="100%" height="100%" src={allDetail.documentList.salaryProofUrl.includes('//credfin.money') ? allDetail.documentList.salaryProofUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.salaryProofUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                    : ""}
                                {allDetail.documentList.salaryProofUrl.split("type=")[1] == "pdf" ?
                                    <object className="obj-doc" data={allDetail.documentList.salaryProofUrl.includes('//credfin.money') ? allDetail.documentList.salaryProofUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.salaryProofUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                    : ""}
                                {allDetail.documentList.salaryProofUrl.includes("downloaddocxfile") ?
                                    <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.salaryProofUrl.includes('//credfin.money') ? allDetail.documentList.salaryProofUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.salaryProofUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                    : ""}
                            </div>
                        </div>
                        : ""}
                </div>
                : ""
        }
        {
            allDetail != '' && typeofDocument == 'NOC' && allDetail.documentList != '' ?

                <div className='row'>
                    {allDetail.documentList.currentAddressUrl != null && allDetail.documentList.currentAddressUrl != '' ?
                        <div className="col-sm-4 col-xs-12" >
                            <div className="profile-details-box">
                                <div className="crm-user-head margin-0">
                                    <a target="_blank" className="text-white" href={allDetail.documentList.currentAddressUrl.includes('//credfin.money') ? allDetail.documentList.currentAddressUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.currentAddressUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Current Address </a>
                                </div>
                                {allDetail.documentList.currentAddressUrl.split("type=")[1] == "img" ?
                                    <img className="" width="100%" height="100%" src={allDetail.documentList.currentAddressUrl.includes('//credfin.money') ? allDetail.documentList.currentAddressUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.currentAddressUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                    : ""}
                                {allDetail.documentList.currentAddressUrl.split("type=")[1] == "pdf" ?
                                    <object className="obj-doc" data={allDetail.documentList.currentAddressUrl.includes('//credfin.money') ? allDetail.documentList.currentAddressUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.currentAddressUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                    : ""}
                                {allDetail.documentList.currentAddressUrl.includes("downloaddocxfile") ?
                                    <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.currentAddressUrl.includes('//credfin.money') ? allDetail.documentList.currentAddressUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.currentAddressUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                    : ""}
                            </div>
                        </div>
                        : ""}

                    {allDetail.documentList.loanAgreement != null && allDetail.documentList.loanAgreement != '' ?

                        <div className="col-sm-4 col-xs-12" >
                            <div className="profile-details-box">
                                <div className="crm-user-head margin-0">
                                    <a target="_blank" className="text-white" href={allDetail.documentList.loanAgreement.includes('//credfin.money') ? allDetail.documentList.loanAgreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.loanAgreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Loan Agreement </a>
                                </div>
                                {allDetail.documentList.loanAgreement.split("type=")[1] == "img" ?
                                    <img className="" width="100%" height="100%" src={allDetail.documentList.loanAgreement.includes('//credfin.money') ? allDetail.documentList.loanAgreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.loanAgreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                    : ""}
                                {allDetail.documentList.loanAgreement.split("type=")[1] == "pdf" ?
                                    <object className="obj-doc" data={allDetail.documentList.loanAgreement.includes('//credfin.money') ? allDetail.documentList.loanAgreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.loanAgreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                    : ""}
                                {allDetail.documentList.loanAgreement.includes("downloaddocxfile") ?
                                    <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.loanAgreement.includes('//credfin.money') ? allDetail.documentList.loanAgreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.loanAgreement.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                    : ""}
                            </div>
                        </div>
                        : ""}
                    {allDetail.documentList.stampDutyUrl != null && allDetail.documentList.stampDutyUrl != '' ?
                        <div className="col-sm-4 col-xs-12" >
                            <div className="profile-details-box">
                                <div className="crm-user-head margin-0">
                                    <a target="_blank" className="text-white" href={allDetail.documentList.stampDutyUrl.includes('//credfin.money') ? allDetail.documentList.stampDutyUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.stampDutyUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Stamp Duty </a>
                                </div>
                                {allDetail.documentList.stampDutyUrl.split("type=")[1] == "img" ?
                                    <img className="" width="100%" height="100%" src={allDetail.documentList.stampDutyUrl.includes('//credfin.money') ? allDetail.documentList.stampDutyUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.stampDutyUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                    : ""}
                                {allDetail.documentList.stampDutyUrl.split("type=")[1] == "pdf" ?
                                    <object className="obj-doc" data={allDetail.documentList.stampDutyUrl.includes('//credfin.money') ? allDetail.documentList.stampDutyUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.stampDutyUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                    : ""}
                                {allDetail.documentList.stampDutyUrl.includes("downloaddocxfile") ?
                                    <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.stampDutyUrl.includes('//credfin.money') ? allDetail.documentList.stampDutyUrl.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.stampDutyUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                    : ""}
                            </div>

                        </div>
                        : ""}
                    {allDetail.documentList.signedLoanAggreement != null && allDetail.documentList.signedLoanAggreement != '' ?
                        <div className="col-sm-4 col-xs-12" >
                            <div className="profile-details-box">
                                <div className="crm-user-head margin-0">
                                    <a target="_blank" className="text-white" href={allDetail.documentList.signedLoanAggreement.includes('//credfin.money') ? allDetail.documentList.signedLoanAggreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.signedLoanAggreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')}>Signed LoanAggreement</a>
                                </div>
                                {allDetail.documentList.signedLoanAggreement.split("type=")[1] == "img" ?
                                    <img className="" width="100%" height="100%" src={allDetail.documentList.signedLoanAggreement.includes('//credfin.money') ? allDetail.documentList.signedLoanAggreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.signedLoanAggreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')} />
                                    : ""}
                                {allDetail.documentList.signedLoanAggreement.split("type=")[1] == "pdf" ?
                                    <object className="obj-doc" data={allDetail.documentList.signedLoanAggreement.includes('//credfin.money') ? allDetail.documentList.signedLoanAggreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.signedLoanAggreement.replace('//admin.credfin.money', '//adminsupport.credfin.money')} ></object>
                                    : ""}
                                {allDetail.documentList.signedLoanAggreement.includes("downloaddocxfile") ?
                                    <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList.signedLoanAggreement.includes('//credfin.money') ? allDetail.documentList.signedLoanAggreement.replace('//credfin.money', '//adminsupport.credfin.money') : allDetail.documentList.signedLoanAggreement.replace('//admin.credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                    : ""}
                            </div>

                        </div>
                        : ""}
                </div>
                : ""
        }

        {/* {allDetail != null && allDetail.documentList != null ?
            <div className="row">
                {Object.keys(allDetail.documentList).map((head, i) => {
                    if (typeof allDetail.documentList[head] === 'string' && allDetail.documentList[head].substring(0, 4) === 'http') {
                        return (
                            <div className="col-sm-4 col-xs-12" key={i}>
                                <div className="profile-details-box">
                                    <div className="crm-user-head margin-0">
                                        <a target="_blank" className="text-white" href={allDetail.documentList[head].replace('//credfin.money', '//adminsupport.credfin.money')}>{head.split("Url")[0]}</a>
                                    </div>
                                    {allDetail.documentList[head].split("type=")[1] == "img" ?
                                        <img className="" width="100%" height="100%" src={allDetail.documentList[head].replace('//credfin.money', '//adminsupport.credfin.money')} />
                                        : ""}
                                    {allDetail.documentList[head].split("type=")[1] == "pdf" ?
                                        <object className="obj-doc" data={allDetail.documentList[head].replace('//credfin.money', '//adminsupport.credfin.money')} ></object>
                                        : ""}
                                    {allDetail.documentList[head].includes("downloaddocxfile") ?
                                        <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + allDetail.documentList[head].replace('//credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                                        : ""}
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            : null}


{allDetail!=null && allDetail!='' ?
        <select onChange={(e) => ViewDocument(e)}>
            {allDetail!=null && allDetail!='' && allDetail.documentList.photographUrl != null  ?
                <option value='photographUrl'>Photo</option>
                : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.salaryProofUrl != '' ?
                <option value='salaryProofUrl'>salary Proof</option>: "" }
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.panCardUrl != '' ?
                <option value='panCardUrl'>pan Card</option> : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.bankStatementUrl != '' ?
                <option value='bankStatementUrl'>bankStatementUrl</option> : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.adharCardUrl != '' ?
                <option value='adharCardUrl'>adharCardUrl</option> : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.cibilUrl != '' ?
                <option value='cibilUrl'>cibilUrl</option> : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.loanAgreement != '' ?
                <option value='loanAgreement'>loanAgreement</option> : ""}
            {allDetail!=null && allDetail!='' &&  allDetail.documentList.stampDutyUrl != '' ?
                <option value='stampDutyUrl'>stampDutyUrl</option> : ""}

        </select>
        :""}
        {docViewData != '' ?
            <div className="col-sm-4 col-xs-12" >
                {console.log(docViewData.split("Url"))}
                <div className="profile-details-box">
                    <div className="crm-user-head margin-0">
                        <a target="_blank" className="text-white" href={docViewData.replace('//credfin.money', '//adminsupport.credfin.money')}>{urlName}</a>
                    </div>
                    {docViewData.split("type=")[1] == "img" ?
                        <img className="" width="100%" height="100%" src={docViewData.replace('//credfin.money', '//adminsupport.credfin.money')} />
                        : ""}
                    {docViewData.split("type=")[1] == "pdf" ?
                        <object className="obj-doc" data={docViewData.replace('//credfin.money', '//adminsupport.credfin.money')} ></object>
                        : ""}
                    {docViewData.includes("downloaddocxfile") ?
                        <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + docViewData.replace('//credfin.money', '//adminsupport.credfin.money') + "&embedded=true"}></iframe>
                        : ""}
                </div>
            </div>
            : ""} */}

        {
            allDetail != null && allDetail.adminList != null && allDetail.adminList.length > 0 && adminDocument == false ?
                <div className="text-center ">
                    <button className="profile-save-btn" onClick={e => openAdminDocuments()}>Open Admin Documents</button>
                </div>
                : ""
        }

        {
            allDetail != null && allDetail.adminList != null && allDetail.adminList.length > 0 && adminDocument == true ?
                <div className="row">
                    <div className="">
                        <button className="profile-save-btn" onClick={() => closeAdminDoc()}>Close Admin Documents</button>
                    </div>
                    <h3 className="text-center blue-text">Admin Docs</h3>
                    {allDetail.adminList.map((imgURL, i) => {
                        return (
                            <div key={i} className="col-sm-4 col-xs-12">
                                <div className="profile-details-box">
                                    <div className="crm-user-head margin-0">
                                        <a target="_blank" className="text-white" href={imgURL.documentUrl.includes('//credfin.money') ? imgURL.documentUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl.includes('//admin.credfin.money') ? imgURL.documentUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl}>
                                            <h5>{imgURL.type}</h5>
                                        </a>
                                    </div>
                                    {imgURL.documentUrl != null && imgURL.documentUrl.includes("downloaddocxfile") ?
                                        <iframe src={"https://docs.google.com/gview?url=" + imgURL.documentUrl.includes('//credfin.money') ? imgURL.documentUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl.includes('//admin.credfin.money') ? imgURL.documentUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl + "&embedded=true"} className="obj-doc"></iframe>
                                        : imgURL.documentUrl != null && imgURL.documentUrl.split("type=")[1] == "img" ?
                                            <img className="" width="100%" height="100%" src={imgURL.documentUrl != null ? imgURL.documentUrl.includes('//credfin.money') ? imgURL.documentUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl.includes('//admin.credfin.money') ? imgURL.documentUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl : ""} />
                                            : <object className="obj-doc" data={imgURL.documentUrl != null ? imgURL.documentUrl.includes('//credfin.money') ? imgURL.documentUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl.includes('//admin.credfin.money') ? imgURL.documentUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : imgURL.documentUrl : ""} ></object>
                                    }

                                </div>
                            </div>
                        )
                    })}
                </div>
                : null
        }
        {
            admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && allDetail != null && allDetail.automationDoc != null && allDetail.automationDoc.length > 0 && automationDocument == false ?
                <div className="text-center ">
                    <button className="profile-save-btn" onClick={e => openautomationDocuments()}>Open Automation Documents</button>
                </div>
                : ""
        }
        {
            admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                automationDocument == true ?
                    <div className="row">
                        <button className="profile-save-btn" onClick={e => closeautomationDoc()}>Close Automation Documents</button>

                        <h3 className="text-center blue-text">Automation Docs</h3>
                        {allDetail.automationDoc.map((imgURL, i) => {
                            return (
                                <div key={i} className="upload-docs col-sm-6 col-xs-12">
                                    <select className="form-control" onChange={e => saveDocName(e, imgURL.imageUrl)} defaultValue={imgURL.type}>
                                        <option value="">Select Name</option>
                                        <option value="photograph">photograph</option>
                                        <option value="adharCard">adharCard</option>
                                        <option value="panCard">panCard</option>
                                        <option value="bankStatement">bankStatement</option>
                                        <option value="salaryProof">salaryProof</option>
                                        <option value="currentAddressProof">Current Address Proof</option>
                                    </select>
                                    <a target="_blank" className="text-white" href={imgURL.imageUrl != null ? imgURL.imageUrl.includes('//credfin.money') ? imgURL.imageUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.imageUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : ""}>
                                        {imgURL.imageUrl != null && imgURL.imageUrl.split("type=")[1] == "img" ?
                                            <img className="" width="100%" height="100%" src={imgURL.imageUrl != null ? imgURL.imageUrl.includes('//credfin.money') ? imgURL.imageUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.imageUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : ""} />
                                            :
                                            <object className="obj-doc" data={imgURL.imageUrl != null ? imgURL.imageUrl.includes('//credfin.money') ? imgURL.imageUrl.replace('//credfin.money', '//adminsupport.credfin.money') : imgURL.imageUrl.replace('//admin.credfin.money', '//adminsupport.credfin.money') : ""} ></object>
                                        }
                                    </a>
                                </div>

                            )
                        })}

                    </div>
                    : null : null
        }
    </div >
)

export default userDocs;