import React, { useEffect, useState } from "react";
import { getAllEmployees, deleteEmployeeById, getAttendenceApi, downloadReportApi } from "../../targetactioncreator";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';
import AddAttendaence from "../../../../../presentationals/Popup/addAttendence";
import { ROLE } from "../../../../../../utils/Constant";

const EmpList = ({ editHandler, admin, showButton }) => {
    console.log(admin.emailId)
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [employeeData, setEmpData] = useState([]);
    const [editData, setEditData] = useState('');
    const [addPopupState, setaddPopupState] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [month, setMonth] = useState('January');
    const [year, setYear] = useState(new Date().getFullYear());
    const [monthEmp, setMonthEmp] = useState('');
    const [yearEmp, setYearEmp] = useState('');
    const [attendanceData, setAttendanceData] = useState([]);
    const [descriptionPopup, setdescriptionPopup] = useState({ show: false, data: '' })
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i);

    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];

    const handleToggle = () => { };
    const handleRestart = () => { };

    const handlebtnclick = (type, data) => {
        console.log(data);
        setLoader(true);
        deleteEmployeeById(data?.id, (callback) => {
            console.log(callback);
            setLoader(false);
            if (callback.status == '200') {
                getEmployees(monthEmp, yearEmp);
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        });
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        getEmployees(monthEmp, yearEmp);
    }, []);

    const getEmployees = (monthEmp, yearEmp) => {
        let isAllowed = false;
        if(admin.emailId === "2kanchalaggarwal@gmail.com" || admin.emailId === "SuperUser"){
            isAllowed = true
        }
        setLoader(true);
        getAllEmployees(monthEmp, yearEmp, admin?.emailId,isAllowed, callback => {
            console.log(callback);
            setLoader(false);
            if (callback && callback?.status === 200) {
                setEmpData(callback.data);
            }
        });
    };

    const closePopup = () => {
        setPopup(false);
    };

    const addPopup = (data) => {
        setaddPopupState(true);
        setEditData(data);
    };

    const closeDisbursePopup = () => {
        setaddPopupState(false);
        getEmployees(monthEmp, yearEmp);
    };

    const showDetailEmp = (data) => {
        setSelectedEmployee(data);
        setShowDetails(true);
        calculateAttendance(data.employeeID); // Call attendance on employee selection
    };

    const closeDetailView = () => {
        setShowDetails(false);
        setSelectedEmployee(null);
        setAttendanceData([]);
        setMonth('')
        setYear('')
    };

    const calculateAttendance = (employeeId) => {
        getAttendenceApi(employeeId, month, year, callback => {
            if (callback && callback.status === 200) {
                setAttendanceData(callback.data); // Store attendance data
            } else {
                setAttendanceData([]); // Clear if no data
            }
        });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        if (name === "month") {
            setMonth(value);
        } else if (name === "year") {
            setYear(value);
        }
    };
    const handleFilterChangeemp = (e) => {
        const { name, value } = e.target;
        if (name === "month") {
            setMonthEmp(value);
        } else if (name === "year") {
            setYearEmp(value);
        }
    };
    const filterData = () => {
        getEmployees(monthEmp, yearEmp)
    }
    const groupByDepartment = employeeData.length > 0 ? employeeData.reduce((acc, employee) => {
        const dept = employee.departmentName || 'Unassigned';
        if (!acc[dept]) acc[dept] = [];
        acc[dept].push(employee);
        return acc;
    }, {}) : "";


    const handleDescriptionPopup = (data) => {
        setdescriptionPopup({ show: true, data: data })
    }

    const closeDescriptionPopup = () => {
        setdescriptionPopup({ show: false, data: '' })
    }
    const downloadReport = () => {
        downloadReportApi(admin.emailId, monthEmp, yearEmp, callback => {
           
        });
    }

    return (
        <>
            {showDetails ? <button className="btn btn-primary" style={{ marginTop: '30px' }} onClick={closeDetailView}>Back to list</button> : ""}

            <div className={`${window.innerWidth > 1800 ? 'container-fluid' : 'container-fluid'} Executor`}>
                {loader && <Loader />}
                {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
                {addPopupState && <AddAttendaence editData={editData} admin={admin} closeDisbursePopup={closeDisbursePopup} />}
                {
                    !showDetails ?
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-2 colxs-12">
                                <label >Month</label>
                                <select className="form-control" name="month" value={monthEmp} onChange={handleFilterChangeemp}>
                                    <option value={''}>All Months</option>
                                    {months.map(m => (
                                        <option key={m.value} value={m.value}>{m.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-2 col-xs-12">
                                <label >Year</label>
                                <select className="form-control" name="year" value={yearEmp} onChange={handleFilterChangeemp}>
                                    <option value={''}>Select Year</option>

                                    {years.map(y => (
                                        <option key={y} value={y}>{y}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>
                                <button className="btn btn-primary" style={{ width: '40px', height: '40px',marginRight:'20px' }} onClick={() => filterData()}><i className="fa fa-filter"></i></button>
                                <button className="btn btn-primary" style={{ width: '40px', height: '40px' }} onClick={() => downloadReport()}><i className="fa fa-download"></i></button>
                            </div>
                            {/* <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>
                            </div> */}
                        </div> : ''}
                {!showDetails ? (
                    employeeData.length > 0 ? (
                        // <div className='' style={{ background: '#f2f2f2', padding: '10px', margin: '50px auto', borderRadius: '5px', overflow: 'auto' }}>
                        //     <table className='table-logs' style={{ marginBottom: '10px' }}>
                        //         <thead>
                        //             <tr>
                        //                 {
                        //                     admin.rolelist != undefined && admin.rolelist.length > 0 ?
                        //                     admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ?
                        //                     <th>Details</th>
                        //                 :'':''}
                        //                 <th>Employee Name</th>
                        //                 <th>Add Attendance</th>
                        //                 <th>Employee Type</th>
                        //                 <th>Department</th>
                        //                 <th>Joining Date</th>
                        //                 {/* <th>Salary</th>
                        //                 <th>Variable</th>
                        //                 <th>Other Components</th>
                        //                 <th>Active</th>
                        //                 <th>In-Hand Amount</th>
                        //                 <th>Tax Deducted</th> */}
                        //                 {showButton?<th>Edit</th>:""}
                        //                 {showButton?<th>Delete</th>:""}
                        //             </tr>
                        //         </thead>
                        //         <tbody>
                        //             {employeeData.map((data, i) => (
                        //                 <tr key={i} style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                        //                       {
                        //                     admin.rolelist != undefined && admin.rolelist.length > 0 ?
                        //                     admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ?
                        //                     <td><i className="fa fa-info " style={{ cursor: 'pointer' }} onClick={() => showDetailEmp(data)}></i></td>
                        //                     :'':''}
                        //                     <td className=''>{data.employeeName}</td>
                        //                     <td><button className="btn btn-primary" onClick={() => addPopup(data)}>Add</button></td>
                        //                     <td>{data.employeeType}</td>
                        //                     <td>{data.departmentName}</td>
                        //                     <td>{data.joiningDate}</td>
                        //                     {/* <td>{data.salary}</td>
                        //                     <td>{data.variable}</td>
                        //                     <td>{data.otherComponent}</td>
                        //                     <td style={{ width: '80px' }}><input readOnly type='checkbox' checked={data.isActive} /></td>
                        //                     <td>{data.inHandAmount}</td>
                        //                     <td>{data.taxDeducted}</td> */}
                        //                   {showButton?  <td><i className="fa fa-pencil-square-o fa-lg" onClick={() => editHandler(data)}></i></td>:""}
                        //                    {showButton? <td><i className="fa fa-trash-o fa-lg" onClick={() => handlebtnclick('delete', data)}></i></td>:""}
                        //                 </tr>
                        //             ))}
                        //         </tbody>
                        //     </table>
                        // </div>

                        groupByDepartment ?

                            <div className="emp-table" style={{ marginTop: '50px' }}>

                                <div className="container" style={{ overflowX: 'auto' }}>
                                    <table style={{ borderSpacing: '1.5px' }}>
                                        <thead style={{ background: 'rgb(185, 216, 249)', color: "rgb(0, 0, 0)", position: 'sticky', top: '0' }}>
                                            <tr className='thead-class'>
                                                <th className="text-center" style={{ position: 'sticky', left: '0', zIndex: '1', background: 'rgb(185,216,249)' }}>Department</th>
                                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ?
                                                <th className="text-center">Details</th>
                                                 :'':''} 
                                                <th className="text-center">Employee Name</th>
                                                <th className="text-center">Reporting Manager</th>
                                                <th className="text-center">Add Attendance</th>
                                                <th className="text-center">Employee Type</th>
                                                <th className="text-center">Attendance</th>

                                                <th className="text-center">Joining Date</th>

                                                {showButton ? <th className="text-center">Edit</th> : ""}

                                                {showButton ? <th className="text-center">Delete</th> : ""}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                Object.keys(groupByDepartment).map((date, dateIndex) => {
                                                    const dateGroup = groupByDepartment[date];
                                                    const rowColor = dateIndex % 2 === 0 ? '#f7f9fe' : '#fff';
                                                    return dateGroup.map((item, index) => (
                                                        <tr key={index}>
                                                            {
                                                                index === 0 && (
                                                                    <td rowSpan={dateGroup.length} className=" text-center" style={{
                                                                        backgroundColor: '#deeeff', position: 'sticky', left: '0', zIndex: '1'


                                                                    }}>
                                                                        {date}
                                                                    </td>
                                                                )
                                                            }
                                                            {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ?
                                                                    <td><i className="fa fa-info " style={{ cursor: 'pointer', borderBottomLeftRadius: '0px' }} onClick={() => showDetailEmp(item)}></i></td>
                                                                    : '' : ''}
                                                            <td className=''>{item.employeeName}</td>
                                                            <td className=''>{item.reportingManagerName}</td>

                                                            <td><button className="btn btn-primary" onClick={() => addPopup(item)}>Add</button></td>
                                                            <td>{item.employeeType}</td>
                                                            <td>{item.presentDays}/{item.totalDays}</td>
                                                            <td style={{ borderBottomRightRadius: '0px' }}>{item.joiningDate}</td>
                                                            {showButton ? <td><i className="fa fa-pencil-square-o fa-lg" onClick={() => editHandler(item)}></i></td> : ""}
                                                            {showButton ? <td style={{ borderBottomRightRadius: '0px' }}><i className="fa fa-trash-o fa-lg" onClick={() => handlebtnclick('delete', item)}></i></td> : ""}
                                                        </tr>
                                                    ));
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : ''
                    ) : (
                        <div className="text-center" style={{ marginTop: '50px' }}>
                            <img src={NodataImage} alt="No Data" />
                        </div>
                    )
                ) : (
                    <>
                        <h3>Employee Details</h3>
                        <div className="employee-detail-view shadow-card" style={{ marginTop: '30px', padding: '20px', border: ' 1px solid #f2f2f2', borderRadius: '5px' }}>
                            {showDetails && selectedEmployee && (
                                <>
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Name</label>
                                            <h4>{selectedEmployee?.employeeName ?? '-'}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Employee Type</label>
                                            <h4>{selectedEmployee?.employeeType ?? '-'}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Employee Email Id</label>
                                            <h4>{selectedEmployee?.employeeEmailId ?? '-'}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Department</label>
                                            <h4>{selectedEmployee?.departmentName ?? '-'}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Designation</label>
                                            <h4>{selectedEmployee?.designation ?? '-'}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Joining Date</label>
                                            <h4>{selectedEmployee?.joiningDate ?? '-'}</h4>
                                        </div>
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?
                                            <>
                                                <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'grey' }}>Salary</label>
                                                    <h4>{selectedEmployee?.salary ?? '-'}</h4>
                                                </div>
                                                <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'grey' }}>Variable</label>
                                                    <h4>{selectedEmployee?.variable ?? '-'}</h4>
                                                </div>
                                                <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'grey' }}>Other Components</label>
                                                    <h4>{selectedEmployee?.otherComponent ?? '-'}</h4>
                                                </div>
                                                <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'grey' }}>In-Hand Amount</label>
                                                    <h4>{selectedEmployee?.inHandAmount ?? '-'}</h4>
                                                </div>
                                                <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'grey' }}>Tax Deducted</label>
                                                    <h4>{selectedEmployee?.taxDeducted ?? '-'}</h4>
                                                </div>
                                            </>
                                            : ""}
                                    </div>

                                    {/* Filter Form */}
                                    <div className='row' style={{ marginTop: '30px' }}>
                                        <div className="col-sm-2 col-xs-12">
                                            <label >Month</label>
                                            <select className="form-control" name="month" value={month} onChange={handleFilterChange}>
                                                <option value={''}>All Months</option>
                                                {months.map(m => (
                                                    <option key={m.value} value={m.value}>{m.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">

                                            <label >Year</label>
                                            <select className="form-control" name="year" value={year} onChange={handleFilterChange}>
                                                <option value={''}>Current Year</option>

                                                {years.map(y => (
                                                    <option key={y} value={y}>{y}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>

                                            <button className="btn btn-primary" onClick={() => calculateAttendance(selectedEmployee.employeeID)} style={{ padding: '10px' }}>Filter Attendance</button>
                                        </div>
                                    </div>
                                    {attendanceData.length > 0 ?
                                        <div className='phocket-table-new' style={{}}>
                                            <table className='' style={{ marginBottom: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" >Added By</th>
                                                        <th className="text-center" >EMP Name</th>
                                                        <th className="text-center" >Month</th>
                                                        <th className="text-center" >Year </th>
                                                        <th className="text-center" > Absent </th>
                                                        <th className="text-center" >Earn Leave </th>
                                                        <th className="text-center" >Half Day </th>
                                                        <th className="text-center" >Paid Leave </th>
                                                        <th className="text-center" >Work Description </th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {attendanceData.length > 0 ? (
                                                        attendanceData.map((attendance, i) => (
                                                            <tr key={i}>
                                                                <td className="text-center">{attendance.addedBy}</td>
                                                                <td className="text-center">{attendance.employeeName}</td>
                                                                <td className="text-center">{attendance.month}</td>
                                                                <td className="text-center">{attendance.year}</td>
                                                                <td className="text-center">{attendance.noAbsent}</td>
                                                                <td className="text-center">{attendance.noEarnLeave}</td>
                                                                <td className="text-center">{attendance.noHalfDay}</td>
                                                                <td className="text-center">{attendance.noPaidLeave}</td>
                                                                <td className="text-center"><i className="fa fa-eye " style={{ cursor: 'pointer', borderBottomLeftRadius: '0px' }} onClick={() => handleDescriptionPopup(attendance?.workDescription)}></i></td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No attendance records found.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        : <h3 style={{ marginTop: '40px' }} className="text-center">No attendance records found.</h3>}
                                </>
                            )}
                        </div>
                        {
                            descriptionPopup.show ?
                                <DescriptionPopup data={descriptionPopup.data} closeDescriptionPopup={closeDescriptionPopup} />
                                : ''}
                    </>
                )}
            </div>
        </>
    );
};

export default EmpList;



const DescriptionPopup = ({ data, closeDescriptionPopup }) => {
    return (
        <>
            <div className="AccountReconciliaiton-CSS">
                <div className="background-blur"></div>
                < div class={`modal`}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modalheader">
                                <h4 class="">Work Description</h4>
                                <button type="button" onClick={() => closeDescriptionPopup()}>
                                    <i class="fa fa-times fs-1"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 className="text-center">{data !== null && data !== undefined && data !== "" && data?.length > 0 ? data : "No Data Found"}</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}