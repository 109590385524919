import React, { useState } from "react";
import XClose from "../../../maketingimages/xClose.png";
import Paperclip from "../../../maketingimages/paperclip.png";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
    BulkCommunicateByFormStatus,
    BulkCommunicateByFormStatusMail,
    BulkCommunicateByFormStatusNotification,
    getBranchCommunicateByEventName,
} from "../../actioncreator";
import {
    bulkCommTabNames as tabNames,
    aggregatorDetailsTabName,
} from "../../constans";
import { HeaderType, MSG_STRING, TemplateId } from "../../../../../presentationals/Admin/BulkActions/MessageTemplates.component";
import { notificationImageUploadApi } from "../../../../Admin/AdminActionCreator.component";
import SidePopup from "./sidepopup";
function BulkCommunicationModal({ admin, aggregator, showBulkCommunicationEvent, showBulkCommunicationLeadName, setShowBulkcommunication, payload, detailsData }) {
    const [tab, setTab] = useState("sms");
    const [msgBody, setMsgBody] = useState('')
    const [mailBody, setmailBody] = useState('')
    const [subject, setsubject] = useState('')
    const [notificationBody, setNotificationBody] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [notificationSubject, setNotoficationSubject] = useState('')
    const [popupState, setpopupState] = useState('')
    const [popupType, setpopupType] = useState('')
    const [msg, setmsg] = useState('')

    const [templateId, settemplateIdTosend] = useState('')
    const [msgTypeHeader, setmessageType] = useState('')

    const [data, setData] = useState({
        template: "",
        body: "",
        subject: "",
    });

    const changeTab = (value) => {
        setData({
            template: "",
            body: "",
            subject: "",
        });
        setTab(value);
    };
    const handleChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };
    const handleBody = (value) => {
        setData({ ...data, body: value });
    };
    const getBranchCommunicateForBreakDown = () => {
        if (msgBody && templateId) {
            BulkCommunicateByFormStatus(
                payload.fromDate, payload.toDate, msgBody, admin, templateId, msgTypeHeader, aggregator.partnerId, detailsData.form_status,
                (callback) => {
                    setpopupState(false)
                    setpopupType('success')
                    setmsg('Successfully sent !')
                    removePopup()
                    setMsgBody('')
                    settemplateIdTosend('')
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Please fill all details !')
            removePopup()
        }
    };
    const sendButton = () => {
        var payload = {
            typeList: [tab],
            body: tab === tabNames.sms ? data.body : data.body.replace(/&/g, "and"),
            templateId: data.template,
            partnerId: aggregator.aggregatorId,
        };

        if (tab === tabNames.email) {
            payload = { ...payload, subject: data.subject };
        }

        if (
            aggregatorDetailsTabName.Lead_Breakdown === showBulkCommunicationLeadName
        ) {
            payload = {
                ...payload,
                eventName: showBulkCommunicationEvent.eventName,
            };
            getBranchCommunicateByEventName(
                payload,
                (callback) => {
                },
                (error) => {
                    console.log(error);
                }
            );
        } else if (
            aggregatorDetailsTabName.Pushed_Lead === showBulkCommunicationLeadName
        ) {
            payload = {
                ...payload,
                formStatus: showBulkCommunicationEvent.form_status,
            };
            BulkCommunicateByFormStatus(
                payload,
                (callback) => {
                },
                (error) => {
                    console.log(error);
                }
            );
        }


    };
    const smsTemplateHandle = (e) => {
        if (e.target.value === '') {
            setMsgBody('')
            settemplateIdTosend('')
            setmessageType('')
        }
        if (e.target.value == 'REVIEW_ON_PLAYSTORE') {
            setMsgBody(MSG_STRING.REVIEW_ON_PLAYSTORE)
            settemplateIdTosend(TemplateId.REVIEW_ON_PLAYSTORE)
            setmessageType(HeaderType.REVIEW_ON_PLAYSTORE)
        }

        if (e.target.value == 'MONTH_END_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.MONTH_END_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.MONTH_END_SAL_ADVANCE)
            setmessageType(HeaderType.MONTH_END_SAL_ADVANCE)
        }
        if (e.target.value == 'BILL_PAY_SAL_ADVANCE') {
            setMsgBody(MSG_STRING.BILL_PAY_SAL_ADVANCE)
            settemplateIdTosend(TemplateId.BILL_PAY_SAL_ADVANCE)
            setmessageType(HeaderType.BILL_PAY_SAL_ADVANCE)
        }
        if (e.target.value == 'CREDFIN_APP_TRY_KARO') {
            setMsgBody(MSG_STRING.CREDFIN_APP_TRY_KARO)
            settemplateIdTosend(TemplateId.CREDFIN_APP_TRY_KARO)
            setmessageType(HeaderType.CREDFIN_APP_TRY_KARO)
        }
        if (e.target.value == 'JHATPAT_LOAN') {
            setMsgBody(MSG_STRING.JHATPAT_LOAN)
            settemplateIdTosend(TemplateId.JHATPAT_LOAN)
            setmessageType(HeaderType.JHATPAT_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'FESTIVE_SHOPPING_LOAN') {
            setMsgBody(MSG_STRING.FESTIVE_SHOPPING_LOAN)
            settemplateIdTosend(TemplateId.FESTIVE_SHOPPING_LOAN)
            setmessageType(HeaderType.FESTIVE_SHOPPING_LOAN)
        }
        if (e.target.value == 'LOAN_WITHIN_FEW_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_FEW_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_FEW_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_FEW_MINUTES)
        }
        if (e.target.value == 'LAST_CHANCE_LOAN') {
            setMsgBody(MSG_STRING.LAST_CHANCE_LOAN)
            settemplateIdTosend(TemplateId.LAST_CHANCE_LOAN)
            setmessageType(HeaderType.LAST_CHANCE_LOAN)
        }
        if (e.target.value == 'UPTO_200000_LOAN') {
            setMsgBody(MSG_STRING.UPTO_200000_LOAN)
            settemplateIdTosend(TemplateId.UPTO_200000_LOAN)
            setmessageType(HeaderType.UPTO_200000_LOAN)
        }
        if (e.target.value == 'LOAN_WAITING') {
            setMsgBody(MSG_STRING.LOAN_WAITING)
            settemplateIdTosend(TemplateId.LOAN_WAITING)
            setmessageType(HeaderType.LOAN_WAITING)
        }
        if (e.target.value == 'LOAN_AT_LOW_INTEREST') {
            setMsgBody(MSG_STRING.LOAN_AT_LOW_INTEREST)
            settemplateIdTosend(TemplateId.LOAN_AT_LOW_INTEREST)
            setmessageType(HeaderType.LOAN_AT_LOW_INTEREST)

        }
        if (e.target.value == 'MONEY_AT_SPEED_OF_ROCKET') {
            setMsgBody(MSG_STRING.MONEY_AT_SPEED_OF_ROCKET)
            settemplateIdTosend(TemplateId.MONEY_AT_SPEED_OF_ROCKET)
            setmessageType(HeaderType.MONEY_AT_SPEED_OF_ROCKET)
        }
        if (e.target.value == 'ADVANCE_SALARY_VIA_BANKBAZAAR') {
            setMsgBody(MSG_STRING.ADVANCE_SALARY_VIA_BANKBAZAAR)
            settemplateIdTosend(TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR)
            setmessageType(HeaderType.ADVANCE_SALARY_VIA_BANKBAZAAR)
        }
        if (e.target.value == 'URGENT_CASH') {
            setMsgBody(MSG_STRING.URGENT_CASH)
            settemplateIdTosend(TemplateId.URGENT_CASH)
            setmessageType(HeaderType.URGENT_CASH)
        }
        if (e.target.value == 'LOAN_WITHIN_5_MINUTES') {
            setMsgBody(MSG_STRING.LOAN_WITHIN_5_MINUTES)
            settemplateIdTosend(TemplateId.LOAN_WITHIN_5_MINUTES)
            setmessageType(HeaderType.LOAN_WITHIN_5_MINUTES)
        }
        if (e.target.value == 'BANKBAZAAR') {
            setMsgBody(MSG_STRING.BANKBAZAAR)
            settemplateIdTosend(TemplateId.BANKBAZAAR)
            setmessageType(HeaderType.BANKBAZAAR)
        } if (e.target.value == 'SALARY_12_MONTH') {
            setMsgBody(MSG_STRING.SALARY_12_MONTH)
            settemplateIdTosend(TemplateId.SALARY_12_MONTH)
            setmessageType(HeaderType.SALARY_12_MONTH)
        }
        if (e.target.value == 'GET_A_CREDFIN_PL') {
            setMsgBody(MSG_STRING.GET_A_CREDFIN_PL)
            settemplateIdTosend(TemplateId.GET_A_CREDFIN_PL)
            setmessageType(HeaderType.GET_A_CREDFIN_PL)
        }
        if (e.target.value == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            setMsgBody(MSG_STRING.ADVANCE_SAL_BY_BUDDYLOAN)
            settemplateIdTosend(TemplateId.ADVANCE_SAL_BY_BUDDYLOAN)
            setmessageType(HeaderType.ADVANCE_SAL_BY_BUDDYLOAN)
        }
        if (e.target.value == 'PRE_QUALIFIED_BUDDY_LOAN_CREDFIN') {
            setMsgBody(MSG_STRING.PRE_QUALIFIED_BUDDY_LOAN_CREDFIN)
            settemplateIdTosend(TemplateId.PRE_QUALIFIED_BUDDY_LOAN_CREDFIN)
            setmessageType(HeaderType.PRE_QUALIFIED_BUDDY_LOAN_CREDFIN)
        }
        if (e.target.value == 'CREDFIN_BANKBAZAAR') {
            setMsgBody(MSG_STRING.CREDFIN_BANKBAZAAR)
            settemplateIdTosend(TemplateId.CREDFIN_BANKBAZAAR)
            setmessageType(HeaderType.CREDFIN_BANKBAZAAR)
        }
        if (e.target.value == 'LOAN_FOR_15000_SALARY') {
            setMsgBody(MSG_STRING.LOAN_FOR_15000_SALARY)
            settemplateIdTosend(TemplateId.LOAN_FOR_15000_SALARY)
            setmessageType(HeaderType.LOAN_FOR_15000_SALARY)
        }
        if (e.target.value == 'CredFin_App_partnered_with_Anjraj_Talent') {
            setMsgBody(MSG_STRING.CredFin_App_partnered_with_Anjraj_Talent)
            settemplateIdTosend(TemplateId.CredFin_App_partnered_with_Anjraj_Talent)
            setmessageType(HeaderType.CredFin_App_partnered_with_Anjraj_Talent)
        }
        if (e.target.value == 'Dear_Customer_Refer_your_friends') {
            setMsgBody(MSG_STRING.Dear_Customer_Refer_your_friends)
            settemplateIdTosend(TemplateId.Dear_Customer_Refer_your_friends)
            setmessageType(HeaderType.Dear_Customer_Refer_your_friends)
        }
        if (e.target.value == 'CredFin_App_partnered_with_Genesis') {
            setMsgBody(MSG_STRING.CredFin_App_partnered_with_Genesis)
            settemplateIdTosend(TemplateId.CredFin_App_partnered_with_Genesis)
            setmessageType(HeaderType.CredFin_App_partnered_with_Genesis)
        }
        if (e.target.value == 'CredFin_App_partnered_with_Hyrexpert') {
            setMsgBody(MSG_STRING.CredFin_App_partnered_with_Hyrexpert)
            settemplateIdTosend(TemplateId.CredFin_App_partnered_with_Hyrexpert)
            setmessageType(HeaderType.CredFin_App_partnered_with_Hyrexpert)
        }
        if (e.target.value == 'CredFin_App_partnered_with_Karyarth') {
            setMsgBody(MSG_STRING.CredFin_App_partnered_with_Karyarth)
            settemplateIdTosend(TemplateId.CredFin_App_partnered_with_Karyarth)
            setmessageType(HeaderType.CredFin_App_partnered_with_Karyarth)
        }
        if (e.target.value == 'CredFin_App_partnered_with_SAM_Career') {
            setMsgBody(MSG_STRING.CredFin_App_partnered_with_SAM_Career)
            settemplateIdTosend(TemplateId.CredFin_App_partnered_with_SAM_Career)
            setmessageType(HeaderType.CredFin_App_partnered_with_SAM_Career)
        }
        if (e.target.value == 'PHONEPARLOAN') {
            setMsgBody(MSG_STRING.PHONEPARLOAN)
            settemplateIdTosend(TemplateId.PHONEPARLOAN)
            setmessageType(HeaderType.PHONEPARLOAN)
        }
    }
    const sendHandlerMsg = (type) => {
        getBranchCommunicateForBreakDown()
    }
    const sendButtonMail = () => {
        if (mailBody && subject) {
            BulkCommunicateByFormStatusMail(
                payload.fromDate, payload.toDate, mailBody, admin, aggregator.partnerId, detailsData.form_status, subject,
                (callback) => {
                    setmailBody('')
                    setsubject('')
                    setpopupState(false)
                    setpopupType('success')
                    setmsg('Successfully sent !')
                    removePopup()
                },

                (error) => {
                    console.log(error);
                }
            );
        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Please fill all details !')
            removePopup()
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 10000);
    }
    const mailHandler = (e, type) => {
        console.log(e)
        if (type === 'subject') {
            setsubject(e.target.value)
        }
        if (type === 'body') {
            setmailBody(e)
        }
    }
    const notificationHandler = (e, type) => {
        if (type === 'body') {
            setNotificationBody(e)
        }
        if (type === 'url') {
            uploadNotificationImage(e)
        }
        if (type === 'subject') {
            setNotoficationSubject(e.target.value)
        }
    }
    const uploadNotificationImage = (e) => {
        let val = e.target.value.split(/[\\\/]/)[2];
        File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        notificationImageUploadApi(File, val, e.target.files[0].type,
            (callBack) => {
                if (callBack != 'failure') {
                    setImageUrl(callBack)
                    // this.setState({
                    //     popupState: true, popupStatus: 'File Uploaded',
                    //     notificationImage: callBack
                    // }, () => this.removePopup())
                } else {
                    // this.setState({ popupState: true, popupStatus: 'Failed To upload file' }, () => this.removePopup())
                }
            });

    }
    const sendNotification = () => {
        if (notificationBody && notificationSubject) {
            BulkCommunicateByFormStatusNotification(
                payload.fromDate, payload.toDate, notificationBody, admin, aggregator.partnerId, detailsData.form_status, notificationSubject, imageUrl,
                (callback) => { 
                    setNotificationBody('')
                    setNotoficationSubject('')
                    setImageUrl('')
                    setpopupState(false)
                    setpopupType('success')
                    setmsg('Successfully sent !')
                    removePopup()

                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            setpopupState(true)
            setpopupType('error')
            setmsg('Please fill all details !')
            removePopup()
        }
    }

    return (
        <>
            <div className="marketing-css"
                style={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 10000,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "99.8vh",
                    }}
                >
                    <div className="commModalBox">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h4 className="bulkCommHeader">Bulk Communication Via</h4>

                            <img
                                onClick={() => setShowBulkcommunication(false)}
                                className="hoverCusrsor"
                                src={XClose}
                                width={25}
                                height={25}
                            />
                        </div>

                        <div className="bulkCommPreHeaderContainer">
                            <div style={{ marginRight: "1rem" }}>
                                Form Status :
                                <span>
                                    <b style={{ color: "var(--icon-color, #232F63)" }}>
                                        &nbsp; {detailsData.form_status}
                                    </b>
                                </span>
                            </div>
                            <div>
                                Lead Count :
                                <span>
                                    <b style={{ color: "var(--icon-color, #232F63)" }}>
                                        &nbsp;{detailsData.count}
                                    </b>
                                </span>
                            </div>
                        </div>

                        {/* tabs  */}
                        <div className="bulkCommTabsContainer">
                            <div
                                className="bulkCommTabs"
                                style={{
                                    backgroundColor: tab === tabNames.email ? "#1D3557" : "#F2F2FA",
                                    borderRadius: "12px 0px 0px 12px",
                                    color: tab === tabNames.email ? "#FFFFFF" : "#000000",
                                }}
                                onClick={() => changeTab(tabNames.email)}
                            >
                                Email
                            </div>
                            <div
                                className="bulkCommTabs"
                                style={{
                                    backgroundColor: tab === tabNames.sms ? "#1D3557" : "#F2F2FA",
                                    color: tab === tabNames.sms ? "#FFFFFF" : "#000000",
                                }}
                                onClick={() => changeTab(tabNames.sms)}
                            >
                                SMS
                            </div>
                            <div
                                style={{
                                    backgroundColor:
                                        tab === tabNames.notification ? "#1D3557" : "#F2F2FA",
                                    borderRadius: "0px 12px 12px 0px",
                                    color: tab === tabNames.notification ? "#FFFFFF" : "#000000",
                                }}
                                className="bulkCommTabs"
                                onClick={() => changeTab(tabNames.notification)}
                            >
                                Notification
                            </div>
                        </div>

                        {tab === tabNames.email ? (
                            <div className="mt-4">
                                <div>
                                    <div className="inputTitle">Select Email Template</div>
                                    <select
                                        name="template"
                                        onChange={handleChange}
                                        id=""
                                        className="form-select"
                                        style={{
                                            minWidth: "165px",
                                            backgroundColor: "rgba(219, 233, 255, 0.70)",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        <option value="SMS Template">Customize Email Template</option>
                                    </select>
                                </div>

                                <div style={{ width: "100%", marginTop: "1rem" }}>
                                    <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                        Mail Box
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            margin: "auto",
                                            backgroundColor: "rgba(219, 233, 255, 0.70)",
                                            borderRadius: "6px",
                                            border: "none",
                                            padding: "20px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                margin: "auto",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            <div
                                                className="inputTitle"
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                Subject
                                            </div>
                                            <input
                                                type="text"
                                                id="subject"
                                                onChange={(e) => mailHandler(e, 'subject')}
                                                value={subject}
                                                name="subject"
                                                placeholder="Enter Subject"

                                                style={{
                                                    width: "100%",
                                                    border: "#FFFFFF",
                                                    borderRadius: "3px",
                                                    padding: "10px",
                                                    backgroundColor: "#FFFFFF",
                                                    marginTop: "0rem",
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                margin: "auto",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <div
                                                className="inputTitle"
                                                style={{ marginBottom: "0.5rem" }}
                                            >
                                                Mail Body
                                            </div>
                                            <ReactQuill
                                                style={{
                                                    backgroundColor: "#FFFFFF",
                                                    marginTop: "0rem",
                                                }}
                                                name="body"
                                                theme="snow"
                                                value={mailBody}
                                                // onChange={(value) => handleBody(value)}
                                                onChange={(e) => mailHandler(e, 'body')}
                                            // value={subje
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    title="SEND"
                                    onClick={() => sendButtonMail()}
                                    type="button"
                                    className="bulkCommSendBtn"
                                >
                                    Send Mail
                                </button>
                            </div>
                        ) : tab === tabNames.sms ? (
                            <div className="mt-4">
                                <div>
                                    <div className="inputTitle">Select SMS Template</div>
                                    <select
                                        name="template"
                                        onChange={(e) => smsTemplateHandle(e)}
                                        id=""
                                        className="form-select"
                                        style={{
                                            minWidth: "165px",
                                            backgroundColor: "rgba(219, 233, 255, 0.70)",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        <option value="">SMS Template</option>
                                        {Object.entries(TemplateId).map((data, i) => {
                                            return (
                                                <option value={data[0]}>{data[0]}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                {msgBody ?
                                    <div style={{ width: "100%", marginTop: "1rem" }}>
                                        <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                            SMS Body
                                        </div>

                                        <textarea
                                            value={msgBody}
                                            rows="6"
                                            readOnly={true}
                                            style={{
                                                width: "100%",
                                                backgroundColor: "rgba(219, 233, 255, 0.70)",
                                                borderRadius: "6px",
                                                border: "none",

                                            }}
                                        ></textarea>
                                    </div>
                                    : ""}
                                {msgBody ?
                                    <button
                                        title="SEND"
                                        onClick={() => sendHandlerMsg()}
                                        type="button"
                                        className="bulkCommSendBtn"
                                        style={{ width: '140px' }}
                                    >
                                        Send Message
                                    </button>
                                    : ""}
                            </div>
                        ) : (
                            <div className="mt-4">
                                <div>
                                    <div className="inputTitle">Select Notification Template</div>
                                    <select
                                        name="template"
                                        id=""
                                        className="form-select"
                                        style={{
                                            minWidth: "165px",
                                            backgroundColor: "rgba(219, 233, 255, 0.70)",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        <option value="SMS Template">
                                            Customize Notification Template
                                        </option>
                                    </select>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        marginBottom: "1rem",
                                        marginTop: '10px'
                                    }}
                                >
                                    <div
                                        className="inputTitle"
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        Subject
                                    </div>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="subject"
                                        onChange={(e) => notificationHandler(e, 'subject')}
                                        value={notificationSubject}
                                        name="subject"
                                        placeholder="Enter Subject"


                                    />
                                </div>
                                <div style={{ width: "100%", marginTop: "1rem" }}>
                                    <div className="inputTitle" style={{ marginBottom: "0.3rem" }}>
                                        Notification Body
                                    </div>
                                    <div>
                                        <ReactQuill
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                marginTop: "0rem",

                                            }}
                                            name="body"
                                            theme="snow"
                                            value={notificationBody}
                                            onChange={(value) => notificationHandler(value, 'body')}
                                        />
                                    </div>
                                    {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src={Paperclip} width={13} height={13} />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter text here"
                    style={{ border: "none" }}
                  />
                  <span>{">"}</span>
                </div> */}
                                </div>
                                <div style={{ width: "100%", marginTop: "1rem" }}>

                                    <div className="image-upload-div" style={{ borderRadius: '5px' }} >
                                        <input
                                            onChange={(value) => notificationHandler(value, 'url')}
                                            accept={['.png', '.jpeg', '.jpg']}
                                            className="file-upload-input" type='file' />
                                        <div className="drag-text-new">
                                            <h5 className="upload-icon"> Click here to Upload Image </h5>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    title="SEND"
                                    style={{ width: '150px' }}
                                    onClick={() => sendNotification()}
                                    type="button"
                                    className="bulkCommSendBtn"
                                >
                                    Send Notification
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {popupState ?

                <SidePopup msg={msg}
                    popupType={popupType} />
                : ""}
        </>
    );
}

export default BulkCommunicationModal;
