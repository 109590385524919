import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveUpdateVendorMaster } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';
import moment from "moment";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const IfscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const defaultDate = (type) => {
    const d = new Date();
    const year = d.getFullYear();
    const day = String(d.getDate()).padStart(2, '0');
    let month;
    if (type == 'currentMonth') {
        month = String(d.getMonth() + 1).padStart(2, '0');
    } else if (type == 'nextMonth') {
        month = String(d.getMonth() + 2).padStart(2, '0');
    }

    return `${year}-${month}-${day}`;
}

const EditTarget = ({ editData }) => {
    const [loader, setLoader] = useState(false);
    const [categoryAmount, setCategoryAmount] = useState(0)
    const [expanded, setExpanded] = React.useState(false);
    const [editedId, setEditedId] = useState({})
    const [categoryData, setCategoryData] = useState({
        "legalEntityName": "",
        "addedOn": "",
        "vendorId": "",
        "vendorName": "",
        "groupName": "",
        "mainBalanceBill": "",
        "creditDays": "",
        "entityName": "",
        "address": "",
        "state": "",
        "pincode": "",
        "country": "india",
        "accountHolderName": "",
        "ifscCode": "",
        "bankName": "",
        "branchName": "",
        "upiId": "",
        "contactPersonName": "",
        "mobileNumber": "",
        "telephoneNumber": "",
        "emailId": "",
        "alternativeEmailId": "",
        "panCard": "",
        "partyType": "",
        "gstNumber": "",

    });


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleCategoryValueChange = (e) => {
        const { name, value } = e.target;
        if(name == "legalEntityName"){
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: value,
                "entityName":value
            }));
        }else{
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: value,
            })); 
        }
    };

    const validateForm = (type) => {
        let formErrors = {};
        let isValid = true;
        if (type === "basicDetails") {
            if (!categoryData.legalEntityName) {
                formErrors.legalEntityName = "Entity Name is required";
                isValid = false;
            }
            // if (!categoryData.mainBalanceBill) {
            //     formErrors.mainBalanceBill = "Bill is required";
            //     isValid = false;
            // }
            // if (!categoryData.creditDays) {
            //     formErrors.creditDays = "Credit Days is required";
            //     isValid = false;
            // }
        } else if (type === "mailingDetails") {
            if (!categoryData.entityName) {
                formErrors.entityName = "Entity Name is required";
                isValid = false;
            }
            if (!categoryData.address) {
                formErrors.address = "Address is required";
                isValid = false;
            }
            if (!categoryData.state) {
                formErrors.state = "state is required";
                isValid = false;
            }
            if (!categoryData.pincode) {
                formErrors.pincode = "pincode is required";
                isValid = false;
            } else if (categoryData.pincode?.length !== 6) {
                formErrors.pincode = "Enter correct Pincode";
                isValid = false;
            }
            if (!categoryData.country) {
                formErrors.country = "country is required";
                isValid = false;
            }
        } else if (type === "bankingDetails") {
            if (!categoryData.accountHolderName) {
                formErrors.accountHolderName = "Name is required";
                isValid = false;
            }
            if (!categoryData.ifscCode) {
                formErrors.ifscCode = "IFSC Code is required";
                isValid = false;
             } else if (!IfscRegex.test(categoryData.ifscCode)) {
                formErrors.ifscCode = "Invalid IFSC Code";
                isValid = false;
            }
            if (!categoryData.bankName) {
                formErrors.bankName = "Bank Name is required";
                isValid = false;
            }
            if (!categoryData.branchName) {
                formErrors.branchName = "Branch Name is required";
                isValid = false;
            }
        } else if (type === "contactPersonDetails") {
            // if (!categoryData.contactPersonName) {
            //     formErrors.contactPersonName = "Name is required";
            //     isValid = false;
            // }
            // if (!categoryData.mobileNumber) {
            //     formErrors.mobileNumber = "Number is required";
            //     isValid = false;
            // } else if (!(categoryData.mobileNumber?.length == 10)) {
            //     formErrors.mobileNumber = "Invalid Number";
            //     isValid = false;
            // }
            // if (!categoryData.telephoneNumber) {
            //     formErrors.telephoneNumber = "Number is required";
            //     isValid = false;
            // } else if (!(categoryData.telephoneNumber?.length == 10)) {
            //     formErrors.mobileNumber = "Invalid Number";
            //     isValid = false;
            // }
            // if (!categoryData.emailId) {
            //     formErrors.emailId = "EmailId is required";
            //     isValid = false;
            // } else if (!emailRegex.test(categoryData.emailId)) {
            //     formErrors.emailId = "Invalid EmailId";
            //     isValid = false;
            // }

        } else if (type === "TaxDetails") {
            if (!categoryData.panCard) {
                formErrors.panCard = "PAN Card is required";
                isValid = false;
            } else if (!panRegex.test(categoryData.panCard)) {
                formErrors.emailId = "Invalid EmailId";
                isValid = false;
            }
            if (!categoryData.partyType) {
                formErrors.partyType = "Party Type is required";
                isValid = false;
            }
            if ((categoryData.partyType === "Composition" || categoryData.partyType === "Regular") && !categoryData.gstNumber) {
                formErrors.partyType = "GSTIN is required";
                isValid = false;
            }
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (type) => {
        // e.preventDefault();
        if (validateForm(type)) {
            const obj = { ...categoryData }
            if (editedId !== null && editedId !== undefined && type !== "panel1" && (editData == null || editData == undefined || editData == "")) {
                obj["id"] = editedId.id;
                obj["addedOn"] = editedId.addedOn;
                obj["vendorId"] = editedId.vendorId;
            }
            setLoader(true)
            saveUpdateVendorMaster(obj, callback => {
                setLoader(false)
                if (callback && callback?.status == 200 && callback?.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                    setEditedId({
                        id: callback?.data?.id || "",
                        addedOn: callback?.data?.addedOn || "",
                        vendorId: callback?.data?.vendorId || ""
                    })
                    if (type === "basicDetails") {
                        setExpanded("panel2")
                    } else if (type === "mailingDetails") {
                        setExpanded("panel3")
                    } else if (type === "bankingDetails") {
                        setExpanded("panel4")
                    } else if (type === "contactPersonDetails") {
                        setExpanded("panel5")
                    } else if (type === "TaxDetails") {
                        setExpanded("")
                    } else {
                        setExpanded("")
                    }
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                id: editData?.id || "",
                legalEntityName: editData.legalEntityName || "",
                vendorId: editData?.vendorId || "",
                addedOn: editData?.addedOn || "",
                vendorName: editData?.vendorName || "",
                groupName: editData?.groupName || "",
                mainBalanceBill: editData?.mainBalanceBill || "",
                creditDays: editData?.creditDays || "",
                entityName: editData?.entityName || "",
                address: editData?.address || "",
                state: editData?.state || "",
                pincode: editData?.pincode || "",
                country: editData?.country || "india",
                accountHolderName: editData?.accountHolderName || "",
                ifscCode: editData?.ifscCode || "",
                bankName: editData?.bankName || "",
                branchName: editData?.branchName || "",
                upiId: editData?.upiId || "",
                contactPersonName: editData?.contactPersonName || "",
                mobileNumber: editData?.mobileNumber || "",
                telephoneNumber: editData?.telephoneNumber || "",
                emailId: editData?.emailId || "",
                alternativeEmailId: editData?.alternativeEmailId || "",
                panCard: editData?.panCard || "",
                partyType: editData?.partyType || "",
                gstNumber: editData?.gstNumber || "",
            });

            if (editData?.legalEntityName == "" || editData?.legalEntityName == undefined || editData?.legalEntityName == null || editData?.mainBalanceBill == "" || editData?.mainBalanceBill == undefined || editData?.mainBalanceBill == null || editData?.creditDays == "" || editData?.creditDays == undefined || editData?.creditDays == null) {
                setExpanded("panel1")
            } else if (editData?.entityName == "" || editData?.entityName == undefined || editData?.entityName == null || editData?.address == "" || editData?.address == undefined || editData?.address == null || editData?.state == "" || editData?.state == undefined || editData?.state == null || editData?.pincode == "" || editData?.pincode == undefined || editData?.pincode == null || editData?.country == "" || editData?.country == undefined || editData?.country == null) {
                setExpanded("panel2")
            } else if (editData?.accountHolderName == "" || editData?.accountHolderName == undefined || editData?.accountHolderName == null || editData?.ifscCode == "" || editData?.ifscCode == undefined || editData?.ifscCode == null || editData?.bankName == "" || editData?.bankName == undefined || editData?.bankName == null || editData?.branchName == "" || editData?.branchName == undefined || editData?.branchName == null) {
                setExpanded("panel3")
            } else if (editData?.contactPersonName == "" || editData?.contactPersonName == undefined || editData?.contactPersonName == null || editData?.mobileNumber == "" || editData?.mobileNumber == undefined || editData?.mobileNumber == null || editData?.telephoneNumber == "" || editData?.telephoneNumber == undefined || editData?.telephoneNumber == null || editData?.emailId == "" || editData?.emailId == undefined || editData?.emailId == null) {
                setExpanded("panel4")
            } else if (editData?.panCard == "" || editData?.panCard == undefined || editData?.panCard == null || editData?.partyType == "" || editData?.partyType == undefined || editData?.partyType == null) {
                setExpanded("panel5")
            } else {
                setExpanded("panel1")
            }
        } else {
            setExpanded("panel1")
            setCategoryData({
                legalEntityName: "",
                vendorName: "",
                groupName: "",
                mainBalanceBill: "",
                creditDays: "",
                entityName: "",
                address: "",
                state: "",
                pincode: "",
                country: "india",
                accountHolderName: "",
                ifscCode: "",
                bankName: "",
                branchName: "",
                upiId: "",
                contactPersonName: "",
                mobileNumber: "",
                telephoneNumber: "",
                emailId: "",
                alternativeEmailId: "",
                panCard: "",
                partyType: "",
                gstNumber: "",
            })
        }
    }, [editData]);

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader /> : ''}
            <div className="target-box">
                <h3>Add/Update Vendor Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <div style={{ padding: '10px', background: '#fff' }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ background: '#f4f3f1' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '18px' }} >
                                Basic Details
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Legal Entity Name  <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="legalEntityName"
                                                    value={categoryData.legalEntityName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.legalEntityName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.legalEntityName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Vendor Name</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="vendorName"
                                                    value={categoryData.vendorName}
                                                    onChange={handleCategoryValueChange}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Group Name</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="groupName"
                                                    value={categoryData.groupName}
                                                    onChange={handleCategoryValueChange}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Main Balance Bill</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="number"
                                                    name="mainBalanceBill"
                                                    value={categoryData.mainBalanceBill}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.mainBalanceBill && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.mainBalanceBill}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Credit Days</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="number"
                                                    name="creditDays"
                                                    value={categoryData.creditDays}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.creditDays && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.creditDays}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12" style={{ marginTop: '30px', textAlign: 'center' }}>
                                        <button ype="submit" className="btn btn-primary" onClick={() => handleSubmit("basicDetails")}> Save Basic Details</button>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ background: '#f4f3f1' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '18px' }}>Mailing Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Entity Name <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="entityName"
                                                    disabled
                                                    value={categoryData.entityName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.entityName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.entityName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Address <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="address"
                                                    value={categoryData.address}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.address && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.address}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>
                                                    State <span style={{ color: 'red' }}>*</span>
                                                </label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <select
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    name="state"
                                                    value={categoryData.state}
                                                    onChange={handleCategoryValueChange}
                                                >
                                                    <option value="">Select State</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                </select>
                                                {errors.state && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>
                                                        {errors.state}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Pincode <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="pincode"
                                                    value={categoryData.pincode}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.pincode && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.pincode}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Country <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="country"
                                                    value={categoryData.country}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.country && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.country}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12" style={{ marginTop: '30px', textAlign: 'center' }}>
                                        <button ype="submit" className="btn btn-primary" onClick={() => handleSubmit("mailingDetails")}> Save Mailing Details</button>
                                    </div>

                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ background: '#f4f3f1' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '18px' }}>
                                Banking Details
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Account Holder Name <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="accountHolderName"
                                                    value={categoryData.accountHolderName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.accountHolderName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.accountHolderName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>IFSC Code <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="ifscCode"
                                                    value={categoryData.ifscCode}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.accountHolderName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.ifscCode}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Bank Name <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="bankName"
                                                    value={categoryData.bankName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.bankName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.bankName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Branch Name <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="branchName"
                                                    value={categoryData.branchName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.branchName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.branchName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>UPI Id</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="upiId"
                                                    value={categoryData.upiId}
                                                    onChange={handleCategoryValueChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12" style={{ marginTop: '30px', textAlign: 'center' }}>
                                        <button ype="submit" className="btn btn-primary" onClick={() => handleSubmit("bankingDetails")}> Save Banking Details</button>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ background: '#f4f3f1' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '18px' }}>Contact Person Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Contact Person Name</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="contactPersonName"
                                                    value={categoryData.contactPersonName}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.contactPersonName && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.contactPersonName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Mobile Number</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="number"
                                                    name="mobileNumber"
                                                    value={categoryData.mobileNumber}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.mobileNumber && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.mobileNumber}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Telephone Number</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="number"
                                                    name="telephoneNumber"
                                                    value={categoryData.telephoneNumber}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.telephoneNumber && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.telephoneNumber}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Email Id</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="email"
                                                    name="emailId"
                                                    value={categoryData.emailId}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.emailId && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.emailId}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Alternate Email Id</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="email"
                                                    name="alternativeEmailId"
                                                    value={categoryData.alternativeEmailId}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.alternativeEmailId && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.alternativeEmailId}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12" style={{ marginTop: '30px', textAlign: 'center' }}>
                                        <button ype="submit" className="btn btn-primary" onClick={() => handleSubmit("contactPersonDetails")}> Save Details</button>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ background: '#f4f3f1' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel45bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '18px' }}>Tax Registration Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>PAN Card <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="text"
                                                    name="panCard"
                                                    value={categoryData.panCard}
                                                    onChange={handleCategoryValueChange}
                                                />
                                                {errors.panCard && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.panCard}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px', fontSize: "14px" }}>Party Type <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div>
                                                <select
                                                    style={{ background: '#fff', border: '1px solid', width: '100%' }}
                                                    className="form-control"
                                                    name="partyType"
                                                    value={categoryData.partyType}
                                                    onChange={handleCategoryValueChange}
                                                >
                                                    <option value="">Select Party Type</option>

                                                    <option value="Unregistered">
                                                        Unregistered
                                                    </option>
                                                    <option value="Composition">
                                                        Composition
                                                    </option>
                                                    <option value="Regular">
                                                        Regular
                                                    </option>

                                                </select>
                                                {errors.partyType && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.partyType}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        categoryData.partyType === "Composition" || categoryData.partyType === "Regular" ?
                                            <div className="col-sm-4 col-xs-12">
                                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                                    <div style={{ width: '50%' }}>
                                                        <label style={{ marginTop: '10px', fontSize: "14px" }}>GST Number</label>
                                                    </div>
                                                    <div style={{ width: '50%' }}>
                                                        <input
                                                            style={{ background: '#fff', border: '1px solid' }}
                                                            className="form-control"
                                                            type="text"
                                                            name="gstNumber"
                                                            value={categoryData.gstNumber}
                                                            onChange={handleCategoryValueChange}
                                                        />
                                                        {errors.gstNumber && (
                                                            <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.gstNumber}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                    <div className="col-xs-12" style={{ marginTop: '30px', textAlign: 'center' }}>
                                        <button ype="submit" className="btn btn-primary" onClick={() => handleSubmit("TaxDetails")}> Save Details</button>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div >
        </div >
    );
};

export default EditTarget;
