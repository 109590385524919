

var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;



export const agreegatorValidation = (agreegatorData) => {
    if (agreegatorData.type === '' ||agreegatorData.type === undefined) {
        return { status: false, msg: 'Please select Type', field: 'type' };
    }
    if (agreegatorData.companyName === '' ||agreegatorData.companyName === undefined) {
        return { status: false, msg: 'Please enter company name', field: 'companyName' };
    }
    if (agreegatorData.partnerName === '' ||agreegatorData.partnerName === undefined) {
        return { status: false, msg: 'Please enter company name', field: 'companyName' };
    }
    if (agreegatorData.organizationContactNo === '' ||agreegatorData.organizationContactNo === undefined) {
        return { status: false, msg: 'Please enter organization contact no', field: 'organizationContactNo' };
    }
    if (agreegatorData.contactPerson === '' ||agreegatorData.contactPerson === undefined) {
        return { status: false, msg: 'Please enter contact Person name', field: 'contactPerson' };
    }
 
    if (agreegatorData.email !== '' ||agreegatorData.email === undefined) {
        if (!filter.test(agreegatorData.email)) {
            return { status: false, msg: 'Please enter a valid email', field: 'email' };
        }
    }
    if (agreegatorData.mobileNumber === '' ||agreegatorData.mobileNumber === undefined) {
        return { status: false, msg: 'Please enter mobile number', field: 'mobileNumber' };
    }
  
    if (agreegatorData.creatorMode === '' ||agreegatorData.creatorMode === undefined) {
        return { status: false, msg: 'Please select creator mode', field: 'creatorMode' };
    }
    if (agreegatorData.status === '' ||agreegatorData.status === undefined) {
        return { status: false, msg: 'Please select status', field: 'status' };
    }
    return { status: true, msg: '' };

}
export const Emailvalidate = (userEmailInput) => {
    if (userEmailInput == '') {
        return { status: false, msg: 'Please enter your Email', field: 'emailId' };
    } else if (!filter.test(userEmailInput)) {
        return { status: false, msg: 'Please enter a valid Email', field: 'emailId' };
    }
    return { status: true, msg: '' };
}