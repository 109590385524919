import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const ChequeBounced = ({ allDetail }) => (
    <div>
        <div>Hello Dear Credfin Customer,</div>
        <br />
        {/* <div>This is to inform you that your NACH has bounced due to insufficient balance.</div>
        <br />
        <div>As per Negotiable Instruments Act, NACH bounce and overdue loans attract hefty Penalties and impact your credit score as well.</div>
        <br />
        <div>We have tried to get in touch with you to understand reason for default and tentative repayment date but have not got satisfactory response.</div>
        <br />
        <div>Get in touch with us on urgent basis.</div>
        <br />
        <div>Please deposit pending money ASAP.</div> */}
        <div>Your NACH has bounced due to insufficient balance. Bounce Charge & Overdue Penalty will be added to your Present Emi.</div>
        <div>Your case will be assigned to the Collection Department.</div>
        <br />
        {/* <div>Get in touch with us: <a href='tel:+918010700600'>+91-8010700600</a> or <a href='mailto:collections@credfin.money'>collections@credfin.money</a></div>
        <br /> */}
        <div>Best Regards,</div>
        <div>Team Credfin</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default ChequeBounced