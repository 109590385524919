import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { saveNbfcApi, SaveUpdateProcessingFee, SaveUpdateNbfcCustomerCriteria,UploadnbfcDocumentByNbfcName } from './actioncreatorNbfc';
import Popup from '../../../presentationals/Popup/Popup.component';
import Loader from '../../../presentationals/Loader/Loader.component'
const labelStyles = {
    fontSize: '12px',
    margin: '8px 0',
    fontWeight: '500'
}

const statusStyleeditActive = {
    border: '1px solid #4D84C7',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#4D84C7',
    margin: '5px',
    transition: 'background 0.3s',
};

const statusStyleedit = {
    border: '1px solid #CACACA',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '14px',
    // fontWeight: 'bold',
    margin: '5px',
    transition: 'background 0.3s',
};


const InputStyle = {
    marginBottom: '15px',
    fontSize: '14px',
}


const ProfileDetails = [
    { labelName: "NBFC ID", type: "text", value: "nbfcId", placeholder: 'NBFC ID', colType: 'col-sm-4' },
    { labelName: "Name", type: "text", value: "name", placeholder: 'Name', colType: 'col-sm-4' },
    { labelName: "POC Name", type: "text", value: "contactPersonName", placeholder: 'POC Name', colType: 'col-sm-4' },
    { labelName: "POC Contact Number", type: "number", value: "contactPersonNumber", placeholder: 'POC Contact Number', colType: 'col-sm-4' },
    { labelName: "POC Email", type: "email", value: "contactPersonEmail", placeholder: 'POC Email', colType: 'col-sm-4' },
    { labelName: "Registration Date", type: "date", value: "registrationDate", placeholder: "", colType: 'col-sm-4' },
    { labelName: "Licence No.", type: "text", value: "licenseNumber", placeholder: "Licence No.", colType: 'col-sm-4' },
    { labelName: "API Key", type: "text", value: "apiKey", placeholder: "API Key", colType: 'col-sm-4' },
]

const AddressDetails = [
    { labelName: "Address", type: "text", value: "address", placeholder: 'Address', colType: 'col-sm-4' },
    { labelName: "City", type: "text", value: "city", placeholder: 'City', colType: 'col-sm-4' },
    { labelName: "Pincode", type: "number", value: "pinCode", placeholder: 'Pincode', colType: 'col-sm-4' },
    { labelName: "State", type: "text", value: "state", placeholder: 'State', colType: 'col-sm-4' },

]

const FinancialDetails = [
    { labelName: "Min. Amount", type: "number", value: "minLoanAmount", placeholder: 'Min. Amount', colType: 'col-sm-4' },
    { labelName: "Max. Amount", type: "number", value: "maxLoanAmount", placeholder: 'Max. Amount', colType: 'col-sm-4' },
    { labelName: "Average Subvention", type: "number", value: "averageSubvention", placeholder: 'Average Subvention', colType: 'col-sm-4' },
    { labelName: "Interest Rate", type: "text", value: "interestRate", placeholder: 'Interest Rate', colType: 'col-sm-4' },
    { labelName: "Type Of Processing Fee", type: "SelectBox", value: "processingType", placeholder: 'Select', colType: 'col-sm-4' },
    { labelName: "Processing Fee", type: "number", value: "processingFee", placeholder: 'Processing Fee', colType: 'col-sm-4' },

]

const CustomerDetails = [
    { labelName: "Min. Age", type: "number", value: "minAge", placeholder: 'Min. Age', colType: 'col-sm-4' },
    { labelName: "Max. Age", type: "number", value: "maxAge", placeholder: 'Max. Age', colType: 'col-sm-4' },
    { labelName: "Min. Credit score", type: "number", value: "minCreditScore", placeholder: 'Min. Credit score', colType: 'col-sm-4' },
    { labelName: "Type Of Employment", type: "SelectBox", value: "employmentType", placeholder: '', colType: 'col-sm-4' },
    { labelName: "Min. Income", type: "number", value: "minIncome", placeholder: 'Min. Age', colType: 'col-sm-4' },
    { labelName: "Max. Income", type: "number", value: "maxIncome", placeholder: 'Max. Age', colType: 'col-sm-4' },


]
const LoanDetails = [
    { labelName: "Dynamic EMI Available", type: "number", value: "dynamicEmiValue", placeholder: 'Dynamic EMI Available', colType: 'col-sm-4' },
    { labelName: "Max. Days", type: "number", value: "maxDays", placeholder: 'Max. Days', colType: 'col-sm-4' },
    { labelName: "Max. Tenure(In Months)", type: "number", value: "maxMonth", placeholder: 'Max. Tenure(In Months)', colType: 'col-sm-4' },
    { labelName: "Obligation Check", type: "SelectBox", value: "obligationCheck", placeholder: '', colType: 'col-sm-4' },


]

const FileInputDetails = [
    "NBFC RBI License",
    "GST Certificate",
    "COI",
    "Cancelled Check ",
    "Authority PAN",
    "Authority Aadhar",
    "NBFC PAN",
    "Other Docs"
]

const ProcessingTypeData = [
    {
        name: "Fixed",
        value: 'fixed'
    },
    {
        name: "Interest",
        value: 'interest'
    },
]

const EmploymentTypeData = [
    {
        name: "Salaried",
        value: 'salaried'
    },
    {
        name: "Self-Employed",
        value: 'selfEmployed'
    },
]
const ObligationcheckData = [
    {
        name: "Yes",
        value: true
    },
    {
        name: "No",
        value: false
    },
]

export default function BasicConfiguration({ nbfcdata }) {
    const [nbfcDetail, setnbfcDetail] = useState("Profile")
    const [inputFields, setInputFields] = useState(ProfileDetails || [])
    const [data, setData] = useState({})
    const [SelectBoxData, setSelectBoxData] = useState([])
    const [Popup, setPopup] = useState({ show: false, popupStatus: "" })
    const [loader, setloader] = useState(false)

    useEffect(() => {
        if (nbfcDetail === "Profile") {
            setInputFields(ProfileDetails)
            setData(prevData => ({
                ...prevData,
                registrationDate: moment(new Date()).format("YYYY-MM-DD")
            }));
        } else if (nbfcDetail === "Address") {
            setInputFields(AddressDetails)
            setData({})
        } else if (nbfcDetail === "Financial Metrics") {
            setInputFields(FinancialDetails)
            setSelectBoxData(ProcessingTypeData)
            setData({})

        } else if (nbfcDetail === "Customer Criteria") {
            setInputFields(CustomerDetails)
            setSelectBoxData(EmploymentTypeData)
            setData({})
        } else if (nbfcDetail === "Loan Management") {
            setInputFields(LoanDetails)
            setSelectBoxData(ObligationcheckData)
            setData({})
        } else {
            setInputFields([])
            setSelectBoxData([])
            setData({})
        }
    }, [nbfcDetail])


    const handleClick = (type) => {
        setnbfcDetail(type)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (nbfcDetail === "Profile" && name === "contactPersonNumber") {
            if (value?.length > 10) {
                let newvalue = value?.slice(0, 10)
                setData(prevData => ({ ...prevData, "contactPersonNumber": newvalue }));
            } else {
                setData(prevData => ({ ...prevData, "contactPersonNumber": value }));
            }

        } else if (nbfcDetail === "Address" && name === "pinCode") {
            if (value?.length > 6) {
                let newvalue = value?.slice(0, 6)
                setData(prevData => ({ ...prevData, "pinCode": newvalue }));
            } else {
                setData(prevData => ({ ...prevData, "pinCode": value }));
            }
        } else {
            setData(prevData => ({ ...prevData, [name]: value }));
        }
    }

    const handleSave = (type) => {
        console.log("data", data)
        if (type === "Profile") {
            const obj = { ...data }
            obj.registrationDate = moment(obj.registrationDate).format("DD-MM-YYYY")
            if (!Object.keys(data)?.includes(nbfcId)) {
                obj.nbfcId = nbfcdata.nbfcId
            }
            setloader(true)
            saveNbfcApi(obj, (callback) => {
                setloader(false)
                if (callback === 'success') {
                    setPopup({ show: true, popupStatus: "success" })
                } else {
                    setPopup({ show: true, popupStatus: "failure" })
                }
                removePopup()
            })
        }
        else if (type === "Address") {
            const obj = { ...data, nbfcId: nbfcdata.nbfcId }
            saveNbfcApi(obj, (callback) => {
                setloader(false)
                if (callback === 'success') {
                    setPopup({ show: true, popupStatus: "success" })
                } else {
                    setPopup({ show: true, popupStatus: "failure" })
                }
                removePopup()
            })
        }
        else if (type === "Financial Metrics") {
            const obj = { ...data, nbfcId: nbfcdata.nbfcId }
            SaveUpdateProcessingFee(obj, (callback) => {
                setloader(false)
                if (callback === 'success') {
                    setPopup({ show: true, popupStatus: "success" })
                } else {
                    setPopup({ show: true, popupStatus: "failure" })
                }
                removePopup()
            })
        }
        else if (type === "Customer Criteria") {
            const obj = { ...data, nbfcId: nbfcdata.nbfcId }
            SaveUpdateNbfcCustomerCriteria(obj, (callback) => {
                setloader(false)
                if (callback === 'success') {
                    setPopup({ show: true, popupStatus: "success" })
                } else {
                    setPopup({ show: true, popupStatus: "failure" })
                }
                removePopup()
            })
        }
        else if (type === "Loan Management") {
            const obj = { ...data, nbfcId: nbfcdata.nbfcId }
            saveNbfcApi(obj, (callback) => {
                setloader(false)
                if (callback === 'success') {
                    setPopup({ show: true, popupStatus: "success" })
                } else {
                    setPopup({ show: true, popupStatus: "failure" })
                }
                removePopup()
            })
        }
    }

    const removePopup = () => {
        setTimeout(() => {
            closePopup()
        }, 4000)
    }

    const closePopup = () => {
        setPopup({ show: false, popupStatus: '' })
    }


    const handleUploadDoc = (e, docName) => {

        const file = e.target.files[0]
        let multipartFormData = new FormData();
        multipartFormData.append("file", file);
        multipartFormData.append("type", "pdf");
        multipartFormData.append("fileName", file?.name);
        UploadnbfcDocumentByNbfcName(nbfcdata?.nbfcName,docName,nbfcdata?.nbfcId,(callback)=>{
            console.log(callback)
            setloader(false)
            if (callback === 'success') {
                setPopup({ show: true, popupStatus: docName + " Uploaded" })
            } else {
                setPopup({ show: true, popupStatus: "failure" })
            }
            removePopup()
        })
    }


    return (
        <>
            {
                Popup?.show ?
                    <Popup
                        closePopup={closePopup()}
                        popupStatus={Popup.popupStatus}
                    />
                    : ''}
            {
                loader ? <Loader /> : ''
            }
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="box-outer"  >
                        <h3 className='text-center' style={{ margin: '10px 0 20px 0' }}>Update NBFC</h3>
                        <div className="lead-status-container" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap', marginBottom: '20px' }}>
                            <div className="lead-status-item" onClick={() => handleClick('Profile')} style={nbfcDetail === 'Profile' ? statusStyleeditActive : statusStyleedit}>
                                <span>Profile</span>
                            </div>
                            <div className="lead-status-item" onClick={() => handleClick('Address')} style={nbfcDetail === 'Address' ? statusStyleeditActive : statusStyleedit}>
                                <span>Address</span>
                            </div>
                            <div className="lead-status-item" onClick={() => handleClick('Financial Metrics')} style={nbfcDetail === 'Financial Metrics' ? statusStyleeditActive : statusStyleedit}>
                                <span>Financial Metrics</span>
                            </div>
                            <div className="lead-status-item" onClick={() => handleClick('Customer Criteria')} style={nbfcDetail === 'Customer Criteria' ? statusStyleeditActive : statusStyleedit}>
                                <span>Customer Criteria</span>
                            </div>
                            <div className="lead-status-item" onClick={() => handleClick('Loan Management')} style={nbfcDetail === 'Loan Management' ? statusStyleeditActive : statusStyleedit}>
                                <span>Loan Management</span>
                            </div>
                            <div className="lead-status-item" onClick={() => handleClick('Upload Document')} style={nbfcDetail === 'Upload Document' ? statusStyleeditActive : statusStyleedit}>
                                <span>Upload Document</span>
                            </div>

                        </div>

                        {
                            nbfcDetail === 'Upload Document' ?
                                <div className="row">
                                    {
                                        FileInputDetails?.map((value, index) => {
                                            return (
                                                <div className="col-sm-6 col-xs-12" key={index} >
                                                    <div className="FileinputGrps" >
                                                        <div className='subGrpclass' >
                                                            <input type='file' className='fileInputTag' id={value} onChange={(e) => handleUploadDoc(e, value)} accept='.pdf'/>
                                                            <span className='cloudicon'><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                        </div>
                                                        <label htmlFor={value}>{value}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div className="row" >
                                    {
                                        inputFields && inputFields?.length > 0 ?
                                            inputFields?.map((value, index) => {
                                                return (
                                                    <div className={`${value?.colType} col-xs-12`} key={index}>
                                                        <label htmlFor="" style={labelStyles}>{value?.labelName}</label>
                                                        {
                                                            value?.type === "SelectBox" ?
                                                                <select style={InputStyle} className='form-control' name={value?.value} value={data[value?.value] || ''} placeholder={value?.placeholder} onChange={(e) => handleChange(e)}>
                                                                    <option>Select</option>
                                                                    {
                                                                        SelectBoxData?.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={index} value={value.value}>{value.name}</option>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                : <input style={InputStyle} type={value?.type} className='form-control' name={value?.value} value={data[value?.value] || ''} placeholder={value?.placeholder} onChange={(e) => handleChange(e)} />
                                                        }
                                                    </div>
                                                )
                                            })
                                            : ''}
                                    <div className="col-xs-12" style={{ marginTop: '20px' }}>
                                        <div className='text-center'>
                                            <button className="btn btn-primary" style={{ width: '10%' }} onClick={() => handleSave(nbfcDetail)}>Save</button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
