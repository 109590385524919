import React, { useEffect, useState } from "react";
import ReplyIcon from '@mui/icons-material/Reply';
import ReactApexChart from "react-apexcharts";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Dashboard, Details, InfoOutlined, ListAltRounded, Money } from "@mui/icons-material";
import ConfigNbfc from "../NbfcManagementNew/ConfigurationMain.container";
import DebtListIndividual from "../DebtCollectionList/deptindividual";
import { List, ListItem } from "@mui/material";
import { getLoanStatusByNbfcIdApi, getNbfcDahsboardDataApi, getCustomerCriteriaBynbfcId, getProcessingFeeDetailsBynbfcId, getNbfcDocsUrlByNbfcId, getProcessingFeeCount, getLoanUtilityStatsBynbfcId, getLoanUtilityCountBynbfcId, getEnachConfigBynbfcId, getEnachConfifCountBynbfcId, getEsignConfigBynbfcId, getEsignConfigCountBynbfcId } from "./actioncreatorNbfc";
import moment from "moment";
import { APIS } from "../../../../utils/api-factory";
import Loader from '../../../presentationals/Loader/Loader.component'
// import { useDateField } from "@mui/x-date-pickers/DateField/useDateField";
import BasicConfiguration from "./BasicConfiguration";
const itemPerPage = 10;

const Individual = ({ setViewpage, admin, nbfcId }) => {
    const [loader, setloader] = useState(false)
    const [showZoom, setShowZoom] = useState(false)
    const [nbfcDetail, setnbfcDetail] = useState('Profile')
    const [productDetail, setProductDetail] = useState('Product')
    const [mainPage, setmainPage] = useState('list')
    const [showNpa, setshowNpa] = useState(false)
    const [page, setpage] = useState('dashboard')
    const [nbfcCountsData, setNbfcLoanCounts] = useState('')
    const [dashboardData, setDashboardData] = useState([])
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [pageValues, setPageValues] = useState({ count: 1, total: 1, buttonDisable: false })
    const [totalPagesALlSection, settotalPagesALlSection] = useState({ chargesSection: 0, ProductSection: 0, esignSection: 0, enachSection: 0 })
    const [TableData, setTableData] = useState([])
    const [nbfcDetailData, setNBFcDeatilData] = useState({
        PALDetails: {},
        CustomerDetails: {},
        nbfcDocs: {},
        ChargesDetails: [],
        ProductDetails: [],
        EnachDetails: [],
        EsignDetails: [],
    })

    const [DocPopup, setDocPopup] = useState({ show: false, name: '', url: '' })


    const handleClick = (data) => {
        setnbfcDetail(data)

    }
    const handleClickproduct = (data) => {
        const value = data === "Product" ? totalPagesALlSection?.ProductSection : data === "E-Nach" ? totalPagesALlSection?.enachSection : data === "E-Sign" ? totalPagesALlSection?.esignSection : data === "Charges" ? totalPagesALlSection?.chargesSection : 0;
        const tableValue = data === "Product" ? nbfcDetailData?.ProductDetails : data === "E-Nach" ? nbfcDetailData?.EnachDetails : data === "E-Sign" ? nbfcDetailData?.EsignDetails : data === "Charges" ? nbfcDetailData?.ChargesDetails : []
        setTableData(tableValue)
        setPageValues({ count: 1, total: value, buttonDisable: false })
        setProductDetail(data)

    }
    const handleClicklead = () => {

    }
    const statusStyle = {
        // background: '#f0f0f0',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '5px',
        transition: 'background 0.3s',
    };
    const statusStyleedit = {
        border: '1px solid #CACACA',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '14px',
        // fontWeight: 'bold',
        margin: '5px',
        transition: 'background 0.3s',
    };
    const statusStyleeditActive = {
        border: '1px solid #4D84C7',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#4D84C7',
        margin: '5px',
        transition: 'background 0.3s',
    };

    const monthShortNames = {
        January: "Jan",
        February: "Feb",
        March: "Mar",
        April: "Apr",
        May: "May",
        June: "Jun",
        July: "Jul",
        August: "Aug",
        September: "Sep",
        October: "Oct",
        November: "Nov",
        December: "Dec"
    };
    const leadsData = dashboardData?.data || [];
    const categories = Array.isArray(leadsData) && leadsData.length > 0
        ? leadsData.map(item => monthShortNames[item.month] || item.month)
        : [];

    const seriesData = Array.isArray(leadsData) && leadsData.length > 0
        ? leadsData.map(item => parseInt(item.totalLeads, 10))
        : [];
    var options = {
        series: [{
            name: 'Total Leads',
            data: seriesData
        }],
        chart: {
            height: 350,
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: categories,
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true
            }
        }
    };
    const pageHandler = (data) => {
        setpage(data)

    }
    const getloanStats = () => {
        setloader(true)
        getLoanStatusByNbfcIdApi(nbfcId?.nbfcId, callback => {
            console.log(callback)
            setloader(false)
            setNbfcLoanCounts(callback.data)
        })
    }
    const getdashboard = (startDate, endDate) => {
        setloader(true)
        getNbfcDahsboardDataApi(moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), nbfcId?.nbfcId, callback => {
            setDashboardData(callback.data)
            setloader(false)
        })
    }
    useEffect(() => {
        const oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        console.log(oneMonthAgo)
        setstartDate(oneMonthAgo);
        setendDate(today);
        getloanStats()
        getdashboard(oneMonthAgo, today)
    }, [])
    const dateHandler = (e, type) => {
        console.log(e.target.value)
        if (type === 'start') {
            setstartDate(e.target.value)
        }
        if (type === 'end') {
            setendDate(e.target.value)
        }


    }
    const applyfilter = () => {
        getdashboard(startDate, endDate)
    }

    useEffect(() => {
        if (page === "details") {
            getNbfcList()
            getCustomerCriteriaFn()

            getProcessingFeeDetailsBynbfcIdFunction(pageValues.count, false)
            getProcessingFeeCountFunction()

            getLoanUtilityStatsBynbfcIdFunction(pageValues.count, true)
            getLoanUtilityCountBynbfcIdFunction()

            getEnachConfigBynbfcIdFunction(pageValues.count, false)
            getEnachConfifCountBynbfcIdFunction()

            getEsignConfigBynbfcIdFunction(pageValues.count, false)
            getEsignConfigCountBynbfcIdFunction()

            getNbfcDocsUrlByNbfcIdFunction()

        }
    }, [page])


    const getNbfcList = () => {
        console.log("nbfcId", nbfcId)
        fetch(APIS.GET_LIST_OF_NBFC + nbfcId?.nbfcName)
            .then(res => res.json())
            .then(res => {
                console.log("res", res)
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    PALDetails: res[0]
                }))
            })
    }

    const getCustomerCriteriaFn = () => {
        setloader(true)
        getCustomerCriteriaBynbfcId(nbfcId?.nbfcId, (callback) => {
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    CustomerDetails: callback?.data
                }))
                setloader(false)
            }
        })
    }

    const getProcessingFeeDetailsBynbfcIdFunction = (current, setValueInTable) => {
        setloader(true)
        getProcessingFeeDetailsBynbfcId(nbfcId?.nbfcId, current, itemPerPage, (callback) => {
            setloader(false)
            console.log("call", callback)
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    ChargesDetails: callback?.data
                }))
                if (setValueInTable == true) {
                    setTableData(callback?.data)
                }
            }
        })
    }

    const getProcessingFeeCountFunction = () => {
        setloader(true)
        getProcessingFeeCount(nbfcId?.nbfcId, (callback) => {
            setloader(false)
            settotalPagesALlSection((prev) => ({
                ...prev,
                chargesSection: callback
            }))

        })
    }

    const getLoanUtilityStatsBynbfcIdFunction = (current, setValueInTable) => {
        setloader(true)
        getLoanUtilityStatsBynbfcId(nbfcId?.nbfcId, current, itemPerPage, (callback) => {
            console.log("call", callback)
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    ProductDetails: callback?.data
                }))
                if (setValueInTable == true) {
                    setTableData(callback?.data)
                    console.log("table")
                }
            }
            setloader(false)
        })
    }
    const getLoanUtilityCountBynbfcIdFunction = () => {
        setloader(true)
        getLoanUtilityCountBynbfcId(nbfcId?.nbfcId, (callback) => {
            settotalPagesALlSection((prev) => ({
                ...prev,
                ProductSection: callback
            }))
            setloader(false)
        })
    }

    const getEnachConfigBynbfcIdFunction = (current, setValueInTable) => {
        setloader(true)
        getEnachConfigBynbfcId(nbfcId?.nbfcId, current, itemPerPage, (callback) => {
            console.log("call", callback)
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    EnachDetails: callback?.data
                }))
                if (setValueInTable == true) {
                    setTableData(callback?.data)

                }
            }
            setloader(false)
        })
    }

    const getEnachConfifCountBynbfcIdFunction = () => {
        setloader(true)
        getEnachConfifCountBynbfcId(nbfcId?.nbfcId, (callback) => {
            settotalPagesALlSection((prev) => ({
                ...prev,
                enachSection: callback
            }))
            setloader(false)
        })
    }

    const getEsignConfigBynbfcIdFunction = (current, setValueInTable) => {
        setloader(true)
        getEsignConfigBynbfcId(nbfcId?.nbfcId, current, itemPerPage, (callback) => {
            console.log("call", callback)
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    EsignDetails: callback?.data
                }))
                if (setValueInTable == true) {
                    setTableData(callback?.data)

                }
            }
            setloader(false)
        })
    }

    const getEsignConfigCountBynbfcIdFunction = () => {
        setloader(true)
        getEsignConfigCountBynbfcId(nbfcId?.nbfcId, (callback) => {
            settotalPagesALlSection((prev) => ({
                ...prev,
                esignSection: callback
            }))
            setloader(false)
        })
    }

    const getNbfcDocsUrlByNbfcIdFunction = () => {
        setloader(true)
        getNbfcDocsUrlByNbfcId(nbfcId?.nbfcId, (callback) => {
            console.log("call", callback)
            if (callback?.message == "success" && callback?.status == 200) {
                setNBFcDeatilData((prev) => ({
                    ...prev,
                    nbfcDocs: callback?.data
                }))
            }
            setloader(false)
        })
    }


    const paginationHandler = (type, showType) => {
        const data = showType === "Product" ? totalPagesALlSection?.ProductSection : showType === "E-Nach" ? totalPagesALlSection?.enachSection : showType === "E-Sign" ? totalPagesALlSection?.esignSection : showType === "Charges" ? totalPagesALlSection?.chargesSection : 0
        if (type == 'left') {
            if (pageValues.count > 1) {

                setPageValues(prevState => {
                    const newState = {
                        ...prevState,
                        count: prevState.count - 1,
                        total: data,
                        buttonDisable: false
                    };
                    ApicallActiveSection(showType, (pageValues?.count - 1));
                    return newState;
                });

            } else {
                setPageValues(prevState => {
                    const newState = {
                        ...prevState,
                        count: prevState.count,
                        total: data,
                        buttonDisable: false
                    };

                    return newState;
                });
            }
        }
        if (type == 'right') {
            if (data >= 10 && pageValues?.count < Math.ceil(data / itemPerPage)) {
                setPageValues(prevState => {
                    const newState = {
                        ...prevState,
                        count: prevState.count + 1,
                        total: data,
                        buttonDisable: false
                    };
                    ApicallActiveSection(showType, (pageValues?.count + 1));
                    return newState;
                });
            } else {

                setPageValues(prevState => {
                    const newState = {
                        ...prevState,
                        count: prevState.count,
                        total: data,
                        buttonDisable: true
                    };

                    return newState;
                });
            }
        }
    }


    const ApicallActiveSection = (type, count) => {
        if (type == "Product") {
            getLoanUtilityStatsBynbfcIdFunction(count, true)
        } else if (type == "E-Nach") {
            getEnachConfigBynbfcIdFunction(count, true)
        } else if (type == "E-Sign") {
            getEsignConfigBynbfcIdFunction(count, true)
        } else if (type == "Charges") {
            getProcessingFeeDetailsBynbfcIdFunction(count, true)
        }
    }

    const handleViewDocs = (name, url) => {
        setDocPopup({
            show: true,
            name: name,
            url: url
        })
    }

    return (

        <>
            {
                loader ? <Loader /> : ''
            }
            {mainPage === 'list' ?
                <div className="container-fluid nbfc-dashboard details-new ">
                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => setViewpage('first')} >  <ReplyIcon /> Back To All NBFC</span>
                        </div>
                        <div className="col-sm-10 col-xs-10">
                            <div className="btn-group-new" style={{ marginLeft: 'auto', marginRight: '20px', float: 'right' }}>
                                <button className={page === 'dashboard' ? 'active' : ''} onClick={(e) => pageHandler('dashboard')}><Dashboard /><br />Dashboard</button>
                                <button className={page === 'list' ? 'active' : ''} onClick={(e) => pageHandler('list')}><ListAltRounded /><br />Listing</button>
                                <button className={page === 'details' ? 'active' : ''} onClick={(e) => pageHandler('details')}><Details /><br />Details</button>
                                <button className={page === 'npa' ? 'active' : ''} onClick={(e) => pageHandler('npa')}><Money /><br />NPA</button>


                            </div>
                        </div>
                        {/* <div className="col-sm-2 col-xs-12" style={{ textAlign: 'end' }}>
                            <button className="btn btn-primary" onClick={() => setshowNpa(true)} >
                                NPA Dashboard
                            </button>

                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            <input value={startDate} type="date" onChange={(e) => dateHandler(e, 'start')} className="form-control" />
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            <input value={endDate} type="date" onChange={(e) => dateHandler(e, 'end')} className="form-control " />
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            <button className="btn btn-primary" style={{ background: '#4D84C7', padding: '10px' }} onClick={() => applyfilter()}>Apply filters</button>
                        </div>
                    </div>
                    {page === 'dashboard' ?
                        <div className="row">
                            <div className="col-sm-7 col-xs-12">
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                            <p >Total Loans</p>
                                            <h3>{nbfcCountsData && nbfcCountsData.totalLoanApplications ? nbfcCountsData.totalLoanApplications : "0"}</h3>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                            <p >Approved Loan</p>
                                            <h3>{nbfcCountsData && nbfcCountsData.approvedLoan ? nbfcCountsData.approvedLoan : "0"}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                            <p >Disbursed Application</p>
                                            <h3>{nbfcCountsData && nbfcCountsData.loansDisbursed ? nbfcCountsData.loansDisbursed : "0"}</h3>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                            <p >Rejected Leads</p>
                                            <h3>{nbfcCountsData && nbfcCountsData.rejectedLeads ? nbfcCountsData.rejectedLeads : "0"}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 col-xs-12">
                                <div className="box-outer">
                                    <div style={{ display: 'flex', width: '100%' }}><div style={{ width: '15%', textAlign: 'center' }}>Total leads </div> <div style={{ width: '2px', background: '#92B7CC', height: '30px', marginTop: '-5px', marginLeft: '4px' }}></div> <div style={{ width: '20%', textAlign: 'center' }}>Trend scale :</div>
                                        <div style={{ width: '25%' }}>
                                            {/* <select style={{ padding: '4px', marginTop: '-5px' }} className="form-control">
                                                <option>month</option>
                                            </select> */}

                                        </div>
                                        <div style={{ width: '39%', textAlign: 'right' }}>
                                            <OpenInFullIcon onClick={e => setShowZoom(true)} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                    <ReactApexChart
                                        options={options}
                                        series={options.series}
                                        type="bar"
                                        height={270}
                                    />
                                </div>

                            </div>

                        </div>
                        : ""}

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            {page === 'list' ?
                                <div className="box-outer" style={{}} >
                                    <div className="lead-status-container" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <div className="lead-status-item" onClick={() => handleClicklead('New Leads')} style={statusStyle}>
                                            <span>New Leads</span> <span style={{ color: '#999999' }}>(45)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Paid Leads')} style={statusStyle}>
                                            <span>Paid Leads</span> <span style={{ color: '#999999' }}>(1,145)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Rejected Leads')} style={statusStyle}>
                                            <span>Rejected Leads</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Communicated')} style={statusStyle}>
                                            <span>Communicated</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Loan Under Process')} style={statusStyle}>
                                            <span>Loan Under Process</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Approved')} style={statusStyle}>
                                            <span>Approved</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('RFD/PFD')} style={statusStyle}>
                                            <span>RFD/PFD</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                        <div className="lead-status-item" onClick={() => handleClicklead('Disbursed')} style={statusStyle}>
                                            <span>Disbursed</span> <span style={{ color: '#999999' }}>(06)</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">

                                            <div className="dash-table">
                                                <table class="">
                                                    <thead>
                                                        <tr>
                                                            <th>Assign To</th>
                                                            <th>Details</th>
                                                            <th>View</th>
                                                            <th><i className="fa fa-star"></i></th>

                                                            <th>Nach</th>
                                                            <th>E-sign</th>
                                                            <th><i className="fa fa-whatsapp"></i></th>

                                                            <th>Application Id</th>
                                                            <th>Crif Decision</th>
                                                            <th>Loan ID</th>
                                                            <th>Name</th>
                                                            <th>State</th>
                                                            <th>Mobile No.</th>
                                                            <th>Apply Date</th>
                                                            <th>Status</th>
                                                            <th>Amount</th>
                                                            <th>Installment</th>
                                                            <th>Loan Form</th>
                                                            <th>Type</th>
                                                            <th>Device</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td> <InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>

                                                        </tr>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td><InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>
                                                        </tr>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td><InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>
                                                        </tr>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td><InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>
                                                        </tr>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td><InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>
                                                        </tr>
                                                        <tr>
                                                            <td>John Doe</td>
                                                            <td><InfoOutlined /></td>
                                                            <td>
                                                                <i className="fa fa-eye"></i>
                                                            </td>
                                                            <td><i className="fa fa-star"></i></td>
                                                            <td>Nach Info</td>
                                                            <td>E-sign Info</td>
                                                            <td><i className="fa fa-whatsapp"></i></td>
                                                            <td>App12345</td>
                                                            <td>Approved</td>
                                                            <td>Loan56789</td>
                                                            <td>Jane Smith</td>
                                                            <td>California</td>
                                                            <td>123-456-7890</td>
                                                            <td>2025-01-14</td>
                                                            <td>Pending</td>
                                                            <td>1000</td>
                                                            <td>200</td>
                                                            <td>PDF</td>
                                                            <td>Personal</td>
                                                            <td>Android</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                : ""}
                            {page === 'details' ?
                                <>
                                    <div className="row">
                                        <div className="com-sm-8 col-xs-8">
                                            <div className="box-outer" style={{ height: '313px', }}>
                                                <div className="row">
                                                    <div className="col-sm-10 col-xs-10">
                                                        <h4>NBFC details</h4>
                                                    </div>
                                                    <div className="col-sm-2 col-xs-2" style={{ textAlign: 'right' }}>
                                                        <button className="edit-btn" onClick={() => setmainPage('BasicConfig')}>Edit</button>
                                                    </div>
                                                </div>
                                                <div className="lead-status-container" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap' }}>
                                                    <div className="lead-status-item" onClick={() => handleClick('Profile')} style={nbfcDetail === 'Profile' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Profile</span>
                                                    </div>
                                                    <div className="lead-status-item" onClick={() => handleClick('Address')} style={nbfcDetail === 'Address' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Address</span>
                                                    </div>
                                                    {/* <div className="lead-status-item" onClick={() => handleClick('Financial Metrics')} style={nbfcDetail === 'Financial Metrics' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Financial Metrics</span>
                                                    </div> */}
                                                    <div className="lead-status-item" onClick={() => handleClick('Customer Criteria')} style={nbfcDetail === 'Customer Criteria' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Customer Criteria</span>
                                                    </div>
                                                    <div className="lead-status-item" onClick={() => handleClick('Loan Management')} style={nbfcDetail === 'Loan Management' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Loan Management</span>
                                                    </div>
                                                    <div className="lead-status-item" onClick={() => handleClick('Upload Document')} style={nbfcDetail === 'Upload Document' ? statusStyleeditActive : statusStyleedit}>
                                                        <span>Upload Document</span>
                                                    </div>

                                                </div>

                                                {nbfcDetail === 'Profile' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>NBFC ID<br />
                                                                <span>{nbfcDetailData?.PALDetails?.nbfcId || "-"}</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                Name<br />
                                                                <span>{nbfcDetailData?.PALDetails?.name || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>POC Name <br />
                                                                <span>{nbfcDetailData?.PALDetails?.contactPersonName || "-"}</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>POC Email <br />
                                                                <span>{nbfcDetailData?.PALDetails?.contactPersonEmail || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>POC Contact Number <br />
                                                                <span>{nbfcDetailData?.PALDetails?.contactPersonNumber || "-"}</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                {/* Logo <br />
                                                                <span><img src={nbfcDetailData?.PALDetails?.logoUrl || "-"} width={"100%"} alt="Logo"/></span> */}
                                                            </td>
                                                        </tr>


                                                    </table>
                                                    : ""}

                                                {nbfcDetail === 'Address' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Address<br />
                                                                <span>{nbfcDetailData?.PALDetails?.address || "-"}</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                City<br />
                                                                <span>{nbfcDetailData?.PALDetails?.city || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Pincode <br />
                                                                <span>{nbfcDetailData?.PALDetails?.pinCode || "-"}</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>State <br />
                                                                <span>{nbfcDetailData?.PALDetails?.state || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', padding: '17px', borderLeft: 'none' }}> <br />
                                                                <span></span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', padding: '17px', borderRight: 'none', borderLeft: 'none' }}> <br />
                                                                <span></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    : ""}
                                                {nbfcDetail === 'Financial Metrics' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Min. Amount<br />
                                                                <span>5000</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                Max. Amount<br />
                                                                <span>200000</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Avg. Subvention <br />
                                                                <span>8</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>Processing Fee <br />
                                                                <span>8%</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Type Of Processing Fee<br />
                                                                <span>flat</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>Interest Rate <br />
                                                                <span>8%</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    : ""}
                                                {nbfcDetail === 'Customer Criteria' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Min. Age<br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.minAge || "-"}</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                Max. Age<br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.maxAge || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Min. Credit Score <br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.minCreditScore || "-"}</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>Min. Income <br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.minIncome || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Max. Income<br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.maxIncome || "-"}</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>Type Of Employment <br />
                                                                <span>{nbfcDetailData?.CustomerDetails?.employmentType || "-"}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    : ""}
                                                {nbfcDetail === 'Loan Management' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Dynamic EMI<br />
                                                                <span> {nbfcDetailData?.PALDetails?.dynamicEmiValue || "-"}</span>
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
                                                                Max. Days<br />
                                                                <span>{nbfcDetailData?.PALDetails?.maxDays || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Max. Months <br />
                                                                <span>{nbfcDetailData?.PALDetails?.maxMonth || "-"}</span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>Obligation Check <br />
                                                                <span>{nbfcDetailData?.PALDetails?.obligationCheck == true ? "Yes" : "No" || "-"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', padding: '17px', borderLeft: 'none' }}> <br />
                                                                <span></span>

                                                            </td>
                                                            <td style={{ borderTop: 'none', padding: '17px', borderRight: 'none', borderLeft: 'none' }}> <br />
                                                                <span></span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    : ""}
                                                {nbfcDetail === 'Upload Document' ?
                                                    <table className="nbfc-table" >
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>NBFC RBI License<br />
                                                                {nbfcDetailData?.nbfcDocs?.nbfcRbiLicenseUrl !== null && nbfcDetailData?.nbfcDocs?.nbfcRbiLicenseUrl !== undefined && nbfcDetailData?.nbfcDocs?.nbfcRbiLicenseUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("NBFC RBI License", nbfcDetailData?.nbfcDocs?.nbfcRbiLicenseUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>
                                                                GST Certificate<br />
                                                                {nbfcDetailData?.nbfcDocs?.gstCertificateUrl !== null && nbfcDetailData?.nbfcDocs?.gstCertificateUrl !== undefined && nbfcDetailData?.nbfcDocs?.gstCertificateUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("GST Certificate", nbfcDetailData?.nbfcDocs?.gstCertificateUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>

                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>COI <br />
                                                                {nbfcDetailData?.nbfcDocs?.coiUrl !== null && nbfcDetailData?.nbfcDocs?.coiUrl !== undefined && nbfcDetailData?.nbfcDocs?.coiUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("COI", nbfcDetailData?.nbfcDocs?.coiUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', width: '25%' }}>Cancelled Check <br />
                                                                {nbfcDetailData?.nbfcDocs?.cancelledCheckUrl !== null && nbfcDetailData?.nbfcDocs?.cancelledCheckUrl !== undefined && nbfcDetailData?.nbfcDocs?.cancelledCheckUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("Cancelled Check", nbfcDetailData?.nbfcDocs?.cancelledCheckUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>Authority PAN<br />
                                                                {nbfcDetailData?.nbfcDocs?.authorityPanUrl !== null && nbfcDetailData?.nbfcDocs?.authorityPanUrl !== undefined && nbfcDetailData?.nbfcDocs?.authorityPanUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("Authority PAN", nbfcDetailData?.nbfcDocs?.authorityPanUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>
                                                                Authority Aadhar<br />
                                                                {nbfcDetailData?.nbfcDocs?.authorityAadharUrl !== null && nbfcDetailData?.nbfcDocs?.authorityAadharUrl !== undefined && nbfcDetailData?.nbfcDocs?.authorityAadharUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("Authority Aadhar", nbfcDetailData?.nbfcDocs?.authorityAadharUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>

                                                            <td style={{ borderTop: 'none', borderLeft: 'none', width: '25%' }}>NBFC PAN <br />
                                                                {nbfcDetailData?.nbfcDocs?.nbfcPanUrl !== null && nbfcDetailData?.nbfcDocs?.nbfcPanUrl !== undefined && nbfcDetailData?.nbfcDocs?.nbfcPanUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("NBFC PAN", nbfcDetailData?.nbfcDocs?.nbfcPanUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }

                                                            </td>
                                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', width: '25%' }}>Other Docs <br />
                                                                {nbfcDetailData?.nbfcDocs?.otherDocsUrl !== null && nbfcDetailData?.nbfcDocs?.otherDocsUrl !== undefined && nbfcDetailData?.nbfcDocs?.otherDocsUrl !== "" ?
                                                                    <span style={{ color: '#006c67', cursor: 'pointer' }} onClick={() => handleViewDocs("Other Docs", nbfcDetailData?.nbfcDocs?.otherDocsUrl)}>View</span>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                        </tr>

                                                    </table>
                                                    : ""}

                                            </div>

                                        </div>
                                        <div className="com-sm-4 col-xs-4">
                                            <div className="box-outer" style={{ height: '313px', overflow: 'auto' }}>
                                                <h4>Comments</h4>
                                                <textarea className="form-control" placeholder="Enter Comment" style={{ background: '#fff', border: '1px solid #CACACA' }} />
                                                <div style={{ textAlign: 'end' }}>
                                                    <button className="edit-btn" style={{ marginTop: '10px' }}>Post</button>
                                                </div>
                                                <div className="comment-recent">
                                                    Prateesh  added a new comment
                                                </div>
                                                <div className="comment-recent">
                                                    Prateesh  added a new comment
                                                </div>
                                                <div className="comment-recent">
                                                    Prateesh  added a new comment
                                                </div>
                                                <div className="comment-recent">
                                                    Prateesh  added a new comment
                                                </div>
                                                <div className="comment-recent">
                                                    Prateesh  added a new comment
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">

                                            <div className="box-outer">
                                                <div className="row">
                                                    <div className="col-sm-12 col-xs-12">

                                                        <div className="row">
                                                            <div className="col-sm-10 col-xs-10">
                                                                <h4>NBFC Configuration</h4>
                                                            </div>
                                                            <div className="col-sm-2 col-xs-2" style={{ textAlign: 'right' }}>
                                                                <button className="edit-btn" onClick={() => setmainPage('config')}>Edit</button>
                                                            </div>
                                                        </div>
                                                        <div className="lead-status-container" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap' }}>
                                                            <div className="lead-status-item" onClick={() => handleClickproduct('Product')} style={productDetail === 'Product' ? statusStyleeditActive : statusStyleedit}>
                                                                <span>Product </span>
                                                            </div>
                                                            <div className="lead-status-item" onClick={() => handleClickproduct('E-Nach')} style={productDetail === 'E-Nach' ? statusStyleeditActive : statusStyleedit}>
                                                                <span>E-Nach</span>
                                                            </div>
                                                            <div className="lead-status-item" onClick={() => handleClickproduct('E-Sign')} style={productDetail === 'E-Sign' ? statusStyleeditActive : statusStyleedit}>
                                                                <span>E-Sign</span>
                                                            </div>
                                                            <div className="lead-status-item" onClick={() => handleClickproduct('Charges')} style={productDetail === 'Charges' ? statusStyleeditActive : statusStyleedit}>
                                                                <span>Charges</span>
                                                            </div>


                                                        </div>

                                                        <div style={{ border: '1px solid #0000003B', padding: '15px', minHeight: '350px', marginTop: '20px', borderRadius: '10px' }}>
                                                            <h4 style={{ marginBottom: '15px' }}>{productDetail} Configuration</h4>

                                                            {TableData && TableData?.length > 0 ?
                                                                <div>
                                                                    <div className='overflow-auto'>
                                                                        <table className='phocket-table'>
                                                                            <thead>
                                                                                {productDetail === 'Product' ?
                                                                                    <tr>
                                                                                        <th>Cheque Bounce Charge</th>
                                                                                        <th>EMI Penalty Interest</th>
                                                                                        <th>Emi Type</th>
                                                                                        <th>GST Included</th>
                                                                                        <th>GST Interest</th>
                                                                                        <th>Interest Type</th>
                                                                                        <th>Mandate Bounce Charge</th>
                                                                                        <th>Normal Interest</th>
                                                                                        <th>Penalty Amount</th>
                                                                                        <th>Penalty Interest</th>
                                                                                        <th>Previously Affect</th>
                                                                                        <th>ROI Reflect Date</th>
                                                                                        <th>StampDuty Charge</th>
                                                                                    </tr>
                                                                                    : ''}
                                                                                {productDetail === 'E-Nach' ?
                                                                                    <tr>
                                                                                        <th>Api Key</th>
                                                                                        <th>Api Secret</th>

                                                                                    </tr>
                                                                                    : ''}

                                                                                {productDetail === 'E-Sign' ?
                                                                                    <tr>
                                                                                        <th>Api Key</th>
                                                                                        <th>App Id</th>
                                                                                        <th>Docket Info Url</th>
                                                                                        <th>E-Sign Request Url</th>
                                                                                        <th>Signature Status Url</th>
                                                                                        <th>status</th>
                                                                                        <th>Widget Token Url</th>

                                                                                    </tr>
                                                                                    : ''}
                                                                                {productDetail === 'Charges' ?
                                                                                    <tr>
                                                                                        <th>Effecting From</th>
                                                                                        <th>GST Included</th>
                                                                                        <th>MAX Loan Amount</th>
                                                                                        <th>MIN Loan Amount</th>
                                                                                        <th>Old Effected</th>
                                                                                        <th>Processing Fee</th>
                                                                                        <th> Processing Type</th>
                                                                                    </tr>
                                                                                    : ''}
                                                                            </thead>
                                                                            <tbody>
                                                                                {TableData?.map((nbfc, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            {productDetail === 'Product' ?
                                                                                                <tr key={i}>
                                                                                                    <td>{nbfc.chequeBounceCharge}</td>
                                                                                                    <td>{nbfc.emiPenaltyInterest}</td>
                                                                                                    <td>{nbfc.emiType}</td>
                                                                                                    <td>{nbfc.gstIncluded === false ? 'No' : nbfc.gstIncluded === true ? 'Yes' : ""}</td>
                                                                                                    <td>{nbfc.gstInterest}</td>
                                                                                                    <td>{nbfc.interestType}</td>
                                                                                                    <td>{nbfc.mandateBounceCharge}</td>
                                                                                                    <td>{nbfc.normalInterest}</td>
                                                                                                    <td>{nbfc.penaltyAmount}</td>
                                                                                                    <td>{nbfc.penaltyInterest}</td>
                                                                                                    <td>{nbfc.previouslyAffect === false ? 'No' : nbfc.previouslyAffect === true ? 'Yes' : ""}</td>
                                                                                                    <td>{nbfc.roiReflectDate}</td>
                                                                                                    <td>{nbfc.stampDutyCharge}</td>


                                                                                                </tr>
                                                                                                : ''}
                                                                                            {productDetail === 'E-Nach' ?
                                                                                                <tr key={i}>
                                                                                                    <td>{nbfc.apiKey}</td>
                                                                                                    <td>{nbfc.apiSecret}</td>

                                                                                                </tr>
                                                                                                : ''}

                                                                                            {productDetail === 'E-Sign' ?
                                                                                                <tr key={i}>
                                                                                                    <td>{nbfc.apiKey}</td>
                                                                                                    <td>{nbfc.appId}</td>
                                                                                                    <td>{nbfc.docketInfoUrl}</td>
                                                                                                    <td>{nbfc.eSignRequestUrl}</td>
                                                                                                    <td>{nbfc.signatureStatusUrl}</td>
                                                                                                    <td>{nbfc.status === true ? 'Active' : 'Inactive'}</td>
                                                                                                    <td>{nbfc.widgetTokenUrl}</td>

                                                                                                </tr>
                                                                                                : ''}
                                                                                            {productDetail === 'Charges' ?
                                                                                                <tr key={i}>
                                                                                                    <td>{nbfc.effectingFrom}</td>
                                                                                                    <td>{nbfc.gstIncluded === false ? 'No' : nbfc.gstIncluded === true ? 'Yes' : ""}</td>
                                                                                                    <td>{nbfc.maxLoanAmount}</td>
                                                                                                    <td>{nbfc.minLoanAmount}</td>
                                                                                                    <td>{nbfc.oldEffected === false ? 'No' : nbfc.oldEffected === true ? 'Yes' : ""}</td>
                                                                                                    <td>{nbfc.processingFee}</td>
                                                                                                    <td>{nbfc.processingType}</td>
                                                                                                </tr>
                                                                                                : ''}
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left', productDetail)}><span className="fa fa-chevron-left" /></button></div>
                                                                            <div className="col-sm-2 col-xs-12  phoc text-center" >Total: {TableData?.length}&nbsp; of &nbsp; {pageValues?.total}</div>
                                                                            <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" disabled={pageValues?.buttonDisable} className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right', productDetail)}><span className="fa fa-chevron-right" /></button></div> </div>
                                                                    </div>
                                                                </div>
                                                                : ""}

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                    </div>
                                </>
                                : ""}
                        </div>
                    </div>

                    {showZoom ?
                        <>
                            <div className="background-blur" onClick={e => setShowZoom(false)}></div>
                            <div className="popup-main small-popup container-fluid " style={{ width: '80%' }}>
                                <h3 className="blue-text text-center"></h3>
                                <div className="row form-group">
                                    <div style={{ display: 'flex', width: '100%' }}><div style={{ width: '15%', textAlign: 'center' }}>Total leads </div> <div style={{ width: '2px', background: '#92B7CC', height: '30px', marginTop: '-5px', marginLeft: '10px' }}></div> <div style={{ width: '20%', textAlign: 'center' }}>Trend scale :</div>
                                        <div style={{ width: '25%' }}>
                                            <select style={{ padding: '4px', marginTop: '-5px' }} className="form-control">
                                                <option>month</option>
                                            </select>

                                        </div>

                                    </div>
                                    <ReactApexChart
                                        options={options}
                                        series={options.series}
                                        type="bar"
                                        height={400}
                                    />
                                </div>
                            </div>
                        </>
                        : ""}

                    {
                        DocPopup?.show == true ?
                            <>
                                <div className="background-blur" onClick={e => setDocPopup({ show: false, name: "", url: "" })}></div>
                                <div className="popup-main small-popup container-fluid " style={{ width: '80%' }}>

                                    <div className="row form-group">
                                        <div style={{ width: '100%' }}>
                                            <a className="text-center" href={DocPopup?.url} target="_blank" style={{ display: 'block', fontSize: '20px' }}>{DocPopup?.name || ""}</a>

                                            <div style={{ marginTop: '20px' }}>
                                                <iframe src={DocPopup?.url} style={{ height: '500px', width: '100%' }}></iframe>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                            : ''}

                </div>
                : ""}
            {page === 'npa' ?
                <>
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-10 col-xs-12" style={{}}>
                            </div>
                            <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <button className="btn btn-primary" style={{ background: '#006c67' }} onClick={() => setshowNpa(false)} >
                                    Close NPA
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <DebtListIndividual admin={admin} />
                </>
                : ""}
            {mainPage === 'config' ?
                <>
                    <div className="container-fluid">
                        <span style={{ cursor: 'pointer' }} onClick={() => setmainPage('list')} >  <ReplyIcon /> Back To Details</span>
                        <ConfigNbfc />
                    </div>
                </>
                : ""}

            {mainPage === 'BasicConfig' ?
                <>
                    <div className="container-fluid nbfc-dashboard details-new">
                        <span style={{ cursor: 'pointer' }} onClick={() => setmainPage('list')} >  <ReplyIcon /> Back To Details</span>
                        <BasicConfiguration nbfcdata={nbfcId} />
                    </div>
                </>
                : ""}
        </>

    )
}
export default Individual