import React, { useState } from "react";
import { lastDateOfMonth, monthStartDate, reverseFormat, todayDate } from "../maketingComponents/utils/utils";
import DownArrow from '../maketingimages/arrow (2).png'
import arrowDown from './imagesmarket/arrow-down.png';
import arrowup from './imagesmarket/arrow-up.png';
import { useEffect } from "react";
import { projectionData, projectionDataDaywise } from "../actioncreatormarketing";
import { getCountSatusWise } from "../../Admin/AdminActionCreator.component";
import moment from "moment";
const Projections = ({ }) => {
    const [fromDate, setfromDate] = useState(monthStartDate())
    const [toDate, settoDate] = useState(todayDate())
    const [filterType, setfilterType] = useState(3)
    const [apiData, setApiData] = useState([])
    const [noOfMonth, setnoOfMonth] = useState(3)
    const [openAccordion, setOpenAccordion] = useState(null);
    const [dayWiseApiData, setdayWiseData] = useState('');
    const [countData, setcountData] = useState('');
    const [dateType, setdateType] = useState('disbursedate');
    const [startDateDDMMYYYY, setStartDateDDMMYYYY] = useState('');
    const [endDateDDMMYYYY, setEndDateDDMMYYYY] = useState('');
    const [startDateYYYYMMDD, setStartDateYYYYMMDD] = useState('');
    const [endDateYYYYMMDD, setEndDateYYYYMMDD] = useState('');

  
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const dayWiseData = (month) => {
        projectionDataDaywise(month, fromDate, toDate, callback => {

            setdayWiseData(callback)
        })
    }


    const handleChange = (e, type) => {
        // getData(data)
        if (type === 'fromDate') {
            setfromDate(reverseFormat(e.target.value))
        }
        if (type === 'toDate') {
            settoDate(reverseFormat(e.target.value))
        }
        console.log(e.target.value)


    }
    const dataFromAPI = [
        { month: 'Jan,2024', value: 1000, achievement: '800', achievementPercentage: '200' },
        { month: 'Feb,2024', value: 1000, achievement: '800', achievementPercentage: '200' },
        { month: 'Feb,2024 (Achieved)', value: '₹ 60,000', achievement: '₹ 60,000', achievementPercentage: '10' },

    ];
    const dataFromAPI2 = [
        { month: 'Jan,2024', value: 1000, achievement: 800, achievementPercentage: '+200%' },
        { month: 'Feb,2024', value: 1000, achievement: 800, achievementPercentage: '+200%' },
        { month: 'Feb,2024 (Achieved)', value: 1000, achievement: 800, achievementPercentage: '+200%' },

    ];
    useEffect(() => {
        getData(noOfMonth)
        dayWiseData(noOfMonth)
        getCount()
    }, [])
    const getCount = () => {
        getCountSatusWise(fromDate, toDate, dateType, callBack => {
            console.log(callBack)
            setcountData(callBack)
        })
    }
    // const getData = (month) => {
    //     setnoOfMonth(month)
    //     projectionData(month,fromDate, toDate, callback => {
    //         setApiData(callback)

    //     })
    //     dayWiseData(month)
    // }
    const getData = (month) => {
        setnoOfMonth(month);
        projectionData(month, fromDate, toDate, callback => {
            // Check if callback is an array containing only empty objects
            if (Array.isArray(callback) && callback.every(obj => isEmptyObject(obj))) {
                // If all objects in the array are empty, don't update state
                console.log("Array contains only empty objects. Not updating state.");
            } else {
                // Otherwise, update the state with the callback data
                setApiData(callback);
            }
        });
        dayWiseData(month);
    };

    const buttonHandler = (data) => {
        getData(data)
        setfilterType(data)
        handleFilterDate(data)


    }
    const SubmitHandler = () => {
        getData(noOfMonth)
        dayWiseData(noOfMonth)
        getCount()
    }
    const resetFilter = () => {
        setfromDate(monthStartDate())
        settoDate(todayDate())
        setfilterType(3)
        projectionData(3, monthStartDate(), todayDate(), callback => {
            setApiData(callback)

        })
        projectionDataDaywise(3, monthStartDate(), todayDate(), callback => {

            setdayWiseData(callback)
        })
        getCountSatusWise(monthStartDate(), todayDate(), dateType, callBack => {
            console.log(callBack)
            setcountData(callBack)
        })
    }
    const handleFilterDate = (type) => {
        console.log(type)
        let startDateDDMMYYYY, endDateDDMMYYYY;
        let startDateYYYYMMDD, endDateYYYYMMDD;
    
        switch(type) {
            case 1:
                startDateDDMMYYYY = moment().subtract(1, 'months').startOf('month').format('DD-MM-YYYY');
                endDateDDMMYYYY = moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
                startDateYYYYMMDD = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                endDateYYYYMMDD = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case 2:
                startDateDDMMYYYY = moment().subtract(2, 'months').startOf('month').format('DD-MM-YYYY');
                endDateDDMMYYYY = moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
                startDateYYYYMMDD = moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
                endDateYYYYMMDD = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case 3:
                startDateDDMMYYYY = moment().subtract(3, 'months').startOf('month').format('DD-MM-YYYY');
                endDateDDMMYYYY = moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
                startDateYYYYMMDD = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
                endDateYYYYMMDD = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case 6:
                startDateDDMMYYYY = moment().subtract(6, 'months').startOf('month').format('DD-MM-YYYY');
                endDateDDMMYYYY = moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
                startDateYYYYMMDD = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
                endDateYYYYMMDD = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            default:
                return;
        }
    
        setStartDateDDMMYYYY(startDateDDMMYYYY);
        setEndDateDDMMYYYY(endDateDDMMYYYY);
        setStartDateYYYYMMDD(startDateYYYYMMDD);
        setEndDateYYYYMMDD(endDateYYYYMMDD);
    
        console.log(startDateDDMMYYYY, endDateDDMMYYYY, startDateYYYYMMDD, endDateYYYYMMDD);
        getCountSatusWise(startDateDDMMYYYY, endDateDDMMYYYY, dateType, callBack => {
            console.log(callBack)
            setcountData(callBack)
        })
        setfromDate(startDateDDMMYYYY)
        settoDate(endDateDDMMYYYY)

    };
    return (
        <div className="projection-new">
            <div className="container" style={{ padding: '20px 50px' }}>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                        From Date
                        <input type="date" id="startDate" placeholder="DD/MM/YY" name="fromDate" value={reverseFormat(fromDate)} onChange={(e) => handleChange(e, 'fromDate')} className=""
                            style={{ border: "1px solid gray", borderRadius: "6px", padding: "10px", background: '#fff', width: '100%' }}
                        />
                    </div>
                    <div className="col-sm-2 col-xs-12">
                        <div className="filterHeaders" style={{ marginTop: "5.5px" }}>
                            To Date
                        </div>

                        <input type="date" id="endDate" name="toDate" value={reverseFormat(toDate)} onChange={(e) => handleChange(e, 'toDate')} placeholder="DD/MM/YY" className="" style={{ border: "1px solid gray", borderRadius: "6px", padding: "10px", background: '#fff', width: '100%' }}
                        />
                    </div>

                </div>

                <h4 style={{ marginTop: '20px' }}><b><i className="fa fa-sliders" aria-hidden="true"></i>&nbsp;&nbsp;Month Filter</b> </h4>
                <div className="" style={{ display: 'flex' }}>
                    <div  >
                        <button onClick={() => buttonHandler(1)} style={{ background: filterType === 1 ? '#dee8fd' : 'transparent', width: '145px', color: filterType === 1 ? '#006c67' : '#00000040', padding: '10px', border: '1px solid #00000040', borderRadius: '6px' }}><i className="fa fa-calendar-o" aria-hidden="true"></i>&nbsp;&nbsp;last month</button>
                    </div>
                    <div >
                        <button onClick={() => buttonHandler(2)} style={{ background: filterType === 2 ? '#dee8fd' : 'transparent', width: '145px', marginLeft: '10px', color: filterType === 2 ? '#006c67' : '#00000040', padding: '10px', border: '1px solid #00000040', borderRadius: '6px' }}><i className="fa fa-calendar-o" aria-hidden="true"></i>&nbsp;&nbsp;last 2 months</button>

                    </div>
                    <div >
                        <button onClick={() => buttonHandler(3)} style={{ background: filterType === 3 ? '#dee8fd' : 'transparent', width: '145px', marginLeft: '10px', color: filterType === 3 ? '#006c67' : '#00000040', padding: '10px', border: '1px solid #00000040', borderRadius: '6px' }}><i className="fa fa-calendar-o" aria-hidden="true"></i>&nbsp;&nbsp;last 3 months</button>

                    </div>
                    <div >
                        <button onClick={() => buttonHandler(6)} style={{ background: filterType === 6 ? '#dee8fd' : 'transparent', width: '145px', marginLeft: '10px', color: filterType === 6 ? '#006c67' : '#00000040', padding: '10px', border: '1px solid #00000040', borderRadius: '6px' }}><i className="fa fa-calendar-o" aria-hidden="true"></i>&nbsp;&nbsp;last 6 months</button>

                    </div>
                    <div >
                        <button style={{ width: '145px', marginLeft: '10px', background: '#006c67', padding: '10px', color: '#fff', borderRadius: '6px'  }}onClick={()=>SubmitHandler()}>Submit</button>

                    </div>
                    <div >
                        <button style={{ width: '145px', marginLeft: '10px', background: '#fff', padding: '10px', border: '1px solid #00000040', borderRadius: '6px' }} onClick={() => resetFilter()}><i className="fa fa-repeat"></i>&nbsp;&nbsp;Reset</button>
                    </div>
                </div>
                {countData !== '' ?
                <>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Total Application Count<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}> {countData.totalApplications}</h3>
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Repeat Leads Count<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}> {countData.repeatLeads}</h3>
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Fresh Leads Count<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}> {countData.freshLeads}</h3>

                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Fresh Disbursement Amount<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.freshDisbursement).toFixed(2)).toLocaleString('en-IN')} </h3>
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Repeat Disbursement Amount<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.repeatDisbursement).toFixed(2)).toLocaleString('en-IN')}</h3>

                            </div>
                        </div>

                        <div className='col-sm-4 col-xs-12'>
                            <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                                Total Disbursement Amount<br />
                                <h3 style={{ color: 'grey', fontWeight: '700' }}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(countData.totalDisbursement).toFixed(2)).toLocaleString('en-IN')}</h3>
                            </div>
                        </div>

                    </div>
                    </>
                    : ""}




                <div className=" Projection " style={{ marginTop: '40px' }}>
                    <div className="">
                        {apiData.length > 0 ?
                            <div className="accords openaccord">
                                <h4 className="text-center">Cumulative Comparison &amp; Projection</h4>
                                <div className="heading table-responsive">

                                    <table class="table text-center tableheader">
                                        <tbody>

                                            <tr className='trhead'>
                                                <td style={{ width: '20%' }}>Date</td>
                                                {console.log(apiData, 'apidata')}
                                                {apiData.length > 0 && apiData?.map((data, index) => (
                                                    <td key={index}>{data.date.value}</td>
                                                ))}
                                            </tr>
                                            <tr className='tr1'>
                                                <td className='td1'>Leads</td>
                                                {apiData.length > 0 && apiData?.map((data, index) => (

                                                    <td key={index}>{data.totalLeads.value}</td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td className='td1'>Disbursed Amount</td>
                                                {apiData.length > 0 && apiData?.map((data, index) => (

                                                    <td key={index}>{data.disburseAmount.value}</td>
                                                ))}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="bottomtable">
                                    <div className='subbottom table-responsive'>
                                        <table class="table text-center tablebottom">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="BTLR Leftcolborder">Lead Particulars</th>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <th key={index}>{data.date.value}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td className="Leftcolborder">Lead to Fresh Approval Ratio</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.leadToRepeatRejectionRatio.value}%</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Lead to Fresh Disbursal Ratio</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.leadToFreshDisbursalRatio.value}%</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Lead to Fresh Rejection Ratio</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.leadToFreshRejectionRatio.value}%</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">New Applications</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (

                                                        <td key={index}>{data.freshLeads.value}</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Repeat Applications</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.repeatLeads.value}</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Approved Applications</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.approvedLeads.value}</td>
                                                    ))}

                                                </tr>

                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='Leftcolborder'>CAC</th>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <th key={index}></th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="Leftcolborder">Lead to Repeat Approval Ratio</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.leadToRepeatApprovalRatio.value}%</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Lead to Repeat Disbursal Ratio</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.leadToRepeatDisbursalRatio.value}%</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder">Total Disbursed Leads</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.disburseLeads.value}</td>
                                                    ))}

                                                </tr>
                                                <tr>
                                                    <td className="Leftcolborder BBLR">Total Disbursed Amount</td>
                                                    {apiData.length > 0 && apiData?.map((data, index) => (
                                                        <td key={index}>{data.disburseAmount.value}</td>
                                                    ))}

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                            : ""}

                        <div className="accords openaccord" style={{ background: '#fff' }}>
                            <h4 className="text-center">Daywise Comparison &amp; Projection</h4>
                            <div className="">
                                {dayWiseApiData.length > 0 ? dayWiseApiData.map((item, index) => (
                                    <AccordionItem
                                        key={index} // Using index as a unique key
                                        id={index} // Passing index as id
                                        isOpen={openAccordion === index}
                                        toggleAccordion={toggleAccordion}
                                        item={item} // Pass the entire item object as prop
                                        dayWiseApiData={dayWiseApiData}
                                    />
                                )) : ""}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projections

function AccordionItem({ id, isOpen, toggleAccordion, dayWiseApiData }) {
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        <img style={{ width: '60%', marginTop: '-10px' }} src={isOpen ? arrowup : arrowDown} alt="" />&nbsp;
                        {/* {id} */}
                    </h5>
                </div>
                <table class="table text-center tableheader">
                    <tbody>
                        <tr>
                            <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Date</td>

                            {dayWiseApiData[id]?.map((data, index) => (
                                <th key={index}>{data.date.value}</th>
                            ))}
                        </tr>
                        <tr className='tr1'>
                            <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none', borderTop: '1px solid #00000040', borderBottom: '1px solid #00000040' }}>Applications</td>
                            {dayWiseApiData[id]?.map((data, index) => (
                                <td key={index}>{data.totalLeads.value}</td>
                            ))}

                        </tr>
                        <tr>
                            <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Disbursed Amount</td>
                            {dayWiseApiData[id]?.map((data, index) => (
                                <td key={index}>{data.disburseAmount.value}</td>
                            ))}
                            {/* <td>₹ 60,000</td>
                            <td>₹ 60,000</td>
                            <td className='td41 bg-positive BBTR'>₹ 60,000 &nbsp;
                                <div className="Positivebox">
                                    +10000%
                                </div>
                            </td> */}

                        </tr>

                    </tbody>
                </table>
                {/* {dayWiseApiData && dayWiseApiData.map((data, i) => (
                 data.map((yh,i)=>{
                    // console.log(yh.totalLeads.value)
                    <td>1000</td>
                 })
                ))} */}

            </div>
            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`}>
                        <table class="table text-center tablebottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="BTLR Leftcolborder">Lead Particulars</th>
                                    {/* <th scope="col">Jan, 2024</th>
                                    <th scope="col">Feb, 2024 (Projected)</th>
                                    <th scope="col" className='BTTR'>Feb, 2024 (Acheived)</th> */}
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <th key={index}>{data?.date?.value}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="Leftcolborder">Lead to Fresh Approval Ratio</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.leadToRepeatRejectionRatio?.value}%</td>
                                    ))}
                                    {/* <td>23.71%</td>
                                    <td>23.71%</td>
                                    <td className="td41 bg-positive">23.71% &nbsp;
                                        <div className="Positivebox">
                                            +200%
                                        </div>

                                    </td> */}
                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Lead to Fresh Disbursal Ratio</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.leadToFreshDisbursalRatio?.value}%</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Lead to Fresh Rejection Ratio</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.leadToFreshRejectionRatio?.value}%</td>
                                    ))}

                                </tr>
                                <tr>
                                    <td className="Leftcolborder">New Applications</td>
                                    {dayWiseApiData[id]?.map((data, index) => (

                                        <td key={index}>{data?.freshLeads?.value}</td>
                                    ))}

                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Repeat Applications</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.repeatLeads?.value}</td>
                                    ))}

                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Approved Applications</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.approvedLeads?.value}</td>
                                    ))}

                                </tr>

                                {/* <tr>
                                    <td className="Leftcolborder">Lead to Fresh Disbursal Ratio</td>
                                    <td>23.71%</td>
                                    <td>23.71%</td>
                                    <td className="td41 bg-negative">23.71% &nbsp;
                                        <div className="Negativebox">
                                            -200%
                                        </div></td>
                                </tr> */}

                            </tbody>
                            <thead>
                                <tr>
                                    <th scope="col" className='Leftcolborder'>CAC</th>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <th key={index}></th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="Leftcolborder">Lead to Repeat Approval Ratio</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.leadToRepeatApprovalRatio?.value}%</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Lead to Repeat Disbursal Ratio</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.leadToRepeatDisbursalRatio?.value}%</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="Leftcolborder">Total Disbursed Leads</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.disburseLeads?.value}</td>
                                    ))}
                                </tr>
                                {/* <tr>
                                    <td className="Leftcolborder BBLR">Total Amount</td>
                                    <td>23.71%</td>
                                    <td>23.71%</td>
                                    <td className="td41 bg-negative BBTR">
                                        23.71% &nbsp;
                                        <div className="Negativebox">
                                            -200%
                                        </div></td>
                                </tr> */}

                                <tr>
                                    <td className="Leftcolborder BBLR">Total Disbursed Amount</td>
                                    {dayWiseApiData[id]?.map((data, index) => (
                                        <td key={index}>{data?.disburseAmount?.value}</td>
                                    ))}

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}