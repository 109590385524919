import React, { useState,useCallback } from 'react'
// import { HeaderType, MSG_STRING, TemplateId } from '../../../../../presentationals/Admin/BulkActions/MessageTemplates.component'
import {MSG_CONTENT,TemplateIdPromotional,TemplateType} from  '../../../../../../utils/MessageConstant';
import { statusWiseCommunicationMarketSms } from '../../actioncreator'

const Smspage = ({ admin,userIdList,payload, communicateViaFilter, status }) => {
    const [msgBody, setMsgBody] = useState('')
    const [templateId, settemplateIdTosend] = useState('')
    const [msgTypeHeader, setmessageType] = useState('')
    const [typeList, setTypeList] = useState(["Message"])
    const [mailBody, setmailBody] = useState('')
    const [imageUrl, setimageUrl] = useState('')
    const [subject, setsubject] = useState('')
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // const [userIdList, setuserIdList] = useState([])

    const getBranchCommunicateForBreakDown = () => {
        // statusWiseCommunicationMarketSms(typeList, msgBody, subject, admin.emailId, imageUrl, userIdList, templateId, msgTypeHeader,payload, communicateViaFilter, status, callback => {

        // })
        

    };
    
    const sendHandlerMsg = (type) => {
        getBranchCommunicateForBreakDown()
    }
    const smsTemplateHandle = (e) => {
        console.log(e)
        if (e.target.value === '') {
            setMsgBody('')
            settemplateIdTosend('')
            setmessageType('')
        }
        if (e.target.value == 'EASY_LOANS') {
            setMsgBody(MSG_CONTENT.EASY_LOANS)
            settemplateIdTosend(TemplateIdPromotional.EASY_LOANS)
            setmessageType(TemplateType.EASY_LOANS)
        }

        if (e.target.value == 'SAY_GOODBYE') {
            setMsgBody(MSG_CONTENT.SAY_GOODBYE)
            settemplateIdTosend(TemplateIdPromotional.SAY_GOODBYE)
            setmessageType(TemplateType.SAY_GOODBYE)
        }
        if (e.target.value == 'ENJOYING_CREDFIN_FEEDBACK') {
            setMsgBody(MSG_CONTENT.ENJOYING_CREDFIN_FEEDBACK)
            settemplateIdTosend(TemplateIdPromotional.ENJOYING_CREDFIN_FEEDBACK)
            setmessageType(TemplateType.ENJOYING_CREDFIN_FEEDBACK)
        }
        if (e.target.value == 'PRE_APPROVED_LOANS') {
            setMsgBody(MSG_CONTENT.PRE_APPROVED_LOANS)
            settemplateIdTosend(TemplateIdPromotional.PRE_APPROVED_LOANS)
            setmessageType(TemplateType.PRE_APPROVED_LOANS)
        }
        if (e.target.value == 'FINANCIAL_SOLUTION') {
            setMsgBody(MSG_CONTENT.FINANCIAL_SOLUTION)
            settemplateIdTosend(TemplateIdPromotional.FINANCIAL_SOLUTION)
            setmessageType(TemplateType.FINANCIAL_SOLUTION)
        }
        if (e.target.value == 'NEED_PERSONAL_LOAN') {
            setMsgBody(MSG_CONTENT.NEED_PERSONAL_LOAN)
            settemplateIdTosend(TemplateIdPromotional.NEED_PERSONAL_LOAN)
            setmessageType(TemplateType.NEED_PERSONAL_LOAN)
        }
        if (e.target.value == 'URGENT_FUNDS') {
            setMsgBody(MSG_CONTENT.URGENT_FUNDS)
            settemplateIdTosend(TemplateIdPromotional.URGENT_FUNDS)
            setmessageType(TemplateType.URGENT_FUNDS)
        }
        if (e.target.value == 'PAYDAY_BILLS') {
            setMsgBody(MSG_CONTENT.PAYDAY_BILLS)
            settemplateIdTosend(TemplateIdPromotional.PAYDAY_BILLS)
            setmessageType(TemplateType.PAYDAY_BILLS)
        }
        if (e.target.value == 'INSTANT_APPROVAL') {
            setMsgBody(MSG_CONTENT.INSTANT_APPROVAL)
            settemplateIdTosend(TemplateIdPromotional.INSTANT_APPROVAL)
            setmessageType(TemplateType.INSTANT_APPROVAL)
        }
       
    }
    return (
        <div className=' marketing-main-css '>
            <div className='row'>
                <div className='col-sm-9'>
                    <div className='cards-shadow ' style={{ padding: '15px', marginTop: '20px' }}>
                        <label className="form-label">Select SMS Template </label>
                        <select className="form-select" onChange={(e) => smsTemplateHandle(e)} >
                            <option value="" >Select From Below Options</option>
                            {Object.entries(TemplateIdPromotional).map((data, i) => {
                                return (
                                    <option value={data[0]}>{data[0]}</option>
                                )
                            })}
                        </select>
                        <div>
                            {msgBody ?
                                <textarea
                                    value={msgBody}
                                    rows="6"
                                    readOnly={true}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "rgba(219, 233, 255, 0.70)",
                                        borderRadius: "6px",
                                        border: "none",
                                        marginTop: '20px',
                                        padding: '10px'

                                    }}
                                ></textarea>
                                : ""}
                            {msgBody ?
                                <button
                                    title="SEND"
                                    onClick={() => sendHandlerMsg()}
                                    type="button"
                                    className="bulkCommSendBtn"
                                    style={{ width: '140px', marginTop: '20px' }}
                                >
                                    Send Message
                                </button>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Smspage