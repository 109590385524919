import React, { Component } from 'react'
import LeadDashboardUi from '../../../presentationals/Admin/LeadManagement/userdashboard.component'
import { _formatDateInDash } from '../../../../utils/validation'
import moment from 'moment'
import { APIS } from '../../../../utils/api-factory'
import { getCompanyPerformance, getCompanyPerformanceDepartment, downloadBydepartment, getDashboardData } from '../AdminActionCreator.component';
import { ROLE } from '../../../../utils/Constant'
import PopUp from '../../../presentationals/Popup/Popup.component'
import AdminDahboard from '../../../presentationals/Admin/LeadManagement/admindashboard.component'
import Loader from '../../../presentationals/Loader/Loader.component'
let mainId = ['SuperUser', 'vishnu.pandey@credfin.money', 'mohit.bansal@credfin.money', 'sumit.jain@credfin.money']
const COLORS = ['#7AD3FF', '#FFD572', '#9646FD'];
const data = [30, 40, 30]; // Array of values
const names = ['Male', 'Female', 'Other']; // Array of corresponding labels
let seriesData = []
class LeadsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadDashFilterData: {
                startDateToShow: moment(new Date()).format('YYYY-MM-DD'),
                startDate: moment(new Date()).format("DD-MM-YYYY"),
                // startDate: moment(new Date()).subtract(7, "days").format("DD-MM-YYYY"),
                endDateToShow: moment(new Date()).format('YYYY-MM-DD'),
                endDate: moment(new Date()).format("DD-MM-YYYY"),
                stateWiseDate: moment(new Date()).format("DD-MM-YYYY"),
                stateWiseDateToShow: ''
            },
            stateWiseData: [],
            agentWiseData: [],
            statusWiseData: "",
            errorMsg: '',
            todayData: '',
            agentStatusWiseData: '',
            performanceData: '',
            performanceLoader: '',
            userStatusData: [],
            graphState: true,
            ReportViewState: 'all',
            todaysApplicationNo: '',
            departmentToSend: '',
            // datadownload: ''
            datadownload: {
                emailId: [],
                data: []
            },
            popupStatus: '',
            popupstate: false,
            emailIdToShow: '',
            tabledata: '',
            filterType: '',
            numberData: [],
            nameData: [],
            viewState: '',
            seriesDataCounts: ''
        }
    }
    closePopup() {
        this.setState({ popupstate: false })
    }
    statusWiseReportingHandler(e) {
        this.setState({
            // ReportViewState: e.target.value,
            departmentToSend: e.target.value

        })
        // graphState: false,
        // if (e.target.value === 'Finance') {
        //     this.setState({
        //         ReportViewState: 'Finance / Credit',
        //         departmentToSend: e.target.value
        //     })
        // }
        // if (e.target.value === 'Customer') {
        //     this.setState({
        //         ReportViewState: 'Customer Service',
        //         departmentToSend: e.target.value

        //     })
        // }
        // if (e.target.value !== 'Customer Service' && e.target.value !== 'Finance / Credit') {
        //     this.setState({
        //         ReportViewState: e.target.value,
        //         departmentToSend: e.target.value

        //     })
        // }
    }
    getDashboardCount() {
        getDashboardData(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate, this.state.departmentToSend, this.state.departmentToSend === 'Customer Service' ? 'Customer Service Mark Complete' : "", callBack => {
            this.setState({ seriesDataCounts: callBack })
            seriesData = [];

            Object.entries(callBack).forEach((data) => {
                seriesData.push({
                    name: data[0],
                    data: Object.values(data[1]),
                });
            });

            console.log(seriesData);

        })
    }
    getAssignedByStatus() {
        let emailbyapi = []
        fetch(APIS.GET_ASSIGN_BY_STATUS)
            .then(res => res.json())
            .then(res => {
                // console.log(res)

                res ? (res).map((jn, lk) => {
                    jn.assignIdsWithLeave ? Object.keys(jn.assignIdsWithLeave).map((newdata, k) => {
                        // console.log(newdata)
                        emailbyapi.push(newdata)
                        this.setState({ userStatusData: JSON.stringify(emailbyapi) })
                    }) : ""
                }) : ""


            })
    }
    getCompanyPerfomanceApi() {
        this.setState({ performanceLoader: true })

        getCompanyPerformance(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate, callBack => {
            if (callBack) {
                this.setState({ performanceData: callBack, performanceLoader: false })

            }
        })
    }
    getCompanyPerfomanceApiByDepartment() {
        const { admin } = this.props
        this.setState({ performanceLoader: true })
        this.getDashboardCount()
        getCompanyPerformanceDepartment(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate, this.state.departmentToSend, this.state.departmentToSend === 'Customer Service' ? 'Customer Service Mark Complete' : "", callBack => {
            if (callBack) {
                if (admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0)) {
                    this.setState({ performanceData: callBack, performanceLoader: false })
                    // seriesData = []
                    // Object.values(callBack).map((data, i) => {
                    //     Object.entries(data).map((f, l) => {
                    //         // console.log(f[1])
                    //         seriesData.push({
                    //             name: Object.keys(f[1]),
                    //             data: Object.values(f[1]),
                    //         })
                    //     })
                    // })
                    // console.log(seriesData)




                } else {
                    Object.values(callBack).map((data, i) => {
                        Object.keys(data).filter(row => {
                            if (row === admin.emailId) {
                                this.setState({ emailIdToShow: row })

                                this.setState({ tabledata: (data[row]), performanceLoader: false })
                                let last = []
                                let name = []
                                Object.entries(data[row]).map((i, k) => {
                                    last.push(i[1])
                                    name.push(i[0])
                                })
                                this.setState({ numberData: last, nameData: name })
                            } else {
                                this.setState({ performanceLoader: false })
                            }

                        })


                    })
                }
            }
        })
    }
    downloadBydepartmentFunction() {
        if (this.state.departmentToSend) {
            this.setState({ performanceLoader: true })
            downloadBydepartment(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate, this.state.departmentToSend, callBack => {
                if (callBack) {
                    this.setState({ performanceLoader: false })
                }
            })
        } else {
            this.setState({ popupstate: true, popupStatus: 'Please Select Department !' }, () => this.removePopup())
        }
    }
    getCountByState(date) {
        fetch(APIS.GET_COUNT_BY_STATUS + date)
            .then(res => res.json())
            .then(res => {
                this.setState({ agentStatusWiseData: res })
            })
    }

    getCountByStatus(date) {
        fetch(APIS.GET_COUNT_BY_STATES + date + '&applicationStatus=complete')
            .then(res => res.json())
            .then(res => {
                this.setState({ stateWiseData: res })
            })
    }

    getCountByAgent(date) {
        fetch(APIS.GET_COUNT_BY_AGENT + date)
            .then(res => res.json())
            .then(res => {
                this.setState({ agentWiseData: res })
            })
    }

    getApplicationStatusCount(startDate, endDate) {
        const { admin } = this.props
        fetch(APIS.GET_APPLICATION_STATUS_COUNT + startDate + '&endDate=' + endDate + '&agentId=' + admin.emailId)
            .then(res => res.json())
            .then(res => {
                this.setState({ statusWiseData: res })
            })
    }

    getTodayApplication() {
        const { admin } = this.props
        // fetch(APIS.GET_APPLICATION_STATUS_COUNT + moment(new Date()).format("DD-MM-YYYY") + '&endDate=' + moment(new Date()).format("DD-MM-YYYY") + '&agentId=' + admin.emailId)
        //     .then(res => res.json())
        //     .then(res => {
        //         this.setState({ todayData: res })
        //     })
        fetch(APIS.DEPARTMENTWISE_LOAN_COUNT + this.state.leadDashFilterData.startDate)
            .then(res => res.json())
            .then(res => {
                this.setState({ todayData: res })
            })
    }
    repeatandallapplicationcount() {
        fetch(APIS.TODAYS_LOAN_COUNT + this.state.leadDashFilterData.startDate)
            .then(res => res.json())
            .then(json => {
                this.setState({ todaysApplicationNo: json })
            })
        this.setState({
            loadarState: false,
        })
    }
    componentDidMount() {

        const { admin } = this.props
        // console.log(mainId.includes(admin.emailId))
        // if (mainId.includes(admin.emailId)) {
        //     this.setState({ departmentToSend: 'Customer Service' }, () => this.getCompanyPerfomanceApiByDepartment())
        // } else {
        this.setState({ departmentToSend: admin.department }, () => this.getCompanyPerfomanceApiByDepartment())

        // }
        this.repeatandallapplicationcount()
        this.getTodayApplication()

        if (admin.rolelist != undefined && (admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 || admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0)) {
            this.setState({ viewState: 'admin' })
        } else {
            this.setState({ viewState: 'user' })

        }


    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupstate: false });
        }.bind(this), 5000)
    }



    render() {
        // const transformedData = Object.keys(seriesData).map(date => {
        //     const categories = Object.keys(seriesData[date]);
        //     return {
        //       name: date,
        //       data: categories.map(category => seriesData[date][category]),
        //     };
        //   });

        const rawData = this.state.seriesDataCounts || {}

        const categories = Object.keys(rawData[Object.keys(rawData)[0]] || {});
        const seriesData = categories.map(category => ({
          name: category,
          data: Object.keys(rawData).map(date => rawData[date][category]),
        }));
        
        const options = {
            series: seriesData,
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: '30%', // Adjust the width of the bars as needed
                },
            },
            xaxis: {
                type: 'category',
                categories: Object.keys(rawData),
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return value;
                    },
                },
            },
            legend: {
                position: 'top',
            },
            fill: {
                opacity: 0.6,
            },
        };

        // Your chart initialization code here


        const chartOptions = {
            series: this.state.numberData,
            chart: {
                width: '100%',
                type: 'donut',
            },
            labels: this.state.nameData,
            theme: {
                monochrome: {
                    enabled: true,

                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -5

                    },

                }
            },

            fill: {
                colors: COLORS,
                opacity: 3,
                type: 'solid',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.5,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 100],
                    colorStops: []
                },
            },
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    return [name, val.toFixed(1) + '%'];
                }
            },
            legend: {
                show: true,
            }
        }
        const { allStatus, admin } = this.props
        return (
            <div>
                {this.state.performanceLoader ?
                    <Loader />
                    : ""}
                {
                    this.state.popupstate ?
                        <PopUp
                            // popupStatus={this.state.popupstate}
                            popupStatus={this.state.popupStatus}
                            closePopup={this.closePopup.bind(this)}
                        />
                        : ""
                }
                {this.state.viewState === 'user' ?
                    < LeadDashboardUi
                        chartOptions={chartOptions}
                        tabledata={this.state.tabledata}
                        // mainId={mainId}
                        emailIdToShow={this.state.emailIdToShow}
                        downloadBydepartmentFunction={this.downloadBydepartmentFunction.bind(this)}
                        datadownload={this.state.datadownload}
                        departmentToSend={this.state.departmentToSend}
                        admin={admin}
                        todaysApplicationNo={this.state.todaysApplicationNo}
                        ReportViewState={this.state.ReportViewState}
                        statusWiseReportingHandler={this.statusWiseReportingHandler.bind(this)}
                        graphState={this.state.graphState}
                        userStatusData={this.state.userStatusData}
                        performanceLoader={this.state.performanceLoader}
                        performanceData={this.state.performanceData}
                        leadDashFilterData={this.state.leadDashFilterData}
                        filterDataChange={this.filterDataChange.bind(this)}
                        filterDataHandler={this.filterDataHandler.bind(this)}
                        stateWiseData={this.state.stateWiseData}
                        agentWiseData={this.state.agentWiseData}
                        statusWiseData={this.state.statusWiseData}
                        errorMsg={this.state.errorMsg}
                        todayData={this.state.todayData}
                        agentStatusWiseData={this.state.agentStatusWiseData}
                    />
                    : ""}
                {this.state.viewState === 'admin' ?
                    <AdminDahboard
                        startDateToShow={this.state.startDateToShow}
                        options={options}
                        tabledata={this.state.tabledata}
                        // mainId={mainId}
                        emailIdToShow={this.state.emailIdToShow}
                        downloadBydepartmentFunction={this.downloadBydepartmentFunction.bind(this)}
                        datadownload={this.state.datadownload}
                        departmentToSend={this.state.departmentToSend}
                        admin={admin}
                        todaysApplicationNo={this.state.todaysApplicationNo}
                        ReportViewState={this.state.ReportViewState}
                        statusWiseReportingHandler={this.statusWiseReportingHandler.bind(this)}
                        graphState={this.state.graphState}
                        userStatusData={this.state.userStatusData}
                        performanceLoader={this.state.performanceLoader}
                        performanceData={this.state.performanceData}
                        leadDashFilterData={this.state.leadDashFilterData}
                        filterDataChange={this.filterDataChange.bind(this)}
                        filterDataHandler={this.filterDataHandler.bind(this)}
                        stateWiseData={this.state.stateWiseData}
                        agentWiseData={this.state.agentWiseData}
                        statusWiseData={this.state.statusWiseData}
                        errorMsg={this.state.errorMsg}
                        todayData={this.state.todayData}
                        agentStatusWiseData={this.state.agentStatusWiseData}
                    />
                    : ""}
            </div >
        )
    }

    filterDataChange(e, type) {
        let leadDashFilterData = Object.assign({}, this.state.leadDashFilterData)
        if (type == 'startDate') {
            console.log(e.target.value)
            leadDashFilterData.startDateToShow = e.target.value
            leadDashFilterData.startDate = _formatDateInDash(e.target.value)
        } else if (type == 'endDate') {
            leadDashFilterData.endDateToShow = e.target.value
            leadDashFilterData.endDate = _formatDateInDash(e.target.value)
        } else if (type == 'stateWiseDate') {
            leadDashFilterData.stateWiseDateToShow = e.target.value
            leadDashFilterData.stateWiseDate = _formatDateInDash(e.target.value)
            this.getCountByState(_formatDateInDash(e.target.value))
            this.getCountByAgent(_formatDateInDash(e.target.value))
            this.getCountByStatus(_formatDateInDash(e.target.value))
        }
        this.setState({ leadDashFilterData, errorMsg: '' })
    }

    filterDataHandler() {
        // this.getTodayApplication()
        // this.repeatandallapplicationcount()
        let startDate = moment(this.state.leadDashFilterData.startDate, 'DD-MM-YYYY')
        let endDate = moment(this.state.leadDashFilterData.endDate, 'DD-MM-YYYY')
        let DateDiff = endDate.diff(startDate, 'days')
        // if (DateDiff <= 7) {
        //     // this.getApplicationStatusCount(this.state.leadDashFilterData.startDate, this.state.leadDashFilterData.endDate)
        // } else {
        //     this.setState({ errorMsg: "You can show only maximum of one week data." })
        // }

        const { admin } = this.props
        // if (admin.emailId === 'SuperUser') {
        //     this.getCompanyPerfomanceApi()

        // }
        // if (admin.emailId !== 'SuperUser') {
        if (this.state.departmentToSend) {
            this.getCompanyPerfomanceApiByDepartment()
        } else {
            this.setState({ popupstate: true, popupStatus: 'Please Select Department !' }, () => this.removePopup())
        }
        // }

    }
}

export default LeadsDashboard;