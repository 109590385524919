import { InfoOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Individual from "./individualNbfc";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { getConversionRatioDataApi, getLoanStatusByNbfcIdApi, getNbfcPerformanceDataApi, getRepaymentStatusApi, nbfcStatusApi, onboardingStatusApi, overNbfcCountsApi,getNbfcRecentActivity } from "./actioncreatorNbfc";
import { getNbfcList } from "../AdminActionCreator.component";
import NbfcIcon from './nbfcicon.svg'
import AddNbfc from "../NbfcManagementNew/addNbfc.container";
import ReplyIcon from '@mui/icons-material/Reply';

const AllNbfc = ({ pageHandler, admin, pageViewHandler }) => {
    const [viewPage, setViewpage] = useState('first')
    const [nbfcIdView, setnbfcIdView] = useState('')
    const [showZoom, setShowZoom] = useState(false)
    const [countData, setCountData] = useState('')
    const [graphData, setGraphData] = useState('')
    const [nbfcList, setNbfcList] = useState([])
    const [nbfcId, setNbfcId] = useState('')
    const [nbfcCountsData, setNbfcLoanCounts] = useState('')
    const [repaymentStatusData, setRepaymentStatusData] = useState('')
    const [conversionData1, setConversionData] = useState('')
    const [performanceData, setPerformaceData] = useState('')
    const [nbfcStats, setNbfcStats] = useState('')
    const [RecentActivity,setRecentActivity] =useState([])



    const getCountData = () => {
        overNbfcCountsApi(callBack => {
            setCountData(callBack.data)
        })
    }
    const getonboardGraphData = () => {
        onboardingStatusApi(callback => {
            setGraphData(callback.data)
        })
    }
    const getLoanCountByNbfcId = (nbfc) => {
        setNbfcId(nbfc)
        callApiAfterNbfcId(nbfc)
    }
    const getAllnbfc = () => {
        getNbfcList(callBack => {
            setNbfcList(callBack)
            setNbfcId(callBack[0].nbfcId)
            callApiAfterNbfcId(callBack[0].nbfcId)

        })
    }
    const getconversionData = () => {
        getConversionRatioDataApi(callback => {
            setConversionData(callback.data)
        })
    }
    const nbfcPerformanceApi = () => {
        getNbfcPerformanceDataApi(callBack => {
            setPerformaceData(callBack.data)
        })
    }
    const nbfcStatsData = () => {
        nbfcStatusApi(callBack => {
            setNbfcStats(callBack.data)
        })
    }
    const callApiAfterNbfcId = (nbfcId) => {
        getLoanStatusByNbfcIdApi(nbfcId, callBack => {
            setNbfcLoanCounts(callBack.data)
        })
        getRepaymentStatusApi(nbfcId, callback => {
            setRepaymentStatusData(callback.data)
        })
    }
    useEffect(() => {
        getCountData()
        getonboardGraphData()
        getAllnbfc()
        getconversionData()
        nbfcPerformanceApi()
        nbfcStatsData()
        getNbfcRecentActivityFunction()

    }, [])

    const categories = Array.isArray(graphData)
        ? graphData.map(item => item.nbfcName ?? 'Unknown')
        : [];

    const series = [
        {
            name: 'Application Received',
            data: Array.isArray(graphData)
                ? graphData.map(item => item.application_received ? parseInt(item.application_received, 10) : 0)
                : []
        },
        {
            name: 'Under Review',
            data: Array.isArray(graphData)
                ? graphData.map(item => item.under_review ? parseInt(item.under_review, 10) : 0)
                : []
        },
        {
            name: 'Approved',
            data: Array.isArray(graphData)
                ? graphData.map(item => item.approved ? parseInt(item.approved, 10) : 0)
                : []
        },
        {
            name: 'Disbursed',
            data: Array.isArray(graphData)
                ? graphData.map(item => item.disbursed ? parseInt(item.disbursed, 10) : 0)
                : []
        },
        {
            name: 'Rejected',
            data: Array.isArray(graphData)
                ? graphData.map(item => item.rejected ? parseInt(item.rejected, 10) : 0)
                : []
        }
    ];


    const barOptions = {
        series,
        chart: {
            type: 'bar',
            height: 350,
            stacked: true
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories,
            labels: {
                formatter: val => val
            }
        },
        yaxis: {
            title: {
                text: undefined
            }
        },
        tooltip: {
            y: {
                formatter: val => val
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    };

    const seriesradial = Object.values(conversionData1).map(value => parseFloat(value) || 0);
    const labels = Object.keys(conversionData1);

    var radialbar = {
        series: seriesradial,
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function (w) {
                            const total = seriesradial.reduce((acc, val) => acc + val, 0);
                            return total.toFixed(2);
                        }
                    }
                },
                track: {
                    background: '#f2f2f2',
                    strokeWidth: '100%'
                },
                hollow: {
                    margin: 15,
                    size: '35%',
                },
                barWidth: '20%',
            }
        },
        labels: labels,
    };

    const handleNbfcView=(data)=>{
        setnbfcIdView(data)
        setTimeout(()=>{
            setViewpage('info')
        },200)
    }

    const getNbfcRecentActivityFunction =()=>{
        getNbfcRecentActivity((callback)=>{
            console.log("call",callback)
            if(callback && callback?.message == "success" && callback?.status == 200){
                setRecentActivity(callback?.data)
            }else{
                setRecentActivity([])
            }
        })
    }

    const total = repaymentStatusData.paidCount + repaymentStatusData.overdueCount;
    const paidPercentage = total > 0 ? (repaymentStatusData.paidCount / total) * 100 : 0;
    const overduePercentage = total > 0 ? (repaymentStatusData.overdueCount / total) * 100 : 0;
    const overtimePercentage = 100 - (paidPercentage + overduePercentage);
    return (
        <>
            {viewPage === 'first' ?
                <div className="container-fluid nbfc-dashboard">
                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            <select className="form-control" onChange={(e) => getLoanCountByNbfcId(e.target.value)}>
                                <option>Select NBFC</option>
                                {nbfcList ? nbfcList.map((data, i) => {
                                    return (
                                        <option key={i} value={data.nbfcId}>{data.name}</option>
                                    )
                                }) : ""}
                            </select>
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            {/* <input type="date" className="form-control" /> */}
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            {/* <input type="date" className="form-control " /> */}
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}>
                            {/* <button className="btn btn-primary" style={{ background: '#4D84C7', padding: '10px' }}>Apply filters</button> */}
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '10px' }}></div>
                        <div className="col-sm-2 col-xs-12" style={{ textAlign: 'end', marginTop: '10px' }}>
                            <button className="btn btn-primary" style={{ background: '#4D84C7', padding: '10px' }} onClick={() => setViewpage('addnbfc')}>Add NBFC</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-5 col-xs-12">
                            <div className="box-outer">
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey">
                                            <h1><b>{countData !== '' && countData.totalNBFCsCount ? countData.totalNBFCsCount : "0"}</b></h1>
                                            Total NBFCs
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey" >
                                            <h1><b>{countData !== '' && countData.activeNBFCsCount ? countData.activeNBFCsCount : "0"}</b></h1>
                                            Active NBFCs
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey">
                                            <h1><b>{countData !== '' && countData.inactiveNBFCsCount ? countData.inactiveNBFCsCount : "0"}</b></h1>
                                            Inactive NBFCs
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <div className="box-grey">
                                            <h1><b>{countData !== '' && countData.pendingApprovalsCount ? countData.pendingApprovalsCount : "0"}</b></h1>
                                            Pending Approvals
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-7 col-xs-12">
                            <div className="box-outer">
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <h5><b>Onboarding Status</b></h5>
                                    </div>
                                    <div className="col-sm-6 col-xs-12" style={{ textAlign: 'right' }}>
                                        <OpenInFullIcon onClick={e => setShowZoom(true)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                {graphData ?
                                    <ReactApexChart
                                        options={barOptions}
                                        series={barOptions.series}
                                        type="bar"
                                        height={290}
                                    />
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-xs-12">
                            <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                {console.log(nbfcCountsData)}
                                <h3>{nbfcCountsData && nbfcCountsData.loansDisbursed ? nbfcCountsData.loansDisbursed : "0"}</h3>
                                <p >Total Loans Disbursed</p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12">
                            <div className="box-grey-individual" style={{ marginTop: '30px' }}>

                                <h3>{nbfcCountsData && nbfcCountsData.approvalRate ? nbfcCountsData.approvalRate : "0"}</h3>
                                <p >Approval Rate</p>
                            </div>
                        </div>

                        <div className="col-sm-3 col-xs-12">
                            <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                <h3>{nbfcCountsData && nbfcCountsData.notApprovalRate ? nbfcCountsData.notApprovalRate : "0"}</h3>
                                <p >Default Rate</p>

                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12">
                            <div className="box-grey-individual" style={{ marginTop: '30px' }}>
                                <h3>{nbfcCountsData && nbfcCountsData.totalLoanApplications ? nbfcCountsData.totalLoanApplications : "0"}</h3>
                                <p >Total Loan Applications</p>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="box-outer" style={{ minHeight: '310px' }}>
                                <h5><b>Repayment Status</b></h5>

                                <div className="row" style={{ marginTop: '45px' }}>
                                    <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>Overdue</div>
                                    <div className="col-sm-10 col-xs-12">
                                        <div className="progress-bar">
                                            <div className="progress-bar-left" style={{ background: '#913BFE', width: `${overduePercentage}%` }}></div>
                                            <div className="progress-bar-right" style={{ width: `${100 - overduePercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '45px' }}>
                                    <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>Paid</div>
                                    <div className="col-sm-10 col-xs-12">
                                        <div className="progress-bar" style={{ background: '#C3E3E5' }}>
                                            <div className="progress-bar-left" style={{ background: '#05A58B', width: `${paidPercentage}%` }}></div>
                                            <div className="progress-bar-right" style={{ width: `${100 - paidPercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '45px', marginBottom: '35px' }}>
                                    <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>Overtime</div>
                                    <div className="col-sm-10 col-xs-12">
                                        <div className="progress-bar" style={{ background: '#FCEDDD' }}>
                                            <div className="progress-bar-left" style={{ background: '#FFBB55', width: `${overtimePercentage}%` }}></div>
                                            <div className="progress-bar-right" style={{ width: `${100 - overtimePercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="box-outer">
                                <h5><b>Conversion Rate</b></h5>
                                <ReactApexChart
                                    options={radialbar}
                                    series={radialbar.series}
                                    type="radialBar"
                                    height={264}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-7 col-xs-12">
                            <div className="box-outer" >
                                <h5><b>Top Performing NBFCs</b></h5>
                                <div style={{ overflow: 'auto' }}>
                                    <table class="simple-table">
                                        <thead>
                                            <tr>
                                                <th>NBFC Name</th>
                                                <th>Total Loan</th>
                                                <th>Approved Loan</th>
                                                <th>Loan Disbursement Rate</th>
                                                <th>Approval Rate</th>
                                                <th>Default rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {performanceData && performanceData.TopPerforming.map((data, i) => {
                                                return (
                                                    <tr>
                                                        <td>{data.nbfcName}</td>
                                                        <td>{data.totalLoan}</td>
                                                        <td>{data.approvedLoan}</td>
                                                        <td>{data.disbursementRate}</td>
                                                        <td>{data.approvalRate}</td>
                                                        <td>{data.notApprovedRate}</td>
                                                    </tr>
                                                )
                                            })}



                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="box-outer" >
                                <h5><b>Under Performing NBFCs</b></h5>
                                <div style={{ overflow: 'auto' }}>

                                    <table class="simple-table">
                                        <thead>
                                            <tr>
                                                <th>NBFC Name</th>
                                                <th>Total Loan</th>
                                                <th>Approved Loan</th>
                                                <th>Loan Disbursement Rate</th>
                                                <th>Approval Rate</th>
                                                <th>Default rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {performanceData && performanceData.UnderPerforming.map((data, i) => {
                                                return (
                                                    <tr>
                                                        <td>{data.nbfcName}</td>
                                                        <td>{data.totalLoan}</td>
                                                        <td>{data.approvedLoan}</td>
                                                        <td>{data.disbursementRate}</td>
                                                        <td>{data.approvalRate}</td>
                                                        <td>{data.notApprovedRate}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5 col-xs-12">
                            <div className="box-outer" style={{ minHeight: '425px' }}>
                                <h5><b>Recent NBFC Activity</b></h5>


                              
                                {RecentActivity && RecentActivity?.length > 0 ? RecentActivity.map((data, i) => {
                                    return (
                                        <div className="row" style={{marginTop:'20px'}}>
                                            <div className="col-sm-2 col-xs-2 text-center">
                                                <img src={NbfcIcon} />
                                            </div>
                                            <div className="col-sm-10 col-xs-10">
                                                <p>{data?.nbfcName + " " + data?.action + "at" + data?.timeStamp}</p>
                                            </div>
                                        </div>
                                    )
                                }) : <p>No data</p> }



                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <div className="box-outer" style={{ marginBottom: '30px' }} >
                                <h4><b>NBFC Overview Table</b></h4>
                                <div className="dash-table">
                                    <table class="">


                                        <thead>
                                            <tr>
                                                <th>Info</th>
                                                <th>NBFC</th>
                                                <th>POC Name</th>
                                                <th>POC Contact Number</th>
                                                <th>Total Lead</th>
                                                <th>Interest</th>
                                                <th>Total Loan Disbursed</th>
                                                <th>Approval Rate</th>
                                                <th>Default Rate</th>
                                                {/* <th>Generate Report</th> */}
                                                <th>Status</th>
                                                {/* <th>Actions</th> */}
                                                <th>Integration Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {nbfcStats ? nbfcStats.map((data, i) => (
                                                <tr key={i}>
                                                    <td><InfoOutlined onClick={() => handleNbfcView(data)} style={{ background: '#267DFF', borderRadius: '20px', color: '#fff', cursor: 'pointer' }} /></td>

                                                    <td>{data.nbfcName || 'N/A'}</td>
                                                    <td>{data.pocName || 'N/A'}</td>
                                                    <td>{data.pocNumber || 'N/A'}</td>
                                                    <td>{data.totalApplications || 'N/A'}</td>
                                                    <td>{data.interestRate || 'N/A'}</td>
                                                    <td>{data.loanDisbursed || 'N/A'}</td>
                                                    <td>{data.approvalRate || 'N/A'}</td>
                                                    <td>{data.notapprovedRate || 'N/A'}</td>
                                                    {/* <td><button>Generate Report</button></td> */}
                                                    <td>{data.status || 'N/A'}</td>
                                                    {/* <td>
                                                        <button>Actions</button>
                                                    </td> */}
                                                    <td>{data.integrationStatus || 'N/A'}</td>
                                                </tr>
                                            )) : <tr><td colSpan="13">No data available</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}

            {viewPage === 'addnbfc' ?
                <>
               <span style={{cursor:'pointer'}} onClick={()=>setViewpage('first')}> <ReplyIcon/> Back To All NBFC</span>
                    <AddNbfc pageViewHandler={pageViewHandler} />
                </>
                : ""}

            {viewPage === 'info' ?
                <Individual admin={admin} setViewpage={setViewpage} nbfcId={nbfcIdView}/>
                : ""}
            {showZoom ?
                <>
                    <div className="background-blur" onClick={e => setShowZoom(false)}></div>
                    <div className="popup-main small-popup container-fluid " style={{ width: '80%' }}>
                        <h3 className="blue-text text-center"></h3>
                        <div className="row form-group">
                            <ReactApexChart
                                options={barOptions}
                                series={barOptions.series}
                                type="bar"
                                height={400}
                            />
                        </div>
                    </div>
                </>
                : ""}

        </>
    );
}

export default AllNbfc;
