import React, { useState, useEffect } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconImg from '../../../../../images/ObligationPopupImage.svg'
import NodataImage from '../../../../../images/nodata.png';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getCrifSummaryAPI, getCrifLoansAPI, addNewObligaitonAPI, UpdateObligaitonDataAPI, DeleteLoanDataAPI, getObligationcalculatorAPI } from '../../Analysis/analysisactionCreator';
import Loader from '../../../../presentationals/Loader/Loader.component'
import Popup from '../../../../presentationals/Popup/Popup.component';

const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}


export default function ObligationCalculatorPopup({ allDetail, handleShowObligationPopup }) {
    const itemsPerPage = 10;
    const disabledbtn = false;
    const [loader, setloader] = useState(false)
    const [fromDate, setFromdate] = useState(InitalDate('fromDate'))
    const [toDate, setTodate] = useState(InitalDate('toDate'))
    const [currentPage, setCurrentPage] = useState(1)
    const [updateDeviationValue, setUpdateDeviationValue] = useState({ dataObj: {}, updatedValue: '', index: '' })
    const [DeviationPopupShow, setDeviationPopupShow] = useState({ show: false, value: '' })
    const [ObligationPopupShow, setObligationPopupShow] = useState(false)
    const [addNewObligationPopup, setAddNewObligationPopup] = useState({ show: false, data: {} })
    const [crifSummaryData, setcrifSummaryData] = useState({})
    const [listData, setlistdata] = useState([])
    const [filteredData, setfilteredData] = useState([])
    const [editData, setEditData] = useState({})
    const [popup, setPopup] = useState({ show: false, popupStatus: '' })


    useEffect(() => {
        getCrifSummaryFunction()
        getCrifLoansFunction(InitalDate('fromDate'), InitalDate('toDate'))
    }, [])

    const getCrifSummaryFunction = () => {
        setloader(true)
        getCrifSummaryAPI(allDetail?.userId, allDetail?.loanId, (callback) => {
            console.log(callback)
            setloader(false)
            if (callback) {
                setcrifSummaryData(callback)
            } else {
                setcrifSummaryData({})
            }
        })
    }
    const getCrifLoansFunction = (start, end) => {
        const sDate = moment(start).format('DD-MM-YYYY')
        const eDate = moment(end).format('DD-MM-YYYY')
        setloader(true)
        // getCrifLoansAPI(allDetail?.userId, allDetail?.loanId,sDate,eDate, (callback) => {
        getCrifLoansAPI(allDetail?.userId, allDetail?.loanId, "", "", (callback) => {
            console.log(callback)
            setloader(false)
            if (callback) {
                setlistdata(callback)
                setfilteredData(callback.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                ))
            } else {
                setlistdata([])
                setfilteredData([])
            }
        })
    }


    const handleDateFilter = (e, type) => {
        if (type === "fromdate") {
            setFromdate(e.target.value)
        }
        else if (type === "todate") {
            setTodate(e.target.value)
        }
        else if (type === "apply") {
            getCrifLoansFunction(fromDate, toDate)
        }
        else if (type === "reset") {
            setFromdate(InitalDate('fromDate'))
            setTodate(InitalDate('toDate'))
            getCrifLoansFunction(InitalDate('fromDate'), InitalDate('toDate'))
        }
    }




    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setfilteredData(listData.slice(
            (value - 1) * itemsPerPage,
            value * itemsPerPage
        ))
    }

    const handleUpdateDeviation = (e, data, index) => {
        console.log(data, index)
        setUpdateDeviationValue({
            dataObj: data,
            updatedValue: e.target.value,
            index: index,
        });
    }

    const handleDeviationInfo = (value) => {
        if (value !== "" && value !== null && value !== undefined) {
            setDeviationPopupShow({ show: true, value: value })
        }
    }

    const handlePopupClose = () => {
        setEditData({})
        setDeviationPopupShow({ show: false, value: "" })
        setObligationPopupShow(false)
        setAddNewObligationPopup({ show: false, data: {} })
    }

    const handleInfo = (data) => {
        setObligationPopupShow(true)
        setEditData(data)
    }

    const handleAddNewObligation = () => {
        if (!disabledbtn) {
            setAddNewObligationPopup({ show: true, data: {} })
        }
    }

    const handleAddObligationDataFunction = (e) => {
        const { name, value } = e.target;
        setAddNewObligationPopup((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
            },
        }));
    }

    const HandleEditData = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setEditData((prev) => ({
            ...prev,
            [name]: value
        }))
    }



    const submitEditData = () => {
        console.log("editDataeditDataeditData", editData)
        const obj = { ...editData, uniqueId: editData?.uniqueId }
        setloader(true)
        UpdateObligaitonDataAPI(allDetail?.userId, allDetail?.loanId, editData?.uniqueId, obj, (callback) => {
            console.log(callback)
            setloader(false)
            if (callback == "success") {
                handlePopupClose()
                getCrifLoansFunction(fromDate, toDate)
                setPopup({ show: true, popupStatus: "Data Saved" })
            } else {
                setPopup({ show: true, popupStatus: "Error: Please Try Again later!" })
            }
            removePopup()
        })
    }

    const SaveUpdatedDeviation = () => {
        console.log("updateDeviationValueupdateDeviationValueupdateDeviationValue", updateDeviationValue)
        setloader(true)
        const obj = { ...updateDeviationValue.dataObj, deviation: updateDeviationValue?.updatedValue, uniqueId: updateDeviationValue?.dataObj?.uniqueId };
        UpdateObligaitonDataAPI(allDetail?.userId, allDetail?.loanId, updateDeviationValue?.dataObj?.uniqueId, obj, (callback) => {
            console.log(callback)
            setloader(false)
            if (callback == "success") {
                getCrifLoansFunction(fromDate, toDate)
                setUpdateDeviationValue({
                    dataObj: {},
                    updatedValue: "",
                    index: "",
                });
                setPopup({ show: true, popupStatus: "Data Saved" })
            } else {
                setPopup({ show: true, popupStatus: "Error: Please Try Again later!" })
            }
            removePopup()
        })
    }


    const SaveEMIcalculate = () => {
        setloader(true)
        const dueDate = moment(addNewObligationPopup?.data?.disburseDate).format('DD-MM-YYYY')
        const obj = { ...addNewObligationPopup?.data, disburseDate: dueDate }
        addNewObligaitonAPI(allDetail?.userId, allDetail?.loanId, obj, (callback) => {
            console.log(callback)
            setloader(false)
            if (callback == "success") {
                handlePopupClose()
                getCrifLoansFunction(InitalDate('fromDate'), InitalDate('toDate'))
                setPopup({ show: true, popupStatus: "Data Saved" })
            } else {
                setPopup({ show: true, popupStatus: "Error: Please Try Again later!" })
            }
            removePopup()
        })
    }

    const closePopup = () => {
        setPopup({ show: false, popupStatus: "" })
    }

    const removePopup = () => {
        setTimeout(() => {
            closePopup()
        }, 5000)
    }

    const handleDelete = (data) => {
        console.log("data", data)
        setloader(true)
        DeleteLoanDataAPI(data.userId, data?.loanId, data?.uniqueId, callback => {
            setloader(false)
            console.log(callback)
            if (callback == "success") {
                handlePopupClose()
                getCrifLoansFunction(fromDate, toDate)
            } else {
                setPopup({ show: true, popupStatus: "Error: Please Try Again later!" })
            }
            removePopup()
        })
    }

    const regenerateFunction = () => {
        getObligationcalculatorAPI(allDetail.userId, allDetail.loanId, 1, (callback) => {
            console.log("callbackcallback", callback)
            if (callback) {
                setFromdate(InitalDate('fromDate'))
                setTodate(InitalDate('toDate'))
                getCrifSummaryFunction()
                getCrifLoansFunction(InitalDate('fromDate'), InitalDate('toDate'))
            }
        })
    }

    return (
        <>
            <div className='background-blur income ObligationCalculatorPopup' style={{ zIndex: '1000' }}>
                {
                    loader ?
                        <Loader />
                        : ''}
                {
                    popup?.show == true ?
                        <Popup
                            closePopup={closePopup}
                            popupStatus={popup.popupStatus}
                        />
                        : ''}
                <div className={`popup-main container-fluid dbl-border ${ObligationPopupShow == true || addNewObligationPopup.show == true ? "slide-out-left" : "slide-in-left"}`} style={{ padding: '0px', width: 'auto', top: '4%', height: '90%' }}>
                    <div style={{ background: '#1D3557', display: 'flex', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', color: '#fff', padding: '10px 20px ', alignItems: 'center' }}>
                        <ArrowBackIosNewIcon style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => handleShowObligationPopup(false)} />&nbsp;&nbsp;
                        <h4>Credit Automation</h4>
                    </div>

                    <div className='' style={{ padding: '20px' }}>
                        <div className='Stats'>
                            <div className="leftSide">
                                <div className="subBox">
                                    <div style={{ marginRight: '10px' }}>
                                        <h5>Total Monthly Obligation</h5>
                                        <h3>{crifSummaryData?.totalObligations !== null && crifSummaryData?.totalObligations !== undefined && crifSummaryData?.totalObligations !== null ? (Number(crifSummaryData?.totalObligations))?.toFixed(2) : "-"}</h3>
                                    </div>
                                    <div>
                                        <img src={IconImg} />
                                    </div>

                                </div>
                                <hr style={{ width: '2px', height: '30px', background: '#D3D9E9', margin: 'auto 20px' }} />
                                <div className="subBox">
                                    <div style={{ marginRight: '10px' }}>
                                        <h5>Total Loan Fetch</h5>
                                        <h3>{crifSummaryData?.loansFetched !== null && crifSummaryData?.loansFetched !== undefined && crifSummaryData?.loansFetched !== null ? crifSummaryData?.loansFetched : "-"}</h3>
                                    </div>
                                    <div>
                                        <img src={IconImg} />
                                    </div>

                                </div>
                                <hr style={{ width: '2px', height: '30px', background: '#D3D9E9', margin: 'auto 20px' }} />
                                <div className="subBox">
                                    <div style={{ marginRight: '10px' }}>
                                        <h5>Credit Score</h5>
                                        <h3>{crifSummaryData?.score !== null && crifSummaryData?.score !== undefined && crifSummaryData?.score !== null && crifSummaryData?.score !== "null" ? crifSummaryData?.score : "-"}</h3>
                                    </div>
                                    <div>
                                        <img src={IconImg} />
                                    </div>

                                </div>

                            </div>
                            <div className="rightSide" style={{ display: 'flex' }}>
                                <button className='addNewObligationbtn' style={{ background: disabledbtn ? "#66A2FA" : '#006c67', cursor: disabledbtn ? 'no-drop' : 'pointer', marginRight: '10px' }} onClick={() => regenerateFunction()}>Regenerate</button>
                                <button className='addNewObligationbtn' style={{ background: disabledbtn ? "#66A2FA" : '#006c67', cursor: disabledbtn ? 'no-drop' : 'pointer' }} onClick={() => handleAddNewObligation()}>Add New Obligation</button>
                            </div>
                        </div>

                        {/* <div className="filters">
                            <div className="row">
                                <div className="col-lg-2 col-sm-3 col-xs-12">
                                    <label>From</label>
                                    <input type="date" id='customDateInput' className='form-control' value={fromDate} onChange={(e) => handleDateFilter(e, "fromdate")} />
                                </div>
                                <div className="col-lg-2 col-sm-3 col-xs-12">
                                    <label>To</label>
                                    <input type="date" id='customDateInput' className='form-control' value={toDate} min={fromDate} onChange={(e) => handleDateFilter(e, "todate")} />
                                </div>
                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                    <div style={{ display: 'flex' }}>
                                        <button style={{ background: '#006c67', color: '#fff', padding: '10px', marginTop: '20px', borderRadius: '6px', marginRight: '15px' }} onClick={(e) => handleDateFilter(e, "apply")}>Apply Filter</button>
                                        <button style={{ border: '1px solid #006c67', background: '#fff', color: '#006c67', padding: '10px', marginTop: '20px', borderRadius: '6px' }} onClick={(e) => handleDateFilter(e, "reset")}>Reset Filter</button>
                                    </div>
                                </div>

                            </div>
                        </div> */}
                        {
                            filteredData && filteredData?.length > 0 ?

                                <div className="Tables" style={{ marginTop: '30px' }}>
                                    <div className="phocket-table-new">
                                        <div style={{ overflowX: 'auto', overflowY: 'auto', height: window.innerWidth > 1800 ? "620px" : window.innerWidth > 1200 && window?.innerWidth < 1800 ? '280px' : "200px" }}>
                                            <table>
                                                <thead style={{ position: 'sticky', top: '0', background: '#006c67', color: '#fff' }}>
                                                    <tr>
                                                        <th className="text-center">Disbursed Amount</th>
                                                        <th className="text-center">Date Of Disbursed</th>
                                                        <th className="text-center">Loan Type</th>
                                                        <th className="text-center">Tenure(in Months)</th>
                                                        <th className="text-center">Interest</th>
                                                        <th className="text-center">Installement Amount</th>
                                                        <th className="text-center">Monthly Calculated EMI</th>
                                                        <th className="text-center">Last Update As On</th>
                                                        <th className="text-center">Deviation</th>
                                                        <th className="text-center">Already saved Deviation</th>
                                                        <th className="text-center">Resources</th>
                                                        <th className="text-center">View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData?.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{(data?.disbursedAmount ?? '-')?.toLocaleString("en-US")}</td>
                                                            <td className="text-center" >{data?.disburseDate ?? "-"}</td>
                                                            <td className="text-center">{data?.accountType || "-"}</td>
                                                            <td className="text-center">{data?.tenure ?? "-"}</td>
                                                            <td className="text-center">{(data?.roi ?? '-')?.toLocaleString("en-US")}</td>
                                                            <td className="text-center">{(data?.installmentAmount ?? "-")?.toLocaleString("en-US")}</td>
                                                            <td className="text-center">{(data?.calculatedEmiAmount ?? "-")?.toLocaleString("en-US")}</td>
                                                            <td className="text-center">{data?.reportedOn || "-"}</td>
                                                            <td className="text-center" style={{ minWidth: window.innerWidth > 1200 ? '90px' : '150px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <input value={updateDeviationValue.index === index ? updateDeviationValue.updatedValue : ""} style={{ border: '1px solid #929292', background: '#DBE9FF26', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', borderRight: 'none', width: '70%', minWidth: '110px' }} onChange={(e) => handleUpdateDeviation(e, data, index)} />
                                                                    <button style={{ background: '#267dff', color: '#fff', padding: '12px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }} onClick={() => SaveUpdatedDeviation()}>Save</button>
                                                                </div>
                                                            </td>
                                                            {
                                                                data?.deviation !== null && data?.deviation !== undefined && data?.deviation !== "" ?
                                                                    <td className="text-center" ><InfoOutlinedIcon style={{ fontSize: '20px', color: '#267dff', cursor: 'pointer' }} onClick={() => handleDeviationInfo(data?.deviation)} /></td>
                                                                    :
                                                                    <td className="text-center">-</td>
                                                            }
                                                            <td className="text-center" >{data?.resources ?? "-"}</td>
                                                            <td className="text-center" ><VisibilityOutlinedIcon style={{ fontSize: '20px', color: '#267dff', cursor: 'pointer' }} onClick={() => handleInfo(data)} /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{ marginTop: '15px' }}>
                                            <Pagination
                                                count={Math.ceil(listData?.length / itemsPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="success" style={{ display: 'flex', justifyContent: 'end' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <p style={{ marginTop: '100px' }} className="text-center">
                                    <img src={NodataImage} style={{ width: '40%' }} />
                                </p>
                        }

                        {
                            DeviationPopupShow?.show == true ?
                                <div className='background-blur income' style={{ zIndex: '1100' }}>
                                    <div className='popup-main container-fluid dbl-border' style={{ padding: '0px', height: 'auto', zIndex: '1101', width: '600px', top: '30%' }}>
                                        <button className='close' onClick={() => handlePopupClose()}>
                                            &times;
                                        </button>
                                        <div className="col-xs-12">
                                            <div className="Tables" style={{ marginTop: '40px', marginBottom: '15px' }}>
                                                <div className="phocket-table-new">
                                                    <div style={{ overflowX: 'auto' }}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Already Saved Deviation</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center">{DeviationPopupShow?.value ?? ""}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}


                    </div>
                </div>

                {
                    ObligationPopupShow == true ?

                        <div className={`popup-main container-fluid dbl-border ${ObligationPopupShow == true ? "slide-in-right" : "slide-out-right"}`} style={{ padding: '0px', height: 'auto', zIndex: '1100', width: 'auto', top: '20%' }}>
                            {/* <button className='close' onClick={() => handlePopupClose()}>
                                &times;
                            </button>
                            <h5 style={{ margin: '0px 10px', fontSize: '16px', borderBottom: '1px solid #000', padding: '8px 0px' }}>Edit and View</h5> */}
                            <div style={{ background: '#1D3557', display: 'flex', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', color: '#fff', padding: '10px 20px ', alignItems: 'center' }}>
                                <ArrowBackIosNewIcon style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => handlePopupClose("")} />&nbsp;&nbsp;
                                <h4>Edit and View</h4>
                            </div>
                            <div className="col-xs-12">
                                <div className="row">
                                    <div className="col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Disbursed Amount</label>
                                        <input type="number" value={editData?.disbursedAmount} name="disbursedAmount" className='customInput' placeholder='Disbursed Amount' onChange={(e) => HandleEditData(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Tenure</label>
                                        <input type="number" value={editData?.tenure} name="tenure" className='customInput' placeholder='Tenure' onChange={(e) => HandleEditData(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Interest</label>
                                        <input type="number" value={editData?.roi} name="roi" className='customInput' placeholder='Interest' onChange={(e) => HandleEditData(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Resources</label>
                                        {/* <input type="text" value={editData?.resources} name="resources" className='customInput' placeholder='Resources' onChange={(e) => HandleEditData(e)} /> */}
                                        <select className='customInput' name="Resources" value={editData?.resources} onChange={(e) => HandleEditData(e)}>
                                            <option value="">Select Resources</option>
                                            <option value="crif">Crif</option>
                                            <option value="sms">SMS</option>
                                        </select>
                                    </div>
                                    <div className="col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Deviation</label>
                                        <textarea type="text" value={editData?.deviation} name="deviation" className='customInput' rows={4} style={{ resize: 'none' }} placeholder='Deviation' onChange={(e) => HandleEditData(e)} />
                                    </div>
                                    <div className="col-xs-12 inputss" style={{ marginBottom: '15px' }}>
                                        <div style={{ display: 'flex', float: 'right' }}>
                                            <button style={{ background: '#AAAAAA', color: '#fff', padding: '10px', borderRadius: '6px', marginRight: '15px' }} onClick={() => handleDelete(editData)}>Delete</button>
                                            <button style={{ background: '#267dff', color: '#fff', padding: '10px', borderRadius: '6px' }} onClick={() => submitEditData()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                }

                {
                    addNewObligationPopup.show == true ?
                        <div className={`popup-main container-fluid dbl-border ${addNewObligationPopup.show == true ? "slide-in-right" : "slide-out-right"}`} style={{ top: '20%', padding: '0px', zIndex: '1100', width: 'auto', height: 'auto' }}>

                            <div style={{ background: '#1D3557', display: 'flex', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', color: '#fff', padding: '10px 20px ', alignItems: 'center' }}>
                                <ArrowBackIosNewIcon style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => handlePopupClose("")} />&nbsp;&nbsp;
                                <h4>Add New Obligation</h4>
                            </div>

                            <div className="col-xs-12">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Disbursed Amount</label>
                                        <input type="number" className='customInput' placeholder='Disbursed Amount' name="disbursedAmount" value={addNewObligationPopup.data?.disbursedAmount} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Date Of Disbursed</label>
                                        <input type="date" className='customInput' id='customDateInput' placeholder='Date Of Disbursed' name="disburseDate" value={addNewObligationPopup.data?.disburseDate} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Loan Type</label>
                                        <input type="text" className='customInput' placeholder='Loan Type' name="accountType" value={addNewObligationPopup.data?.accountType} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Tenure</label>
                                        <input type="number" className='customInput' placeholder='Tenure' name="tenure" value={addNewObligationPopup.data?.tenure} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Interest</label>
                                        <input type="number" className='customInput' placeholder='Interest' name="roi" value={addNewObligationPopup.data?.roi} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                    <div className="col-sm-4 col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Resources</label>
                                        {/* <input type="text" className='customInput' placeholder='Resources' name="resources" value={addNewObligationPopup.data?.resources} onChange={(e) => handleAddObligationDataFunction(e)} /> */}
                                        <select className='customInput' name="resources" value={addNewObligationPopup.data?.resources} onChange={(e) => handleAddObligationDataFunction(e)}>
                                            <option value="">Select Resources</option>
                                            <option value="crif">Crif</option>
                                            <option value="sms">SMS</option>
                                        </select>
                                    </div>
                                    <div className="col-xs-12 inputss">
                                        <label className="customObligationPopup" htmlFor="">Deviation</label>
                                        <textarea type="text" className='customInput' rows={4} style={{ resize: 'none' }} placeholder='Deviation' name="deviation" value={addNewObligationPopup.data?.deviation} onChange={(e) => handleAddObligationDataFunction(e)} />
                                    </div>
                                    <div className="col-xs-12 inputss" style={{ marginBottom: '15px' }}>

                                        <button style={{ background: '#267dff', color: '#fff', padding: '10px', borderRadius: '6px' }} onClick={() => SaveEMIcalculate()}>EMI Calculate</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div >
        </>
    )
}
