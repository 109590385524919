import React, { Component } from 'react'
import WhatsappUi from '../../../presentationals/Admin/Recovery/Whatsapp.component'
import { sendWhatsappMsgesApi, getWhatsappMsgApi, whatsappFileApi } from '../AdminActionCreator.component'
import { MSG_STRING, MAIL_STRING } from '../../../../utils/Constant'
import $ from 'jquery'
var sortJsonArray = require('sort-json-array');

let interval;

class Whatsapp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.dataForCommunication,
            allWhatsappMsg: '',
            writeWhatsappMsg: '',
            errorMsg: '',
            loaderState: false,
            tickStatus: 0
        }
    }

    componentWillMount() {
        if (this.state.allDetail.mobileNumber != null && this.state.allDetail.mobileNumber != '') {
            getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'recovery', '',
                (callBack) => {
                    this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                })
        } else {
            this.setState({ errorMsg: 'Mobile number is blank' })
        }
    }

    componentDidMount() {
        if (this.state.allDetail.mobileNumber != null && this.state.allDetail.mobileNumber != '') {
            interval = setInterval(function () {
                getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'recovery', '',
                    (callBack) => {
                        if (this.state.allWhatsappMsg.messages.length != callBack.messages.length) {
                            this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                            $('#whatsappMsg').animate({
                                scrollTop: $('#whatsappMsg').get(0).scrollHeight
                            }, 100);
                        }
                    })
            }.bind(this), 30000) // 30 sec
        } else {
            this.setState({ errorMsg: 'Mobile number is blank' })
        }
    }

    render() {
        const {collectionType} = this.props
        return (
            <WhatsappUi
                whatsappmsg={this.whatsappmsg.bind(this)}
                writeWhatsappMsg={this.state.writeWhatsappMsg}
                whatsappmsgHandler={this.whatsappmsgHandler.bind(this)}
                errorMsg={this.state.errorMsg}
                allWhatsappMsg={this.state.allWhatsappMsg != '' && this.state.allWhatsappMsg.messages != null ? sortJsonArray(this.state.allWhatsappMsg.messages, 'date', 'asc') : ""}
                msgTempleteClick={this.msgTempleteClick.bind(this)}
                loaderState={this.state.loaderState}
                tickStatus={this.state.tickStatus}
                collectionType={collectionType}
            />
        )
    }

    msgTempleteClick(msgType) {
        let payBackDate = this.state.allDetail != null && this.state.allDetail.loanPaybackDate != null ? this.state.allDetail.loanPaybackDate.split(' ')[0] : ""
        let payBackAmount = this.state.allDetail != null && this.state.allDetail.loanPaybackAmount != null ? this.state.allDetail.loanPaybackAmount : ""
        let installmentAmount = this.state.allDetail != null && this.state.allDetail.installmentAmount != null ? this.state.allDetail.installmentAmount : ""
        let installmentDate = this.state.allDetail != null && this.state.allDetail.installmentDate != null ? this.state.allDetail.installmentDate : ""
        if (msgType == 'NachBounced') {
            this.setState({ writeWhatsappMsg: MSG_STRING.NACH_BOUNCE })
        } else if (msgType == 'ChequeBounced') {
            this.setState({ writeWhatsappMsg: MSG_STRING.CHEQUE_BOUNCE })
        } else if (msgType == 'ChequeDepositAlert') {
            this.setState({ writeWhatsappMsg: MSG_STRING.CHEQUE_DEPOSITE_ALERT })
        } else if (msgType == 'LegalNotice') {
            this.setState({ writeWhatsappMsg: MSG_STRING.LEGAL_NOTICE })
        } else if (msgType == 'LoanUnderDefault') {
            this.setState({ writeWhatsappMsg: MSG_STRING.LOAN_UNDER_DEAFULT })
        } else if (msgType == 'WithNach') {
            this.setState({ writeWhatsappMsg: 'Credfin LOAN: We are initiating your auto debit of INR ' + payBackAmount + ' on ' + payBackDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'WithoutNach') {
            this.setState({ writeWhatsappMsg: 'Credfin LOAN: Kindly note the repayment amount INR ' + payBackAmount + ' as on ' + payBackDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@credfin.money' })
        } else if (msgType == 'EmiWithNach') {
            this.setState({ writeWhatsappMsg: 'Credfin LOAN EMI: We are initiating your auto debit of INR ' + installmentAmount + ' on ' + installmentDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'EmiWithoutNach') {
            this.setState({ writeWhatsappMsg: 'Credfin LOAN EMI: Kindly note the repayment amount INR ' + installmentAmount + ' as on ' + installmentDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@credfin.money' })
        }
    }

    whatsappmsg(e, type) {
        if (type == 'text') {
            this.setState({ writeWhatsappMsg: e.target.value, errorMsg: '' })
        } else if (type == 'file') {
            this.setState({ loaderState: true })
            let val = e.target.value.split(/[\\\/]/)[2];
            let File1 = e.target.files[0];
            let docType = val.split(".");
            let fileType = docType[docType.length - 1];
            whatsappFileApi(File1, this.state.allDetail, val, fileType, this.props.admin, 'recovery',
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({ loaderState: false })
                        getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'recovery', '',
                            (callBack) => {
                                this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                                $('#whatsappMsg').animate({
                                    scrollTop: $('#whatsappMsg').get(0).scrollHeight
                                }, 100);
                            })
                    }
                })

        }
    }

    sendWhatsappMsges() {
        const { admin } = this.props
        sendWhatsappMsgesApi(this.state.allDetail, this.state.writeWhatsappMsg, admin, 'recovery',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        writeWhatsappMsg: '',
                        errorMsg: ''
                    })
                    getWhatsappMsgApi(this.state.allDetail.mobileNumber, 'recovery', '',
                        (callBack) => {
                            this.setState({ allWhatsappMsg: callBack, tickStatus: callBack.status })
                            $('#whatsappMsg').animate({
                                scrollTop: $('#whatsappMsg').get(0).scrollHeight
                            }, 100);
                        })
                } else {
                    this.setState({ errorMsg: 'Something wrong! please try again' })
                }
            })
    }

    whatsappmsgHandler(e, type) {
        if (this.state.writeWhatsappMsg != '') {
            if (type == 'enter') {
                if (e.keyCode == 13) {
                    this.sendWhatsappMsges()
                }
            } else if (type == 'click') {
                this.sendWhatsappMsges()
            }
        } else {
            this.setState({ errorMsg: 'Please type msg' })
        }
    }

    componentWillUnmount() {
        clearInterval(interval);
    }
    
}
export default Whatsapp;