import React, { useState, useEffect } from 'react'
import { getApproveAmountByLoanId, getObligationcalculatorAPI } from '../../Analysis/analysisactionCreator'
import Loader from '../../../../presentationals/Loader/Loader.component'

export default function Approveamount({ allDetail }) {
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        getObligationcalculatorFunction()
    }, [])

    const getObligationcalculatorFunction = () => {
        // setLoader(true)
        getObligationcalculatorAPI(allDetail.userId, allDetail.loanId,0, (callback) => {
            console.log("callbackcallback", callback) 
            if (callback) {
                getApproveAmountByLoanId(allDetail.userId, allDetail.loanId, (callback) => {
                    console.log("callbackcallback", callback)
                    // setLoader(false)
                    if (callback) {
                        setData(callback)
                    } else {
                        setData({})
                    }
                })
            } else {
                setLoader(false)
                setData({})
            }
        })
    }
    return (
        <>
            {loader ?
                <Loader />
                : ''}
            
                    <div style={{ padding: '10px' }}>
                        <div style={{ background: '#DBE9FF', padding: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', display: 'flex', justifyContent: 'space-between' }}>
                            <h5><b>Approve Amount </b></h5>
                           
                        </div>

                        <table className="scoring-table">

                            <tr>
                                <td className='text-center'>No of EMI's</td>
                                <td className='text-center'>Approve Amount</td>
                            </tr>

                            {
                                 data && data?.length > 0 ? data?.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ borderTop: 'none', borderRight: 'none', textAlign: 'center' }}>{value?.numberOfEMI ?? "-"}</td>
                                            <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'center' }}>{(value?.approvedAmount)?.toLocaleString('en-IN') ?? "-"}</td>
                                        </tr>
                                    )
                                })
                            :
                            
                            <tr>
                                            <td style={{ borderTop: 'none', borderRight: 'none', textAlign: 'center' }}>-</td>
                                            <td style={{ borderTop: 'none', borderLeft: 'none', textAlign: 'center' }}>-</td>
                                        </tr>
                                        }

                        </table>



                    </div >
                  
        </>
    )
}