import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({OptionValues, selectedValues, onChange,placeholder}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    if (onChange) {
      onChange(value);
    }
  };

  const generateLabel = () => {
    if (selectedValues.length > 0) {
      const selectedStatuses = OptionValues.filter(optionvalue => selectedValues.includes(optionvalue)).map(optionvalue => optionvalue);
      return selectedStatuses.join(', ');
    }
    return placeholder;
  };

  return (
      <div>
        <FormControl sx={{ width: '-webkit-fill-available', backgroundColor:'#DBE9FF',borderRadius:'6px',height:'40px' }}>
        {/* <label style={{fontSize:'12px',fontWeight:'400',position:'absolute',zIndex:'1',top:'4%',left:'2%',width:'80%',height:'35px',overflowY:'hidden',backgroundColor: '#DBE9FF', borderRadius: '6px'}}>{generateLabel()}</label> */}
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style={{height:'41.5px',backgroundColor:'#DBE9FF'}}
        >
          {OptionValues.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedValues.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
          {/* {OptionValues.map((da) => (
            <MenuItem key={da.value} value={da.value}>
              <Checkbox checked={selectedValues.indexOf(da.value) > -1} />
              <ListItemText primary={da.option} />
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
    </div>
  );
}
