import React, { useState } from 'react'
import accountreconciliaitonimg from './images/accountreconciliaiton.png';
import MandateReportimg from './images/MandateReport.png';
import AccountReconciliaitonPage from './AccountReconciliaitonPage';
import MandateReport from './MandateReport';
import UnidentifiedPayment from './unidentifiedPayment'
import Allpayments from '../Payments/allpayments';
import UnverifiedPayments from '../Payments/UnverifiedPayments'
const tabStyle = {
    width: '25%',
    background: 'rgb(219, 233, 255)',
    cursor: 'pointer',
    color: '#000',
    padding: '12px',
    boxShadow:'inset -3px -3px 10px -7px #000'
};
const activeTabStyle = {
    width: '25%',
    cursor: 'pointer',
    padding: '12px',
    background: '#006c67',
    color: '#fff',
};
const h5Style = {
    textAlign: 'center',
    fontSize: '17px',
    margin: '5px 0px'
}

export default function ARMainTab({ admin, allStatus, newDataBackground, pageViewHandler }) {
    const [tab, setTab] = useState('accountreconciliation');

    const handlepagechange = (type) => {
        setTab(type)
    }
    return (
        <>
            <div className="" style={{ marginTop: '-12px' }}>

                <div className='topTabs fixed-top' style={{ display: 'flex', width: '100%' ,top:'147px'}}>
                    <div className="tabs" style={tab === "accountreconciliation" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('accountreconciliation')}>
                        <h5 style={h5Style}>Account Reconciliation</h5>
                    </div>
                    <div className="tabs" style={tab === "Verified" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('Verified')}>
                        <h5 style={h5Style}>Verified Payments</h5>
                    </div>
                    <div className="tabs" style={tab === "Unverified" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('Unverified')}>
                        <h5 style={h5Style}>Unverified Payments</h5>
                    </div>
                    <div className="tabs" style={tab === "unveried" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('unveried')}>
                        <h5 style={h5Style}>Unidentified Payments</h5>
                    </div>
                </div>
                {/* <div className="row text-center"> */}

                {/* <div className='' style={{ background: '#1D3557', height: '100%', width: '5%', minWidth: '100px', position: 'fixed' }}> */}


                {/* <div className="aggDetailsTab" style={{ color: '#fff', marginTop: '15px', cursor: 'pointer' }} > */}
                {/* <a
                                className="tabText"
                                style={{ color: '#fff', fontSize: '10px' }}
                                onClick={() => handlepagechange('accountreconciliation')}
                            >
                                <div className={tab === 'accountreconciliation' ? 'active-box' : 'inactive-box'}> */}
                {/* <i class="fa fa-users" aria-hidden="true"></i> */}
                {/* <img src={accountreconciliaitonimg} width={'100%'} alt="" />
                                </div>
                                Account Reconciliation
                            </a>
                        </div> */}
                {/* <div className="aggDetailsTab" style={{ color: '#fff',marginTop:'15px',cursor:'pointer' }} >
                    <a
                        className="tabText"
                        style={{ color: '#fff' ,fontSize: '10px' }}
                        onClick={() => handlepagechange('MandateReport')}
                    >
                        <div className={tab === 'MandateReport' ? 'active-box' : 'inactive-box'}>
                            <img src={MandateReportimg} width={'100%'} alt="" />
                        </div>
                       
                        Mandate<br/> Report
                    </a>
                </div> */}
                {/* <div className="aggDetailsTab" style={{ color: '#fff', marginTop: '15px', cursor: 'pointer' }} >
                            <a
                                className="tabText"
                                style={{ color: '#fff', fontSize: '10px' }}
                                onClick={() => handlepagechange('unveried')}
                            >
                                <div className={tab === 'unveried' ? 'active-box' : 'inactive-box'}>
                                    <img src={MandateReportimg} width={'100%'} alt="" />
                                </div>

                                Unverified<br /> Payments
                            </a>
                        </div> */}

                {/* </div> */}
                {/* </div> */}
                <div style={{marginTop:window.innerWidth > 1800 ? '75px' : '120px'}}>
                    {/* <div style={{ width: 'calc(95%-100px)', marginLeft: '100px' }}> */}

                    {tab === 'accountreconciliation' ?
                        <AccountReconciliaitonPage pageViewHandler={pageViewHandler} />
                        : ""}
                    {tab === 'Verified' ?
                        <Allpayments pageViewHandler={pageViewHandler} />
                        : ""}
                    {tab === 'Unverified' ?
                        <UnverifiedPayments pageViewHandler={pageViewHandler} />
                        : ""}
                    {tab === 'MandateReport' ?
                        <MandateReport />
                        : ""}

                    {tab === 'unveried' ?
                        <UnidentifiedPayment />
                        : ""}
                </div>
            </div>
        </>
    )
}