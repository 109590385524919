import React, { Component } from 'react'
import EditAmbDetailPage from '../../../presentationals/Admin/Ambassador/EditAmbassadorDetail.component';
import { TYPE, STRING } from '../../../../utils/Constant'
import { _formatDate } from '../../../../utils/validation';
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';
import { uploadAmbImage, uploadAmbPdf, fetchAmbassadorBasicDetail, fetchAmbassadorAddressDetail } from '../AdminActionCreator.component';

let File1 = '';
let File2 = '';
let File3 = '';
let File4 = '';
let File5 = '';
let File6 = '';
let File = [];

class EditAmbDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ambDOB: '',
            ambassadorAllData: this.props.ambassadorAllData,
            AddressPasswordState: false,
            progressBarState: false,
            progressValue: 20,
            docType: '',
            popupState: false,
            popupStatus: '',
            ambRegDetail: {
                ambNameState: '',
                ambDOB: '',
                ambGenderState: '',
                ambMobileState: '',
                ambEmailState: '',
                ambAddressState: '',
                ambPinCodeState: '',
                ambStateState: '',
                ambCityState: '',
                ambHighQualificationState: '',
                ambIdProofState: 'No File Chosen',
                ambPanState: 'No File Chosen',
                ambAddressProof: 'No File Chosen',
                ambassadorId: this.props.ambassadorId,
                HowToKnowState: 'Credfin',
                formStatus: 'Thanks'
            }
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        const { admin } = this.props
        let ambRegDetail = Object.assign({}, this.state.ambRegDetail);
        if (this.state.ambassadorAllData != undefined) {
            if (this.state.ambassadorAllData.fullName != null && this.state.ambassadorAllData.fullName != "") {
                ambRegDetail.ambNameState = this.state.ambassadorAllData.fullName
            }
            if (this.state.ambassadorAllData.dateOfBirth != null && this.state.ambassadorAllData.dateOfBirth != "") {
                ambRegDetail.ambDOB = this.state.ambassadorAllData.dateOfBirth
            }
            if (this.state.ambassadorAllData.gender != null && this.state.ambassadorAllData.gender != "") {
                ambRegDetail.ambGenderState = this.state.ambassadorAllData.gender
            }
            if (this.state.ambassadorAllData.mobileNumber != null && this.state.ambassadorAllData.mobileNumber != "") {
                ambRegDetail.ambMobileState = this.state.ambassadorAllData.mobileNumber
            }
            if (this.state.ambassadorAllData.emailId != null && this.state.ambassadorAllData.emailId != "") {
                ambRegDetail.ambEmailState = this.state.ambassadorAllData.emailId
            }
            if (this.state.ambassadorAllData.address != null && this.state.ambassadorAllData.address != "") {
                ambRegDetail.ambAddressState = this.state.ambassadorAllData.address
            }
            if (this.state.ambassadorAllData.pincode != null && this.state.ambassadorAllData.pincode != "") {
                ambRegDetail.ambPinCodeState = this.state.ambassadorAllData.pincode
            }
            if (this.state.ambassadorAllData.state != null && this.state.ambassadorAllData.state != "") {
                ambRegDetail.ambStateState = this.state.ambassadorAllData.state
            }
            if (this.state.ambassadorAllData.city != null && this.state.ambassadorAllData.city != "") {
                ambRegDetail.ambCityState = this.state.ambassadorAllData.city
            }
            if (this.state.ambassadorAllData.qualification != null && this.state.ambassadorAllData.qualification != "") {
                ambRegDetail.ambHighQualificationState = this.state.ambassadorAllData.qualification
            }
            if (this.state.ambassadorAllData.ambassadorDocumentDO != null && this.state.ambassadorAllData.ambassadorDocumentDO.idImageUrl != null) {
                ambRegDetail.ambIdProofState = this.state.ambassadorAllData.ambassadorDocumentDO.idImageUrl
            }
            if (this.state.ambassadorAllData.ambassadorDocumentDO != null && this.state.ambassadorAllData.ambassadorDocumentDO.panImageUrl != null) {
                ambRegDetail.ambPanState = this.state.ambassadorAllData.ambassadorDocumentDO.panImageUrl
            }
            if (this.state.ambassadorAllData.ambassadorDocumentDO != null && this.state.ambassadorAllData.ambassadorDocumentDO.addressImageUrl != null) {
                ambRegDetail.ambAddressProof = this.state.ambassadorAllData.ambassadorDocumentDO.addressImageUrl
            }
            if (this.state.ambassadorAllData.ambassadorId != null && this.state.ambassadorAllData.ambassadorId != "") {
                ambRegDetail.ambassadorId = this.state.ambassadorAllData.ambassadorId
            }
            if (this.state.ambassadorAllData.howToKnow != null && this.state.ambassadorAllData.howToKnow != "") {
                ambRegDetail.HowToKnowState = this.state.ambassadorAllData.howToKnow
            }
        }
        this.setState({ ambRegDetail })
    }

    render() {
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <EditAmbDetailPage
                    ambDOB={this.state.ambDOB}
                    ambRegDetail={this.state.ambRegDetail}
                    ambRegDetailChange={this.ambRegDetailChange.bind(this)}
                    ambRegDetailHandler={this.ambRegDetailHandler.bind(this)}
                    registerBtnState={this.props.registerBtnState}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ambRegDetailChange(e, chngType) {
        let ambRegDetail = Object.assign({}, this.state.ambRegDetail);
        if (chngType == TYPE.FULL_NAME) {
            ambRegDetail.ambNameState = e.target.value
        } else if (chngType == TYPE.DOB) {
            this.setState({ ambDOB: e })
            ambRegDetail.ambDOB = _formatDate(e)
        } else if (chngType == TYPE.GENDER) {
            ambRegDetail.ambGenderState = e.target.value
        } else if (chngType == TYPE.MOBILE_NO) {
            ambRegDetail.ambMobileState = e.target.value
        } else if (chngType == TYPE.EMAIL_ID) {
            ambRegDetail.ambEmailState = e.target.value
        } else if (chngType == TYPE.CURRENT_ADDRESS) {
            ambRegDetail.ambAddressState = e.target.value
        } else if (chngType == TYPE.CURRENT_PIN_CODE) {
            let pinCode = e.target.value
            if (pinCode.length == 6) {
                fetch(APIS.GET_STATE_CITY + pinCode)
                    .then(res => res.json())
                    .then(json => {
                        ambRegDetail.ambStateState = json.state
                        ambRegDetail.ambCityState = json.district
                        this.setState({ ambRegDetail })
                    })
            } else {
                ambRegDetail.ambStateState = "";
                ambRegDetail.ambCityState = "";
                this.setState({ ambRegDetail })
            }
            ambRegDetail.ambPinCodeState = pinCode
        } else if (chngType == TYPE.CURRENT_STATE) {
            ambRegDetail.ambStateState = e.target.value
        } else if (chngType == TYPE.CURRENT_CITY) {
            ambRegDetail.ambCityState = e.target.value
        } else if (chngType == TYPE.HIGHEST_QUALIFICATION) {
            ambRegDetail.ambHighQualificationState = e.target.value
        } else {
            let val = e.target.value.split(/[\\\/]/)[2];
            let reader = new FileReader();
            File1 = e.target.files[0];
            File2 = e.target.files[1];
            File3 = e.target.files[2];
            File4 = e.target.files[3];
            File5 = e.target.files[4];
            File6 = e.target.files[5];
            File = [File1, File2, File3, File4, File5, File6];
            let docType = val.split(".");
            let fileType = docType[docType.length - 1];
            if (chngType === TYPE.ID_PROOF) {
                ambRegDetail.ambIdProofState = val;
                if (fileType === "pdf") {
                    uploadAmbPdf(File, chngType, val, '', this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambIdProofState = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    );
                } else {
                    uploadAmbImage(File, chngType, val, this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambIdProofState = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    );
                }
            } else if (chngType === TYPE.PAN_CARD) {
                ambRegDetail.ambPanState = val;
                if (fileType === "pdf") {
                    uploadAmbPdf(File, chngType, val, '', this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambPanState = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    );
                } else {
                    uploadAmbImage(File, chngType, val, this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambPanState = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    );
                }
            } else if (chngType === TYPE.ADDRESS) {
                ambRegDetail.ambAddressProof = val;
                if (fileType === "pdf") {
                    uploadAmbPdf(File, chngType, val, '', this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambAddressProof = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    )
                } else {
                    uploadAmbImage(File, chngType, val, this.state.ambRegDetail,
                        (callBack => {
                            ambRegDetail.ambAddressProof = callBack
                            this.setState({
                                ambRegDetail
                            })
                        })
                    );
                }
            }
        }
        this.setState({
            ambRegDetail,
        })
    }

    ambRegDetailHandler(btnType) {
        if (btnType == 'basic') {
            fetchAmbassadorBasicDetail(this.state.ambRegDetail,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Basic Details Changed Successfully',
                    })
                    this.removePopup();
                })
        } else if (btnType == 'regAndOcc') {
            fetchAmbassadorAddressDetail(this.state.ambRegDetail,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Residence Details Changed Successfully',
                    })
                    this.removePopup();
                })
        } else if (btnType == 'registerAmb') {
            fetch(APIS.AMB_DOC_NEXT + this.state.ambRegDetail.ambassadorId)
                .then(res => res.text())
                .then(text => {
                        this.props.editBackHandler()
                })
        }
    }
}

export default EditAmbDetails;