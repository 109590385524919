import { InfoOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Individual from "./individualNbfc";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DebtListIndividual from "../DebtCollectionList/deptindividual";
import { nbfcStatusApi } from "./actioncreatorNbfc";

const ListNbfc = ({ pageHandler, admin }) => {
    const [viewPage, setViewpage] = useState('first')
    const [showZoom, setShowZoom] = useState(false)
    const [showNpa, setshowNpa] = useState(false)
    const [nbfcStats, setNbfcStats] = useState('')
    const [nbfcId, setnbfcId] = useState('')




    const barOptions = {
        series: [{
            name: 'Application Received',
            data: [44, 55, 41, 37, 22, 43, 21]
        }, {
            name: 'Under Review',
            data: [53, 32, 33, 52, 13, 43, 32]
        }, {
            name: 'Approved',
            data: [12, 17, 11, 9, 15, 11, 20]
        }, {
            name: 'Disbursed',
            data: [9, 7, 5, 8, 6, 9, 4]
        }, {
            name: 'Rejected',
            data: [9, 7, 5, 8, 6, 9, 4]
        }],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },

        stroke: {
            width: 1,
            colors: ['#fff']
        },

        xaxis: {
            categories: [2018, 2019, 2020, 2021, 2022, 2023, 2024],
            labels: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        fill: {
            opacity: 1
        },

        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    };
    const handlenpa = () => {
        setshowNpa(false)
        setViewpage('first')
    }
    const npaView = () => {
        setViewpage('')
        setshowNpa(true)
    }
    const nbfcStatsData = () => {
        nbfcStatusApi(callBack => {
            setNbfcStats(callBack.data)
        })
    }
    useEffect(() => {
        nbfcStatsData()
    }, [])
    const infoHandler = (data) => {
        setnbfcId(data)
        setViewpage('info')

    }
    return (
        <>
            {viewPage === 'first' ?
                <div className="container-fluid nbfc-dashboard">

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <div className="box-outer" style={{ marginBottom: '30px' }} >
                                <h4><b>NBFC Overview Table</b></h4>
                                <div className="dash-table">
                                    <table class="">
                                        <thead>
                                            <tr>
                                                <th>Info</th>
                                                <th>NBFC</th>
                                                <th>POC Name</th>
                                                <th>POC Contact Number</th>
                                                <th>Total Lead</th>
                                                <th>Interest</th>
                                                <th>Total Loan Disbursed</th>
                                                <th>Approval Rate</th>
                                                <th>Default Rate</th>
                                                {/* <th>Generate Report</th> */}
                                                <th>Status</th>
                                                {/* <th>Actions</th> */}
                                                <th>Integration Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {nbfcStats ? nbfcStats.map((data, i) => (
                                                <tr key={i}>
                                                    <td><InfoOutlined onClick={() => infoHandler(data)} style={{ background: '#006c67', borderRadius: '20px', color: '#fff', cursor: 'pointer' }} /></td>

                                                    <td>{data.nbfcName || 'N/A'}</td>
                                                    <td>{data.pocName || 'N/A'}</td>
                                                    <td>{data.pocNumber || 'N/A'}</td>
                                                    <td>{data.totalApplications || 'N/A'}</td>
                                                    <td>{data.interestRate || 'N/A'}</td>
                                                    <td>{data.loanDisbursed || 'N/A'}</td>
                                                    <td>{data.approvalRate || 'N/A'}</td>
                                                    <td>{data.notapprovedRate || 'N/A'}</td>
                                                    {/* <td><button>Generate Report</button></td> */}
                                                    <td>{data.status || 'N/A'}</td>
                                                    {/* <td>
                                                        <button>Actions</button>
                                                    </td> */}
                                                    <td>{data.integrationStatus || 'N/A'}</td>
                                                </tr>
                                            )) : <tr><td colSpan="13">No data available</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            {viewPage === 'info' ?
                <Individual nbfcId={nbfcId} admin={admin} setViewpage={setViewpage} />
                : ""}
            {showZoom ?
                <>
                    <div className="background-blur" onClick={e => setShowZoom(false)}></div>
                    <div className="popup-main small-popup container-fluid " style={{ width: '80%' }}>
                        <h3 className="blue-text text-center"></h3>
                        <div className="row form-group">
                            <ReactApexChart
                                options={barOptions}
                                series={barOptions.series}
                                type="bar"
                                height={400}
                            />
                        </div>
                    </div>
                </>
                : ""}
            {showNpa ?
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-10 col-xs-12" style={{}}>
                            </div>
                            <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <button className="btn btn-primary" style={{ background: '#006c67' }} onClick={() => handlenpa(false)} >
                                    Close NPA
                                </button>
                            </div>
                        </div>
                    </div>
                    <DebtListIndividual admin={admin} />
                </>
                : ""}
        </>
    );
}

export default ListNbfc;
